<template>
  <div class="enterprise-box">
    <div class="box1">
      <video id="video" src="../../assets/mso.mp4" controls style="width:100%;height:100%">
        <source src="/src/assets/mso.mp4" type="video/ogg">
        <source src="/src/assets/mso.mp4" type="video/mp4">
      </video>
    </div>
    <p>
      江苏海宏智能科技有限公司（以下简称“海宏科技”）是隶属于连云港市交通控股集团的国有控股公司，主要从事工业物联网软硬件服务、MSO高速凹版印花成套设备、基于MSO多轴运动控制系统的非标自动化成套设备以及智能移动机器人等研发业务，旨在用自主研发的多轴运动控制系统为客户提供便捷高效、节能环保等优势为一体的服务。
    </p>
    <div class="box2">
      <img src="../../assets/imgs/关于我们/1/2-企业简介背景图.jpg" alt="">
    </div>
    <p>
      海宏科技本部设于连云港，目前于浙江柯桥、嵊州均设有印花办事处，是国内少数拥有成套核心技术自主知识产权和具有自主配套能力的企业之一，盈利能力和价值创造能力在同行中处于领先地位。<br />海宏科技始终把产品创新研发视为公司发展的核心推动力，特别针对”MSO多轴运动控制系统”成立了“高精度多轴运动控制工程技术研发中心”，研发中心目标是每一年至少推出一款“基于MSO多轴运动控制系统”新产品投入市场。<br />目前，海宏科技产品和服务遍及全国各个城市，通过全体海宏人的不断努力，建设出一批具有企业特色的项目产品，为客户提供了成熟完备的服务，形成了行业领先的技术体系。<br />海宏科技坚持"智能改变生活，科技提升品质”的企业愿景，秉承"积极进取、节约低调"的企业使命，坚守"忠诚敬业、高效协作"的企业精神，强化"专注、极致、品牌、快速"的企业文化理念，努力打造具有行业竞争力的"科技型、管理型、质量型"一流企业，全面成为"让客户放心满意、合作单位团结信任、员工幸福、社会赞誉"的受人尊敬的企业。
    </p>
    <div class="box3">
      <p>MSO海宏智能科技专注于</p>
      <div class="box3-url1">
        <div class="box-text1">
          智能机器人
        </div>
        <div class="box-text2">
          自动化仓储物流、配置专业焊接装置
        </div>
        <!-- 文字边框盒子 -->
        <span class="box-border"></span>
        <span class="box-border-left"></span>
        <span class="box-border-right"></span>
        <!-- 文字边框盒子 -->
      </div>
      <div class="box3-url2">
        <div class="box-text1">
          智能制造
        </div>
        <div class="box-text2">
          全自动机床上下料生产线
        </div>
        <!-- 文字边框盒子 -->
        <span class="box-border"></span>
        <span class="box-border-left"></span>
        <span class="box-border-right"></span>
        <!-- 文字边框盒子 -->
      </div>
      <div class="box3-url3">
        <div class="box-text1">
          物联网软硬件成套装备
        </div>
        <div class="box-text2">
          互联网+制造业新技术、量身定做方案
        </div>
        <!-- 文字边框盒子 -->
        <span class="box-border1"></span>
        <span class="box-border-left1"></span>
        <span class="box-border-right1"></span>
        <!-- 文字边框盒子 -->
      </div>
      <div class="box3-url4">
        <div class="box-text1">
          印花机成套设备
        </div>
        <div class="box-text2">
          自主研发、超强印刷工艺、环保节约
        </div>
        <!-- 文字边框盒子 -->
        <span class="box-border"></span>
        <span class="box-border-left"></span>
        <span class="box-border-right"></span>
        <!-- 文字边框盒子 -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 企业简介
  name: 'companyProfile'
}
</script>

<style scoped>
.enterprise-box {
  width: 83.625rem;
  height: 100%;
  margin-left: 10.9375rem;
  margin-top: 5.3125rem;
}

.box1 {
  width: 100%;
  height: 41.375rem;
  background-color: #000;
  margin-bottom: 4.0625rem;
}

.enterprise-box p {
  font-size: 1.75rem;
  line-height: 5rem;
  color: #000;
}

.box2 {
  width: 100%;
  height: 41.375rem;
  margin: 3.3125rem 0 3.75rem 0;
}

.box3 {
  width: 100%;
  height: 100%;
  margin-bottom: 6.5625rem;
  margin-top: 7.8125rem;
}

.box3 p {
  width: 26.6875rem;
  height: 3.375rem;
  margin: 0 0 2.8125rem .375rem;
  line-height: 3.375rem;
  padding-left: 1.125rem;
  border-left: .5rem solid #004ea1;
  font-size: 2rem;
  color: #000;
}

.box3-url1 {
  display: flex;
  width: 40.25rem;
  height: 15.5rem;
  margin-left: .875rem;
  display: inline-block;
  border-radius: .375rem;
  background: url('../../assets/imgs/关于我们/1/图层 562.png');
  background-size: cover;
}

.box3-url2 {
  width: 40.25rem;
  height: 15.5rem;
  margin-left: 2.25rem;
  display: inline-block;
  border-radius: .375rem;
  background: url('../../assets/imgs/关于我们/1/机床.png');
  background-size: cover;
}

.box3-url3 {
  width: 40.25rem;
  height: 15.5rem;
  display: inline-block;
  margin: 2.3125rem 0 0 .875rem;
  border-radius: .375rem;
  background: url('../../assets/imgs/关于我们/1/图层 570.png');
  background-size: cover;
}

.box3-url4 {
  width: 40.25rem;
  height: 15.5rem;
  display: inline-block;
  margin: 2.3125rem 0 0 2.25rem;
  border-radius: .375rem;
  background: url('../../assets/imgs/关于我们/1/mso高速凹版直印印花机印花机.png');
  background-size: cover;
}

.box-text1 {
  display: flex;
  justify-content: center;
  line-height: 1.875rem;
  margin-top: 5rem;
  font-size: 2rem;
  color: #fff;
}

.box-text2 {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
  font-size: 1.25rem;
  color: #fff;
}

/* 底部四个盒子 */
.box3-url1,
.box3-url2,
.box3-url3,
.box3-url4 {
  position: relative;
}

/* 文字外部边框 */
.box-border {
  position: absolute;
  top: 5.75rem;
  left: 7.125rem;
  width: 26rem;
  height: 5.125rem;
  display: block;
  border: .125rem solid #fff;
  border-top: none
}

.box-border-left {
  position: absolute;
  top: 5.75rem;
  left: 7.1875rem;
  display: block;
  width: 70px;
  height: 2px;
  background-color: #fff;
}

.box-border-right {
  position: absolute;
  top: 5.75rem;
  right: 6.9375rem;
  display: block;
  width: 70px;
  height: 2px;
  background-color: #fff;
}

/* 文字外部边框 */

/* 第三章图片文字外部边框 */
.box-border1 {
  position: absolute;
  top: 5.75rem;
  left: 7.125rem;
  width: 26rem;
  height: 5.125rem;
  display: block;
  border: .125rem solid #fff;
  border-top: none
}

.box-border-left1 {
  position: absolute;
  top: 5.75rem;
  left: 7.1875rem;
  display: block;
  width: 1.875rem;
  height: 2px;
  background-color: #fff;
}

.box-border-right1 {
  position: absolute;
  top: 5.75rem;
  right: 6.9375rem;
  display: block;
  width: 1.875rem;
  height: 2px;
  background-color: #fff;
}

/* 文字外部边框 */
</style>
