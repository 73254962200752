<template>
  <div class="box">
    <!-- 标题和搜索盒子 -->
    <div class="headlogo">
      <!-- 左侧盒子 -->
      <div class="head-left">
        <!-- logo -->
          <div class="msoimg">
            <img src="../assets/imgs/clurlogo.png" alt="">
          </div>
          <!-- logo标题 -->
          <div class="mso-title">
            <div class="text1">
              江苏海宏智能科技有限公司
            </div>
            <div class="text2">
              -OCEAN&MACRO INTELLGENT TECHNOLOGY-
            </div>
          </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="head-rgiht">
        <!-- 微信公众号 -->
        <div class="weChat-official-account">
          <img src="../assets/imgs/微信.png" alt="">
          <div class="a-left-box">
            <el-button type="text" class="but-left">微信公众号</el-button>
          </div>
          <el-button type="text" class="but-right">收藏本站</el-button>
          <!-- 二维码 -->
          <div class="QR-code">
            <img src="../assets/imgs/公众号 拷贝.png" alt="">
          </div>
        </div>
        <!-- 右侧搜索输入框和按钮 -->
        <div class="head-inp">
          <el-input type="text" placeholder="输入关键词搜索" v-model="search"></el-input>
          <el-button class="head-but" @click="searchbtn">搜索</el-button>
        </div>
      </div>
    </div>
    <!-- 导航栏盒子 -->
    <el-breadcrumb class="Navigation">
        <el-breadcrumb-item class="a-link" :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: './AboutUs' }"><a>关于我们</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/ProductCenter' }"><a>产品中心</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/CaseSharing' }"><a>案例分享</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/TalentRecruitment' }"><a>人才招聘</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/BusinessCooperation' }"><a>商务合作</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Notice' }"><a>公告</a></el-breadcrumb-item>
        <!-- <el-breadcrumb-item class="a-link" :to="{ path: '/News' }"><a>企业新闻</a></el-breadcrumb-item> -->
        <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 顶部图片盒子 -->
    <div class="top-box">
      <p class="top-p1">人才招聘</p>
      <p class="top-p2">JOIN US</p>
    </div>
    <!-- 您的位置 -->
    <div class="location-top">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 走进我们标题 -->
    <div class="top-title">
      <p class="top-title1">走进我们，用心成就美好</p>
      <p class="top-title2">用不同的眼光看世界，均会发现不同的精彩</p>
    </div>
    <!-- 标题下方圆圈盒子 -->
    <div class="circle-box">
      <div class="circle-box1">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-1.png" alt="">
        </p>
        <div class="circle-box1-p2">
          <p class="circle-box1-p2-text1">沟通无压力</p>
          <p class="circle-box1-p2-text2">沟通是面对面的相互学习的过程，三人行必有我师焉，抱着虚心的态度去沟通，会让工作更加<br />
          <span>顺畅且有效</span></p>
        </div>
      </div>
      <div class="circle-box2">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-2.png" alt="">
        </p>
        <div class="circle-box1-p2">
          <p class="circle-box1-p2-text1">变的更有趣</p>
          <p class="circle-box1-p2-text2">死板的内容不是我们的想要的，任何在人们看起来不可理喻的事物，我们总会找到他有趣的一面</p>
        </div>
      </div>
      <div class="circle-box3">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-3.png" alt="">
        </p>
        <div class="circle-box1-p2">
          <p class="circle-box1-p2-text1">实操大于理论</p>
          <p class="circle-box3-p2-text2">不要将一些不明所以的内容挂在嘴上，最终能够拿出来的内容才<br />
          <span>是最能够说服人的</span></p>
        </div>
      </div>
      <div class="circle-box4">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-4.png" alt="">
        </p>
        <div class="circle-box4-p2">
          <p class="circle-box1-p2-text1">沟通无压力</p>
          <p class="circle-box4-p2-text2">作为分享倡导者，怎样更完美的将分享机制运营到工作和生活中，分享可以让更多人获得你的<br />
          <span>快乐</span></p>
        </div>
      </div>
      <div class="circle-box5">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-5.png" alt="">
        </p>
        <div class="circle-box5-p2">
          <p class="circle-box1-p2-text1">专业的客服</p>
          <p class="circle-box5-p2-text2">深度了解需求，优化沟通流程，减少沟通时间，提升沟通效率；精细研究品牌受众进行策略定制</p>
        </div>
      </div>
      <div class="circle-box6">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-6.png" alt="">
        </p>
        <div class="circle-box6-p2">
          <p class="circle-box1-p2-text1">重视高效沟通</p>
          <p class="circle-box6-p2-text2">我们需要关心他们的一举一动，留意他们的话题以及关心的事物，并适时的参与到他们的讨论</p>
        </div>
      </div>
      <div class="circle-box7">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-7.png" alt="">
        </p>
        <div class="circle-box7-p2">
          <p class="circle-box1-p2-text1">什么是互动</p>
          <p class="circle-box7-p2-text2">让企业与消费者之间“动”起来。创造一切消费者感兴趣的互动点，让消费者与企业尽可能的<br />
          <span>保持联系</span></p>
        </div>
      </div>
      <div class="circle-box8">
        <p class="circle-box1-p1">
          <img src="../assets/imgs/人才招聘/about-good-8.png" alt="">
        </p>
        <div class="circle-box8-p2">
          <p class="circle-box1-p2-text1">有趣的数据</p>
          <p class="circle-box8-p2-text2">数据可以为我们带来最为精准的策略指引，那么我们如何使用以及分析数据，并从中获得商业机<br />
          <span>会呢？</span></p>
        </div>
      </div>
    </div>
    <!-- 图片盒子 -->
    <div class="box-img">
      <img src="../assets/imgs/人才招聘/xuachuan.png" alt="">
    </div>
    <!-- 联系方式大盒子 -->
    <div class="contact">
      <!-- 投递简历 -->
      <div class="contact-img1">
        <img src="../assets/imgs/产品中心/5/椭圆 3.png" alt="">
        <p class="contact-img1-text">01</p>
      </div>
      <div class="contact-img1-text1">
        投递简历
      <p>POST E-MAIL</p>
      </div>
      <div class="contact-img1-text2">
        E-MAIL :jingzhaoyin@mail.chinamso.com
      </div>
      <div class="btn1">
        <el-button type="primary">点击投递</el-button>
      </div>
      <!-- 电话联系我们 -->
      <div class="contact-img2">
        <img src="../assets/imgs/产品中心/5/椭圆 3.png" alt="">
        <p class="contact-img1-text">02</p>
      </div>
      <div class="contact-img2-text1">
        电话联系我们
      <p>CONTACT US</p>
      </div>
      <div class="contact-img2-text2">
        TEL : 19901541926
      </div>
      <div class="btn2">
        <el-button type="primary">在线咨询</el-button>
      </div>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
    <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
            <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
        <p class="bottom-box-right-text">
          关于我们 | 网站地图 | 网站声明 <br />
          联系电话：19901541926 (武经理 微信同号) <br />
          电子邮箱：jingzhaoyin@mail.chinamso.com <br />
          联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号  <br />
        </p>
        <!-- 底部二维码 -->
        <div class="bottom-box-right-text1">
          <img src="../assets/imgs/公众号 拷贝.png" alt="">
          <p>微信公众号</p>
        </div>
        <!-- 底部版权 -->
        <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
        <el-button>
          <img src="../assets/imgs/侧边栏/微信.png" alt="">
          <p>微信</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/形状.png" alt="">
          <p>在线沟通</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/电话.png" alt="">
          <p>联系电话</p>
        </el-button>
        <el-button @click="toTop">
          <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
          <p>返回顶部</p>
        </el-button>
    </div>
  </div>
</template>

<script>
export default {
  // 人才招聘
  name: 'TalentRecruitment',
  data () {
    return {
      search: ''
    }
  },
  created () {
    // 打开组件回到顶部
    this.toTop()
  },
  methods: {
    searchbtn () {
      console.log(this.search)
    },
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    },
    toTop () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.top-box{
  position: relative;
  width: 100%;
  height: 22.5rem;
  background-image: url('../assets/imgs/人才招聘/图层 540.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.top-p1{
  position: absolute;
  top: 6.9375rem;
  left: 53.8125rem;
  font-size: 3.75rem;
  font-weight: 700;
  color: #fffefe;
}

.top-p2{
  position: absolute;
  top: 12.5625rem;
  left: 54.8125rem;
  font-size: 3.125rem;
  font-weight: 700;
  color: #fffefe;
}

.location-top{
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3.625rem;
  padding-left: 8.125rem;
  line-height: 1.5625rem;
  font-size: 1.625rem;
  color: #3f3f3f;
}

/deep/ .location-top .el-breadcrumb__inner, /deep/ .el-icon-arrow-right:before,/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner{
  font-size: 1.625rem;
  color: #3f3f3f;
}

/deep/.location-top .el-breadcrumb__inner.is-link{
  font-weight: 400 !important;
}

/deep/.location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
  color: #3f3f3f;
}

.top-title{
  position: relative;
  width: 100%;
  height: 9.375rem;
}

.top-title1{
  position: absolute;
  top: 0;
  left: 36rem;
  font-weight: 400;
  font-size: 4.375rem;
  color: #000;
}

.top-title2{
  position: absolute;
  bottom: 0;
  left: 36.125rem;
  font-size: 2.5rem;
  color: #000;
}

.circle-box{
  position: relative;
  margin-top: 5.5rem;
  width: 100%;
  height: 53.5625rem;
}

.circle-box1{
  position: absolute;
  top: 0;
  left: 17.375rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box1-p1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.75rem;
  width: 11.9375rem;
  height: 11.9375rem;
  border-radius: 50%;
  border: .1875rem solid #d80c18
}

.circle-box1-p1 img{
  width: 4rem;
  height: 4rem;
}

.circle-box1-p2-text1{
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1.875rem;
  color: #d93146;
}

.circle-box1-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box1-p2-text2 span{
  position: absolute;
  bottom: -1.25rem;
  left: 4.6875rem;
}

.circle-box2{
  position: absolute;
  top: 0;
  left: 40.8125rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box3{
  position: absolute;
  top: 0;
  left: 64.3125rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box3-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box3-p2-text2 span{
  position: absolute;
  bottom: -1.5625rem;
  left: 3.3125rem;
}

.circle-box4{
  position: absolute;
  top: 0;
  left: 87.6875rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box4-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box4-p2-text2 span{
  position: absolute;
  bottom: -1.5625rem;
  left: 7.1875rem;
}

.circle-box5{
  position: absolute;
  top: 29.3125rem;
  left: 17.375rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box5-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box6{
  position: absolute;
  top: 29.3125rem;
  left: 40.8125rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box6-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box7{
  position: absolute;
  top: 29.3125rem;
  left: 64.3125rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box7-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box7-p2-text2 span{
  position: absolute;
  bottom: -1.5625rem;
  left: 5.9375rem;
}

.circle-box8{
  position: absolute;
  top: 29.3125rem;
  left: 87.6875rem;
  width: 15.6875rem;
  height: 23.9375rem;
  background-color: #fff;
}

.circle-box8-p2-text2{
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8125rem;
  width: 15.8125rem;
  font-size: 1.125rem;
  color: #444242;
}

.circle-box8-p2-text2 span{
  position: absolute;
  bottom: -1.5625rem;
  left: 5.9375rem;
}

.box-img{
  margin-top: 5rem;
  width: 100%;
  height: 38.0625rem;
}

.contact{
  position: relative;
  width: 100%;
  height: 18.375rem;
}

.contact-img1{
  position: absolute;
  top: 5.8125rem;
  left: 31.3125rem;
  width: 5.6875rem;
  height: 5.3125rem;
}

.contact-img1-text{
  position: absolute;
  top: .9375rem;
  left: 1.5625rem;
  font-size: 2.25rem;
  color: #fff;;
}

.contact-img1-text1{
  position: absolute;
  top: 6.25rem;
  left: 39.5rem;
  font-size: 2rem;
  color: #000;
}

.contact-img1-text1 p{
  margin-top: .3125rem;
  font-size: 1.25rem;
  color: #7f7f7f;
}

.contact-img1-text2{
  position: absolute;
  top: 14.3125rem;
  left: 25.375rem;
  font-size: 1.75rem;
  color: #000;
}

.btn1{
  position: absolute;
  top: 19rem;
  left: 35.625rem;
}

.btn1 .el-button{
  font-size: 1.75rem;
  width: 13rem;
  height: 3.5rem;
}

.contact-img2{
  position: absolute;
  top: 5.8125rem;
  right: 45.4375rem;
  width: 5.6875rem;
  height: 5.3125rem;
}

.contact-img2-text1{
  position: absolute;
  top: 6.25rem;
  right: 31.25rem;
  font-size: 2rem;
  color: #000;
}

.contact-img2-text1 p{
  margin-top: .3125rem;
  font-size: 1.25rem;
  color: #7f7f7f;
}

.contact-img2-text2{
  position: absolute;
  top: 14.3125rem;
  right: 31.4375rem;
  font-size: 1.75rem;
  color: #000;
}

.btn2{
  position: absolute;
  top: 19rem;
  right: 32.5625rem;
}

.btn2 .el-button{
  font-size: 1.75rem;
  width: 13rem;
  height: 3.5rem;
}

</style>
