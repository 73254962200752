<template>
  <div class="box">
    <!-- 标题和搜索盒子 -->
    <div class="headlogo">
      <!-- 左侧盒子 -->
      <div class="head-left">
        <!-- logo -->
        <div class="msoimg">
          <img src="../assets/imgs/clurlogo.png" alt="">
        </div>
        <!-- logo标题 -->
        <div class="mso-title">
          <div class="text1">
            江苏海宏智能科技有限公司
          </div>
          <div class="text2">
            -OCEAN&MACRO INTELLGENT TECHNOLOGY-
          </div>
        </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="head-rgiht">
        <!-- 微信公众号 -->
        <div class="weChat-official-account">
          <img src="../assets/imgs/微信.png" alt="">
          <div class="a-left-box">
            <el-button type="text" class="but-left">微信公众号</el-button>
          </div>
          <el-button type="text" class="but-right">收藏本站</el-button>
          <!-- 二维码 -->
          <div class="QR-code">
            <img src="../assets/imgs/公众号 拷贝.png" alt="">
          </div>
        </div>
        <!-- 右侧搜索输入框和按钮 -->
        <div class="head-inp">
          <el-input type="text" placeholder="输入关键词搜索" v-model="search"></el-input>
          <el-button class="head-but" @click="searchbtn">搜索</el-button>
        </div>
      </div>
    </div>
    <!-- 导航栏盒子 -->
    <el-breadcrumb class="Navigation">
      <el-breadcrumb-item class="a-link" :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: './AboutUs' }"><a>关于我们</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/ProductCenter' }"><a>产品中心</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/CaseSharing' }"><a>案例分享</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/TalentRecruitment' }"><a>人才招聘</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/Businesscooperation' }"><a>商务合作</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/Notice' }"><a>公告</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item class="a-link" :to="{ path: '/News' }"><a>企业新闻</a></el-breadcrumb-item> -->
      <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 顶部图片盒子 -->
    <div class="top-box">
      <p class="top-p1">案例分享</p>
      <p class="top-p2">SUCCESS CASE</p>
    </div>
    <!-- 您的位置 -->
    <div class="location-top">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CaseSharing' }">案例分享</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 智能移动机器人 -->
    <div class="title">
      智能移动机器人
      <p class="title-box"></p>
    </div>
    <!-- 智能移动机器人下方内容 -->
    <div class="details-box">
      <!-- 图片左侧的盒子1 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/1-小车配图/AMR-036-2.jpg" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            智能移动机器人小车系列
          </p>
          <p class="details-box-left2-text2">
            AMR（即Autonomous Mobile Robots） 是一种工业机器人，广泛应用于移动测试、车间生产、仓储搬运、设备检修、超长大件运输等领域。
          </p>
          <router-link :to="{ path: '/trolley' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
      <!-- 图片右侧的盒子1 -->
      <div class="details-box-right">
        <div class="details-box-right1">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            智能仓储
          </p>
          <p class="details-box-left2-text2">
            智能仓储作为物流的一个重要环节，包括收货验收、上架入库、订单下发、拣货、复核包装、集货等作业环节。
          </p>
          <router-link :to="{ path: '/OnlyWarehousing' }" :underline="false">了解详情 ></router-link>
        </div>
        <div class="details-box-right2">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/2-智能仓储/智能仓储.jpeg" alt="">
        </div>
      </div>
      <!-- 图片左侧的盒子2 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/3-移动充电桩【配图/图片3.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            移动充电桩
          </p>
          <p class="details-box-left2-text2">
            现在新能源电动车是中国汽车市场的新兴产业，国家也大力支持发展新能源汽车，但是在停车场需要建立许多新能源车充电桩，这样大大降低了停车场车位和场地的利用率。我司
          </p>
          <router-link :to="{ path: '/MobileChargingPile' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
    </div>
    <!-- 智能制造 -->
    <div class="title1">
      智能制造
      <p class="title-box"></p>
    </div>
    <!-- 智能制造下方内容 -->
    <div class="details-box">
      <!-- 图片左侧的盒子1 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 15.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            机器人锻造自动化生产线
          </p>
          <p class="details-box-left2-text2">
            锻造机器人系统由工业机器人、专用手爪、锻压机床及锻模、自动运输设备、自动喷雾设备、安全防护系统、自动控制系统等部分组成。
          </p>
          <router-link :to="{ path: '/RobotForging' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
      <!-- 图片右侧的盒子1 -->
      <div class="details-box-right">
        <div class="details-box-right1">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            冲压自动化生产线
          </p>
          <p class="details-box-left2-text2">
            冲压机器人系统由冲压机器人、专用洗盘/手爪、冲压机床及冲压模、自动运输设备、安全防护系统、自动控制系统等部分组成。
          </p>
          <router-link :to="{ path: '/StampingAutomation' }" :underline="false">了解详情 >
          </router-link>
        </div>
        <div class="details-box-right2">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 17.png" alt="">
        </div>
      </div>
      <!-- 图片左侧的盒子2 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 18.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            压铸自动化产线
          </p>
          <p class="details-box-left2-text2">
            压铸机器人系统由压铸机器人、延长轴、定量液勺、检测系统、输送机、下料机器人、安全防护系统、自动控制系统等部分组成。
          </p>
          <router-link :to="{ path: '/DieCastingAutomation' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
      <!-- 图片右侧的盒子2 -->
      <div class="details-box-right">
        <div class="details-box-right1">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            码垛自动化产线
          </p>
          <p class="details-box-left2-text2">
            码垛机器人系统由码垛机器人、码垛手抓、托盘定位系统、托盘输送线、安全防护系统、自动控制系统等部分组成。
          </p>
          <router-link :to="{ path: '/StackingAutomation' }" :underline="false">了解详情 ></router-link>
        </div>
        <div class="details-box-right2">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 19.png" alt="">
        </div>
      </div>
      <!-- 图片左侧的盒子3 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 20.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            机加工上下料自动化产线
          </p>
          <p class="details-box-left2-text2">
            机加工器人上下料系统由自动化机器人、机器人手抓、工件定位系统、检测系统、上下料输送线、安全防护系统、自动控制系统等部分组成。
          </p>
          <router-link :to="{ path: '/Machining' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
      <!-- 图片右侧的盒子3 -->
      <div class="details-box-right">
        <div class="details-box-right1">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            焊接机器人自动化线
          </p>
          <p class="details-box-left2-text2">
            焊接器人自动化线由焊接机器人、焊枪、视觉定位系统、焊枪配件、变位机、安全防护系统、自动控制系统等部分组成。
          </p>
          <router-link :to="{ path: '/WeldingRobot' }" :underline="false">了解详情 ></router-link>
        </div>
        <div class="details-box-right2">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 23.png" alt="">
        </div>
      </div>
      <!-- 图片左侧的盒子4 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 21.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            非标自动化组装线
          </p>
          <p class="details-box-left2-text2">
            根据客户需求，按照工件产品的加工工艺，定制的非标自动化线，根据客户需求，定制开发。满足客户上下料，搬运，组装，加工，分选，等不同的自动化生产需求。
          </p>
          <router-link :to="{ path: '/NonstandardAutomation' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
      <!-- 图片右侧的盒子4 -->
      <div class="details-box-right">
        <div class="details-box-right1">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            立体仓库
          </p>
          <p class="details-box-left2-text2">
            立体厂库主要由货架，管理软件，进料出料输送线，智能移动小车，堆垛机等部分组成。
          </p>
          <router-link :to="{ path: '/StereoscopicWarehouse' }" :underline="false">了解详情 ></router-link>
        </div>
        <div class="details-box-right2">
          <img src="../assets/imgs/案例分享/1-框架页/1-移动机器人配图/4-智能制造/图层 22.png" alt="">
        </div>
      </div>
    </div>
    <!-- 物联网软件硬件服务 -->
    <div class="title1">
      物联网软件硬件服务
      <p class="title-box"></p>
    </div>
    <!-- 物联网软件硬件服务下方内容 -->
    <div class="details-box">
      <!-- 图片左侧的盒子1 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/3物联网图片/1-连e行/微信图片_20220708180628.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            连云港市交通控股集团连e行系统
          </p>
          <p class="details-box-left2-text2">
            连云港市交通控股集团连e行系统
          </p>
          <router-link :to="{ path: '/TransportationHoldingGroup' }" :underline="false">了解详情 >
          </router-link>
        </div>
      </div>
      <!-- 图片右侧的盒子1 -->
      <div class="details-box-right">
        <div class="details-box-right1">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            连云港智慧石梁河监管平台
          </p>
          <p class="details-box-left2-text2">
            该平台是对石梁河水库的砂石开采、水文水质、生态养殖等全面监督、综合管理、做到“信息透明、预报预警”，“成果上屏，一屏指挥”
          </p>
          <router-link :to="{ path: '/SupervisionPlatform' }" :underline="false">了解详情 ></router-link>
        </div>
        <div class="details-box-right2">
          <img src="../assets/imgs/案例分享/1-框架页/3物联网图片/2-石梁河/微信图片_20220709152241(1).jpg" alt="">
        </div>
      </div>
      <!-- 图片左侧的盒子2 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/3物联网图片/3-日出东方MES/微信图片_20220709152326.png" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            日出东方集团平板集热器长MES管理系统
          </p>
          <p class="details-box-left2-text2">
            通过MES系统实现平板集热器厂各个信息化模块，以及部分自动化设备的模块集成，打通“最后一公里”信息化，提高生产效率，为领导全局决策提供全面的数据依据。
          </p>
          <router-link :to="{ path: '/MESmanagementsystem' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
    </div>
    <!-- 高速凹版印花及印花产品 -->
    <div class="title1">
      高速凹版印花及印花产品
      <p class="title-box"></p>
    </div>
    <!-- 高速凹版印花及印花产品下方内容 -->
    <div class="details-box1">
      <!-- 图片左侧的盒子1 -->
      <div class="details-box-left">
        <div class="details-box-left1">
          <img src="../assets/imgs/案例分享/1-框架页/4印花图片/mso高速凹版直印印花机印花机.jpg" alt="">
        </div>
        <div class="details-box-left2">
          <!-- 标题 -->
          <p class="details-box-left2-text1">
            MSO高速凹版印花机
          </p>
          <p class="details-box-left2-text2">
            MSO高速凹版数控印花机是采用印版与承压辊相互挤压的方式直接印刷，印版直接制在印版滚筒上，采用浸墨方式给墨，设有匀墨机构。由于墨层厚，使用挥发性强的快干油墨，进烘箱后烘干或发色。具有节能、环保、印花效率高、印花
          </p>
          <router-link :to="{ path: '/printingmachine' }" :underline="false">了解详情 ></router-link>
        </div>
      </div>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
      <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
        <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
        <p class="bottom-box-right-text">
          关于我们 | 网站地图 | 网站声明 <br />
          联系电话：19901541926 (曹经理 微信同号) <br />
          电子邮箱：jingzhaoyin@mail.chinamso.com <br />
          联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号 <br />
        </p>
        <!-- 底部二维码 -->
        <div class="bottom-box-right-text1">
          <img src="../assets/imgs/公众号 拷贝.png" alt="">
          <p>微信公众号</p>
        </div>
        <!-- 底部版权 -->
        <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
      <el-button>
        <img src="../assets/imgs/侧边栏/微信.png" alt="">
        <p>微信</p>
      </el-button>
      <el-button>
        <img src="../assets/imgs/侧边栏/形状.png" alt="">
        <p>在线沟通</p>
      </el-button>
      <el-button>
        <img src="../assets/imgs/侧边栏/电话.png" alt="">
        <p>联系电话</p>
      </el-button>
      <el-button @click="toTop">
        <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
        <p>返回顶部</p>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  // 案例分享
  name: 'CaseSharing',
  data() {
    return {
      search: ''
    }
  },
  created() {
    // 打开组件回到顶部
    this.toTop()
  },
  methods: {
    searchbtn() {
      console.log(this.search)
    },
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    },
    toTop() {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.top-box {
  position: relative;
  width: 100%;
  height: 22.5rem;
  background-image: url('../assets/imgs/案例分享/图层 15.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.top-p1 {
  position: absolute;
  top: 7.25rem;
  left: 52.75rem;
  font-size: 3.75rem;
  font-weight: 700;
  color: #fffefe;
}

.top-p2 {
  position: absolute;
  top: 12.8125rem;
  left: 48.625rem;
  font-size: 3.125rem;
  font-weight: 700;
  color: #fffefe;
}

.location-top {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3.625rem;
  padding-left: 8.125rem;
  line-height: 1.5625rem;
  font-size: 1.625rem;
}

/deep/ .location-top .el-breadcrumb__inner,
/deep/ .el-icon-arrow-right:before,
/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-size: 1.625rem;
  color: #000;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 3.625rem;
  width: 100%;
  height: 4rem;
  font-size: 2.25rem;
  color: #000;
}

.title-box {
  position: absolute;
  bottom: 0;
  width: 10.25rem;
  height: .3125rem;
  background-color: #004ea1;
}

.details-box {
  margin-top: 5.375rem;
  margin-left: 18.6875rem;
  width: 82.5rem;
  height: 100%;
}

.details-box-left {
  position: relative;
  width: 100%;
  height: 20.625rem;
}

.details-box-left1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 41.25rem;
  height: 20.625rem;
}

.details-box-left2 {
  position: absolute;
  top: 2.1875rem;
  right: 2.8125rem;
  width: 35.9375rem;
  height: 14.0625rem;
}

.details-box-left2-text1 {
  font-size: 1.875rem;
  color: #000;
}

.details-box-left2-text2 {
  margin-top: .625rem;
  line-height: 2.5rem;
  font-size: 1.375rem;
  color: #000;
}

/* .router-link.router-link--default{
  margin-top: 1.25rem;
  font-size: 1.375rem;
  color: #004ea1;
} */

.details-box-right {
  position: relative;
  width: 100%;
  height: 20.625rem;
}

.details-box-right1 {
  position: absolute;
  top: 4.5rem;
  left: 2.3125rem;
  width: 35.9375rem;
  height: 14.0625rem;

}

.details-box-right2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 41.25rem;
  height: 20.625rem;
}

.title1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5.9375rem;
  width: 100%;
  height: 4rem;
  font-size: 2.25rem;
  color: #000;
}

.details-box1 {
  margin-top: 5.375rem;
  margin-left: 18.6875rem;
  margin-bottom: 17.1875rem;
  width: 82.5rem;
  height: 100%;
}

/deep/.location-top .el-breadcrumb__inner.is-link {
  font-weight: 400 !important;
}

/deep/.location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #000;
}
</style>
