<template>
  <div class='box'>
    <!-- 标题和搜索盒子 -->
    <div class="headlogo">
      <!-- 左侧盒子 -->
      <div class="head-left">
        <!-- logo -->
          <div class="msoimg">
            <img src="../assets/imgs/clurlogo.png" alt="">
          </div>
          <!-- logo标题 -->
          <div class="mso-title">
            <div class="text1">
              江苏海宏智能科技有限公司
            </div>
            <div class="text2">
              -OCEAN&MACRO INTELLGENT TECHNOLOGY-
            </div>
          </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="head-rgiht">
        <!-- 微信公众号 -->
        <div class="weChat-official-account">
          <img src="../assets/imgs/微信.png" alt="">
          <el-button type="text" class="but-left">微信公众号</el-button>
          <p class="horizontal-line"></p>
          <el-button type="text" class="but-right">收藏本站</el-button>
          <!-- 二维码 -->
          <div class="QR-code">
            <img src="../assets/imgs/公众号 拷贝.png" alt="">
          </div>
        </div>
        <!-- 右侧搜索输入框和按钮 -->
        <div class="head-inp">
          <el-input type="text" placeholder="输入关键词搜索" v-model="search"></el-input>
          <el-button class="head-but" @click="searchbtn">搜索</el-button>
        </div>
      </div>
    </div>
    <!-- 导航栏盒子 -->
    <el-breadcrumb class="Navigation">
        <el-breadcrumb-item class="a-link" :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: './AboutUs' }"><a>关于我们</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/ProductCenter' }"><a>产品中心</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/CaseSharing' }"><a>案例分享</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/TalentRecruitment' }"><a>人才招聘</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Businesscooperation' }"><a>商务合作</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Notice' }"><a>公告</a></el-breadcrumb-item>
        <!-- <el-breadcrumb-item class="a-link" :to="{ path: '/News' }"><a>企业新闻</a></el-breadcrumb-item> -->
        <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 您的位置 -->
    <div class="location">
      <!-- 上半部分标题 -->
      <div class="location-top">
        <p>您的位置:</p>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>企业新闻</el-breadcrumb-item>
          <el-breadcrumb-item>{{this.activeName}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容盒子 -->

    <!-- 底部盒子 -->
    <div class="bottom-box">
      <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
          <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
            <p class="bottom-box-right-text">
              关于我们 | 网站地图 | 网站声明 <br />
              联系电话：19901541926 (曹经理 微信同号) <br />
              电子邮箱：jingzhaoyin@mail.chinamso.com <br />
              联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号  <br />
            </p>
            <!-- 底部二维码 -->
            <div class="bottom-box-right-text1">
              <img src="../assets/imgs/公众号 拷贝.png" alt="">
              <p>微信公众号</p>
            </div>
            <!-- 底部版权 -->
          <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
        <el-button>
          <img src="../assets/imgs/侧边栏/微信.png" alt="">
          <p>微信</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/形状.png" alt="">
          <p>在线沟通</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/电话.png" alt="">
          <p>联系电话</p>
        </el-button>
        <el-button @click="toTop">
          <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
          <p>返回顶部</p>
        </el-button>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      // 企业新闻
      name: 'News',
      activeName: '企业新闻',
      search: ''
    }
  },
  methods: {
    searchbtn() {
      console.log(this.search)
    }
  }
}
</script>

<style scoped>
  .location{
    width: 100%;
    height: 100%;
    margin-top: 3.9375rem;
    /* padding-left: 8.125rem; */
  }

  .location-top{
      width: 100%;
      height: 100%;
      display: flex;
      padding-bottom: 3.75rem;
      line-height: 1.5625rem;
      font-size: 1.625rem;
  }

  .location-top .el-breadcrumb, /deep/ .location .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator{
    font-size: 1.625rem;
    color: #000;
  }

  /deep/ .location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner{
    color: #000 !important;
  }

  .location-top p{
    width: 112px;
    margin-right: .625rem;
    padding-left: 8.125rem;
  }

</style>
