import { render, staticRenderFns } from "./HonorsQualifications.vue?vue&type=template&id=0980255a&scoped=true&"
import script from "./HonorsQualifications.vue?vue&type=script&lang=js&"
export * from "./HonorsQualifications.vue?vue&type=script&lang=js&"
import style0 from "./HonorsQualifications.vue?vue&type=style&index=0&id=0980255a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0980255a",
  null
  
)

export default component.exports