<template>
  <div class="box">
    <!-- 标题和搜索盒子 -->
    <div class="headlogo">
      <!-- 左侧盒子 -->
      <div class="head-left">
        <!-- logo -->
          <div class="msoimg">
            <img src="../assets/imgs/clurlogo.png" alt="">
          </div>
          <!-- logo标题 -->
          <div class="mso-title">
            <div class="text1">
              江苏海宏智能科技有限公司
            </div>
            <div class="text2">
              -OCEAN&MACRO INTELLGENT TECHNOLOGY-
            </div>
          </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="head-rgiht">
        <!-- 微信公众号 -->
        <div class="weChat-official-account">
          <img src="../assets/imgs/微信.png" alt="">
          <el-button type="text" class="but-left">微信公众号</el-button>
          <p class="horizontal-line"></p>
          <el-button type="text" class="but-right">收藏本站</el-button>
          <!-- 二维码 -->
          <div class="QR-code">
            <img src="../assets/imgs/公众号 拷贝.png" alt="">
          </div>
        </div>
        <!-- 右侧搜索输入框和按钮 -->
        <div class="head-inp">
          <el-input type="text" placeholder="输入关键词搜索" v-model="search"></el-input>
          <el-button class="head-but" @click="searchbtn">搜索</el-button>
        </div>
      </div>
    </div>
    <!-- 导航栏盒子 -->
    <el-breadcrumb class="Navigation">
        <el-breadcrumb-item class="a-link" :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: './AboutUs' }"><a>关于我们</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/ProductCenter' }"><a>产品中心</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/CaseSharing' }"><a>案例分享</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/TalentRecruitment' }"><a>人才招聘</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Businesscooperation' }"><a>商务合作</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Notice' }"><a>公告</a></el-breadcrumb-item>
        <!-- <el-breadcrumb-item class="a-link" :to="{ path: '/News' }"><a>企业新闻</a></el-breadcrumb-item> -->
        <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 顶部走马灯盒子 -->
    <div class="riding-lantern">
        <template>
          <el-carousel indicator-position="none" arrow="always" height="43.75rem">
            <el-carousel-item v-for="item in urls1" :key="item.id">
              <img :src="item.url" alt="">
            </el-carousel-item>
          </el-carousel>
        </template>
    </div>
    <!-- 关于我们盒子 -->
    <div class="about-us">
        <p class="about-top">关于我们</p>
        <div class="xian"></div>
        <p style="color: #585858">我们秉承“智能改变世界，创新引领未来”的价值理念</p>
    </div>
    <!-- 企业简介盒子 -->
    <div class="company-profile">
        <!-- 顶部线盒子 -->
        <div class="company-xian" />
        <p class="company-a">企业简介</p>
        <p class="company-b">江苏海宏智能科技有限公司</p>
        <p class="company-c">
          江苏海宏智能科技有限公司 (以下简称“海宏科技”) 是隶属于连云港市交通控股集团的国有控股公司，主要从事工业物联网软件硬件服务、MSO高速凹版印花成套设备、基于MSO多轴运动控制系统的非标自动化成套设备以及智能移动机器人等研发业务、旨在用自主研发多轴运动控制系统为客户提供便捷高效、节能环保等优势为一体的服务。 <br/>
          海宏科技本部建设于连云港，目前于浙江柯桥、嵊州均设有印花办事处，是国内少数拥有成套核心技术自主知识产权和具有自主配套能力的企业之一，盈利能力和价值创造能力在同行中处于领先地位。
        </p>
        <el-button class="company-but" @click="jumpbtn">查看详情 〉</el-button>
        <div class="company-img">
          <img src="../assets/imgs/企业简介.png" alt="">
          <ul>
            <li>企业简介</li>
            <li>发展历史</li>
            <li>荣誉资质</li>
            <li>知识产权</li>
            <li>方向目标</li>
            <li>企业愿景</li>
          </ul>
        </div>
    </div>
    <!-- 产品中心盒子 -->
    <div class="product-center">
        <!-- 左侧图片盒子 -->
          <img src="../assets/imgs/图片1.png" alt="">
        <!-- 走马灯盒子 -->
        <div class="product-riding-lamp-box">
          <!-- 走马灯上方按钮 -->
          <el-row class="product-btn">
            <el-button v-for="item in urls2btn" :key="item.id" round @click="probtn(item.id)" :class="[item.id === CarouseId ? 'product-btn-add' : ''  ] ">
              {{item.name}}
            </el-button>
          </el-row>
        <!-- 走马灯 -->
          <div class="product-riding-lamp">
            <template>
              <el-carousel :autoplay="false" :interval="4000" type="card" @change="carouselChange" ref="carousel" height="31.5rem" arrow="always">
                <el-carousel-item v-for="item in urls2" :key="item.id">
                  <img :src="item.url" alt="" style="width: 100%; padding: 0; height: 100%">
                </el-carousel-item>
              </el-carousel>
            </template>
          </div>
        </div>
    </div>
    <!-- 专业研发团队盒子 -->
    <div class="professional-team">
        <!-- 走马灯 -->
        <template>
          <el-carousel indicator-position="outside" height="46rem" arrow="always">
            <el-carousel-item v-for="item in urls3" :key="item.id">
              <img :src="item.url" alt="">
            </el-carousel-item>
          </el-carousel>
        </template>

    </div>
    <!-- 客户群体分布盒子 -->
    <div class="customer-group">
        <!-- 客户群体分布盒子 -->
        <div class="customer-group-top">
          <!-- 左侧边框 -->
          <div class="customer-left1"></div>
          <div class="customer-left2"></div>
          <!-- 中间字体 -->
          <p class="customer-text1">客户群体分布</p>
          <p class="customer-text2">为客户<span>创造价值、传递价值</span>是我们的最终使命</p>
          <!-- 右侧边框 -->
          <div class="customer-right1"></div>
          <div class="customer-right2"></div>
        </div>
        <!-- 下方图片 -->
        <div class="customer-group-bottom">
          <img src="../assets/imgs/矢量智能对象2.png" alt="">
        </div>
    </div>
    <!-- 海虹科技市场网络地图 -->
    <div class="map">
        <!-- 顶部左侧标题 -->
        <div class="map-text">
          <p>海宏科技<span>市场网络</span></p>
          <p class="map-text1">13</p>
          <p class="map-text2">省份城市地区</p>
          <p class="map-box"></p>
          <p class="map-text3">江苏、广东、安徽、浙江、山东、黑龙江、西藏、上海、重庆、四川、福建、河南和河北</p>
          <!-- 地图盒子 -->
          <div class="map-img">
            <img src="../assets/imgs/地图/合并地图.png" alt="">
          </div>
        </div>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
      <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
          <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
            <p class="bottom-box-right-text">
              关于我们 | 网站地图 | 网站声明 <br />
              联系电话：19901541926 (曹经理 微信同号) <br />
              电子邮箱：jingzhaoyin@mail.chinamso.com <br />
              联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号  <br />
            </p>
            <!-- 底部二维码 -->
            <div class="bottom-box-right-text1">
              <img src="../assets/imgs/公众号 拷贝.png" alt="">
              <p>微信公众号</p>
            </div>
            <!-- 底部版权 -->
          <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
      </div>
    <!-- 回到顶部盒子 -->
      <div class="back-top">
        <el-button>
          <img src="../assets/imgs/侧边栏/微信.png" alt="">
          <p>微信</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/形状.png" alt="">
          <p>在线沟通</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/电话.png" alt="">
          <p>联系电话</p>
        </el-button>
        <el-button @click="toTop">
          <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
          <p>返回顶部</p>
        </el-button>
      </div>
  </div>
</template>

<script>
export default {
  // 首页
  name: 'HomePage',
  data () {
    return {
      // 搜索按钮
      search: '',
      // 顶部走马灯图片
      urls1: [
        { id: 1, url: require('../assets/imgs/新建文件夹/1-首页主banner.jpg') },
        { id: 2, url: require('../assets/imgs/新建文件夹/1-机械设备专家.jpg') }
      ],
      // 产品中心走马灯图片
      urls2: [
        { id: 0, url: require('../assets/imgs/新建文件夹/3业务布局-多轴运动器 (2).png') },
        { id: 1, url: require('../assets/imgs/新建文件夹/3业务布局-智能机器人.png') },
        { id: 2, url: require('../assets/imgs/新建文件夹/3业务布局-智能制造.png') },
        { id: 3, url: require('../assets/imgs/新建文件夹/3业务布局-物联网.png') },
        { id: 4, url: require('../assets/imgs/新建文件夹/3业务布局-印花.png') }
      ],
      // 产品中心走马灯按钮
      urls2btn: [
        { id: 0, name: '多轴运动控制器' },
        { id: 1, name: '智能机器人' },
        { id: 2, name: '智能制造' },
        { id: 3, name: '物联网信平台' },
        { id: 4, name: '印花机/印花' },
        { id: 5, name: '更多产品' }
      ],
      urls3: [
        { id: 0, url: require('../assets/imgs/团队资料/团队资料/朱总 (2).png') },
        { id: 1, url: require('../assets/imgs/团队资料/团队资料/许强 (2).png') },
        { id: 2, url: require('../assets/imgs/团队资料/团队资料/李纪灵 (2).png') },
        { id: 3, url: require('../assets/imgs/团队资料/团队资料/陈晓东 (2).png') },
        { id: 4, url: require('../assets/imgs/团队资料/团队资料/何俊 (2).png') }
      ],
      // 走马灯索引
      carouseId: 0,
      // 走马灯按钮索引
      CarouseId: 0
    }
  },
  methods: {
    searchbtn () {
      console.log(this.search)
    },
    jumpbtn () {
      this.$router.replace('/AboutUs')
    },
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    },
    carouselChange (now) {
      this.carouseId = now
      this.CarouseId = this.carouseId
    },
    probtn (id) {
      this.CarouseId = id
      this.carouseId = this.CarouseId
      this.$refs.carousel.setActiveItem(id)
    },
    toTop () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.riding-lantern img{
  width: 100%;
}

/deep/ .riding-lantern .el-carousel__container .el-carousel__arrow{
  margin-left: 11.75rem;
  margin-right: 12.1875rem;
  width: 4rem;
  height: 7.125rem;
  border-radius: 0rem;
  font-size: 4.375rem;
  background: rgba(0, 0, 0, .1);
}

/deep/ .riding-lantern .el-carousel__container .el-icon-arrow-left:hover, /deep/ .riding-lantern .el-carousel__container .el-icon-arrow-right:hover{
  width: 4rem;
  height: 7.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #585858;
}

/deep/ .riding-lantern .el-carousel__container .el-icon-arrow-left::before, /deep/ .riding-lantern .el-carousel__container .el-icon-arrow-right::before{
  width: 4rem;
  height: 7.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.about-us{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 12.5625rem;
}

.about-top{
  font-weight: 500;
  font-size: 1.75rem;
  color: #585858;
}

.about-bottom{
  font-weight: 400;
  font-size: 1.125rem;
}

.xian{
  width: 6.375rem;
  height: .125rem;
  background-color: #004ea1;
  margin: .6875rem 0;
}

.company-profile{
  position: relative;
  padding-left: 17.9375rem;
  width: 100%;
  height: 25.6875rem;
  background-color: #e4f3f9;
}

.company-xian{
  width: 3rem;
  height: 2.125rem;
  border-bottom: .1875rem solid #004ea1;
}

.company-a{
  padding-top: 1.375rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #004ea1;
}

.company-b{
  padding-top: 1.4375rem;
  font-size: 1.125rem;
  font-weight: 700;
  color: #585858
}

.company-c{
  padding-top: 1.4375rem;
  width: 40.625rem;
  height: 10.625rem;
  font-size: 1rem;
  color: #585858;
}

.company-but{
  width: 6.4375rem;
  height: 2.625rem;
  background-color: #e4f3f9;
  border: .0625rem solid #a2a2a2;
}

.company-img{
  position: absolute;
  top: 2.3125rem;
  left: 1230px;
  width: 26.8125rem;
  height: 20.75rem;
}

.company-img ul{
  position: absolute;
  top: 3.0625rem;
  left: -2.3125rem;
  list-style: none;
}

.company-img ul>li{
  width: 5.0625rem;
  height: 2.1875rem;
  display: flex;
  justify-content: center;
  line-height: 2.1875rem;
  border-radius: 2px;
  font-size: .875rem;
  font-weight: 700;
  background-color: #545454;
  color: #fff;
}

.company-img ul>li:first-child{
background-color: #004ea1;
}

.company-img ul>li:not(:first-child){
  margin-top: .5625rem;
}

.product-center{
  margin-top: 6.5rem;
  padding-left: 18.125rem;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 45.625rem;
}

.product-center img{
  border: .0625rem solid #96b6d8;
  width: 19.375rem;
  height: 45.625rem;
  margin-left: 0rem;
}

.product-riding-lamp-box{
  margin-left: 1.4375rem;
  width: 61.9375rem;
  height: 100%;
}

.product-btn{
  display: flex;
  padding-top: .125rem;
  width: 62.0625rem;
  height: 2.8125rem;
}

.product-btn-add{
  color: #fff;
  background-color: #004ea1;
}

/deep/ .el-carousel__indicators--outside{
  display: none;
}

.product-btn .el-button.is-round{
  padding-top: .625rem;
  margin-left: 0;
  height: 2.5rem;
  border: .0625rem solid #004ea1;
  margin-right: .875rem;
  font-size: 1.125rem;
}

.el-button.is-round:hover,.is-round:focus{
  color: #fff;
  background-color: #004ea1;
}

/deep/.product-riding-lamp .el-carousel__arrow--left, /deep/ .product-riding-lamp .el-carousel__arrow--right{
  font-size: 90px;
  font-weight: 400;
  margin-left: 2.625rem;
  margin-right: 2.625rem;
  background-color: transparent;
}

.product-riding-lamp .el-carousel__arrow--left,.el-carousel__arrow--right{
  background-color: transparent;
}

/deep/ .product-riding-lamp .el-carousel__arrow--left:hover, /deep/ .product-riding-lamp .el-carousel__arrow--right:hover{
  color: #014ea1;
  background-color: transparent;
}

.el-button.is-round:last-child{
  width: 8.25rem;
  height: 2.1875rem;
  margin-left: 1.75rem;
  font-size: 1rem;
  color: #cdcdcd;
  border: .0625rem solid #cdcdcd;
}

.product-riding-lamp{
  margin-top: 5.25rem;
  width: 62.0625rem;
  height: 28.875rem;
}

.professional-team{
  margin-top: 6.625rem;
  width: 100%;
  height: 46rem;
}

.professional-team img{
  width: 100%;
  height: 100%;
}

/deep/.professional-team .el-carousel__arrow--left, /deep/ .professional-team .el-carousel__arrow--right{
  width: 3.125rem;
  height: 3.125rem;
  font-size: 2.8125rem;
  margin-left: 8.125rem;
  margin-right: 8.125rem;
  border-radius: 0px;
  background-color: #838f9b;
}

/deep/.professional-team .el-carousel__arrow--left:hover,/deep/ .el-carousel__arrow--right:hover{
  color: #fff;
  background-color: #585858;
}

.customer-group{
  width: 100%;
  height: 36.875rem;
  margin-top: 5.3125rem;
}

.customer-group-top{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6.875rem;
}

.customer-text1{
  width: 12.75rem;
  height: 2.5rem;
  padding-right: 3.6875rem;
  font-size: 2.125rem;
}

.customer-text2{
  line-height: 37px;
  width: 18.75rem;
  height: 4.0625rem;
  font-size: 1.25rem;
}

.customer-text2 span{
  font-size: 1.625rem;
  color: #e6a635;
}

.customer-left1 {
  width: 16.625rem;
  height: .125rem;
  margin-top: .375rem;
  background-color: #d2d2d2;
}

.customer-left2{
  margin-right: 2rem;
  width: 1.375rem;
  height: 6.5rem;
  border: .1875rem solid #d2d2d2; border-right:none;
}

.customer-right1{
  margin-left: 2rem;
  width: 1.375rem;
  height: 6.5rem;
  border: .1875rem solid #d2d2d2; border-left:none;
}

.customer-right2{
  width: 16.625rem;
  height: .125rem;
  margin-top: .375rem;
  background-color: #d2d2d2;
}

.customer-group-bottom{
  width: 100%;
  height: 25.625rem;
  margin-top: 3rem;
}

.customer-group-bottom img{
  margin: .625rem 0 0 1.6875rem;
  width: 116.875rem;
  height: 24.6875rem;
}

.map{
  position: relative;
  width: 100%;
  height: 76.5625rem;
}

.map-text{
  position: absolute;
  left: 17.8125rem;
  top: 6.875rem;
  width: 50.3125rem;
  height: 14.0625rem;
  font-size: 2.25rem;
  font-weight: 700;
}

.map-text span{
  padding-left: 2.5rem;
  font-weight: 400;
  font-size: 1.5rem;
  color: #004ea1;
}

.map-text1{
  font-size: 5rem;
  font-weight: 400;
  color: #004ea1;
}

.map-text2{
  font-weight: 400;
  font-size: 1.375rem;
  color: #787878;
}

.map-text3{
  font-weight: 400;
  font-size: 1.25rem;
  color: #787878;
}

.map-box{
  margin: .625rem 0 .5rem 0;
  width: 18.5625rem;
  height: .0625rem;
  background-color: #004ea1;
}

.map-img{
  position: absolute;
  top: 9.375rem;
  left: 0;
  width: 84.0625rem;
  height: 60.3125rem;
}

.map-img img{
  width: 100%;
  height: 100%;
}

</style>
