<template>
  <div>
    <!-- 高速凹版数控印花机盒子 -->
    <div class="box1">
      <!-- 底部盒子 -->
      <div class="box1-bottom">
        <img src="../../assets/imgs/产品中心/5/矩形 11.png" alt="">
      </div>
      <!-- 左侧标题 -->
      <div class="box1-title">
        High speed gravure direct printing machine
        <p>高速凹版数控印花机</p>
      </div>
      <!-- 左侧下方文字盒子 -->
      <div class="box1-title-box">
        <!-- 圆圈盒子 -->
        <img class="box1-title-img1" src="../../assets/imgs/产品中心/5/椭圆 3.png" alt="">
        <!-- 图片中的文字 -->
        <span class="box1-title-img1-text1">01</span>
        <p class="box1-title-text1">解决环保问题</p>
        <img class="box1-title-img2" src="../../assets/imgs/产品中心/5/椭圆 3.png" alt="">
        <!-- 图片中的文字 -->
        <span class="box1-title-img1-text2">02</span>
        <p class="box1-title-text2">解决效率问题</p>
        <img class="box1-title-img3" src="../../assets/imgs/产品中心/5/椭圆 3.png" alt="">
        <!-- 图片中的文字 -->
        <span class="box1-title-img1-text3">03</span>
        <p class="box1-title-text3">解决工艺问题</p>
        <!-- 盒子中文字 -->
        <p class="box1-title-text4">咨询产品，了解详细价格</p>
        <!-- 背景盒子 -->
        <p class="box1-title-text5">
          <img src="../../assets/imgs/产品中心/5/圆角矩形 1.png" alt="">
        </p>
      </div>
      <!-- 右侧图片 -->
      <div class="box1-right-img">
        <img src="../../assets/imgs/产品中心/5/mso高速凹版直印印花机印花机.png" alt="">
      </div>
      <!-- 下方文字介绍 -->
      <div class="box-bottom-text">
        &emsp;&emsp;MSO高速凹版数控印花机控制系统，采用公司高科技专利产品MSO-MC5型多轴运动控制器作为控制核心，对印花机机械运动部件的位置、速度等进行实时的控制管理，实现精准的同步运动，并且按规定的运动参数和印花工艺实现印花全过程流程控制。   <br />
        &emsp;&emsp;MSO高速凹版数控印花机印花时，印版滚筒全版面着墨，以刮墨刀将版面上空白部分的油墨刮清，留下图文部分的油墨，然后过布，由压印滚筒在布的正面压印，使凹下部分的油墨直接转移到布面上，最后经收布部分将印花品堆集或复卷好。 <br />
        &emsp;&emsp;MSO高速凹版数控印花机是采用印版与承压辊相互挤压的方式直接印刷，印版直接制在印版滚筒上，采用浸墨方式给墨，设有匀墨机构。由于墨层厚，使用挥发性强的快干油墨，进烘箱后烘干或发色。具有节能、环保、印花效率高、印花质量好、维护保养简单等优点。
      </div>
    </div>
    <!-- 印花效果展示 -->
    <div class="box2">
      <div class="box2-top">
        <p class="box2-top-p1">印花效果展示</p>
        <p class="box2-top-p2">Effect  demonstration</p>
        <p class="box2-top-left"></p>
        <p class="box2-top-right"></p>
      </div>
      <!-- 走马灯 -->
      <template>
        <el-carousel :interval="5000" arrow="never" indicator-position="outside"  height="23.875rem">
          <el-carousel-item v-for="item in urls" :key="item.id">
            <!-- 走马灯一页展示的盒子大小 -->
            <span class="ridinglantern-box" v-for="item in item" :key="item.id">
              <img :src="item.url" alt="">
            </span>
          </el-carousel-item>
        </el-carousel>
      </template>
    </div>
    <!-- 产品与技术 -->
    <div class="box3">
        <p class="box3-top-p1">产品与技术</p>
        <p class="box3-top-p2"></p>
        <p class="box3-top-p3">Product and technology</p>
        <!-- 标题下方盒子 -->
        <div class="box3-01">
          <div class="box3-left-img1">
            <img src="./../../assets/imgs/产品中心/5/5-印花机文案+图片/透明 印花机.png" alt="">
          </div>
          <!-- 右侧标题 -->
          <div class="box3-01-title">
            01<p>自主研发的核心控制系统</p>
          </div>
          <div class="box3-01-text1">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/图层 531.png" alt="">
            <p>
              打开市场新局面 <br />
              <span>Open the situation</span>
            </p>
          </div>
          <div class="box3-01-text2">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/图层 532.png" alt="">
            <p>
              成本低，打破价格垄断 <br />
              <span>Break the price monopoly</span>
            </p>
          </div>
          <div class="box3-01-text3">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/图层 531.png" alt="">
            <p>
              抢占高端印花机市场 <br />
              <span>Seize the high-end market</span>
            </p>
          </div>
          <div class="box3-01-text4">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/图层 531.png" alt="">
            <p>
              自主研发，打破技术封锁 <br />
              <span>Independent research and development</span>
            </p>
          </div>
        </div>
        <div class="box3-02">
          <img class="box3-02-img1" src="../../assets/imgs/产品中心/5/新建文件夹/图层 535.png" alt="">
          <img class="box3-02-img2" src="../../assets/imgs/产品中心/5/新建文件夹/图层 536.png" alt="">
          <div class="box3-02-title">
            02<p>印刷工艺</p>
          </div>
          <div class="box3-02-text1">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/形状 11.png" alt="">
            <p>承印织物 品种多</p>
          </div>
          <div class="box3-02-text2">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/形状 11.png" alt="">
            <p>实现烫金与印花两种印刷工艺</p>
          </div>
          <div class="box3-02-text3">
            <img src="../../assets/imgs/产品中心/5/新建文件夹/形状 11.png" alt="">
            <p>立体感更强，视觉冲击力好</p>
          </div>
        </div>
        <div class="box3-03">
          <img src="../../assets/imgs/产品中心/5/新建文件夹/图层 537.png" alt="">
          <div class="box3-03-title">
            03<p>环保节约成本</p>
          </div>
          <div class="box3-03-text1">
            采用水性油墨，符合VOC排放环保要求<br />
            一次出印花成品，完全实现污水0排放<br />
            避免了转移印花的转印纸固废处理<br />
            收放卷双工位机构，实现高速不停机换卷<br />
            电脑自动套色系统，带一键式操作，简洁方便
          </div>
        </div>
        <div class="box3-bottom">
          <!-- 左侧图片 -->
          <img src="../../assets/imgs/产品中心/5/图层 538.png" alt="">
          <!-- 右侧标题盒子 -->
          <div class="box3-bottom-box1">
            Two major problems
            <p>联络我们，排除以下问题干扰</p>
          </div>
          <div class="box3-bottom-box2">
            <p class="box3-bottom-box2-p1">现状一</p>
            平网圆网工艺排放大量污水<br />
            热转移工艺需要处理大量固废<br />
            采用的是油性墨，VOC排放严重超标
            <p class="box3-bottom-box2-p2">环保污染问题严重</p>
          </div>
          <!-- 中间线 -->
          <div class="box3-bottom-box3"></div>
          <div class="box3-bottom-box4">
            <p class="box3-bottom-box4-p1">现状二</p>
            国内数码印花机无污水排放，但效率低，<br />
            无法实现大批量生产；<br />
            国外高速数码印花机，单台套价格高达<br />
            2000万，使国内印染企业望而却步。
            <p class="box3-bottom-box4-p2">无法兼顾成本与效率</p>
          </div>
          <div class="box3-bottom-box5">
            <div class="box3-bottom-box5-left">
              <img src="../../assets/imgs/产品中心/5/矩形 11.png" alt="">
              <p>
                <img class="span4" src="../../assets/imgs/产品中心/5/新建文件夹/报价.png" alt="">
                <span class="span1">在线报价</span>
                <span class="span2"></span>
                <span class="span3">简单填写您的咨询信息</span>
              </p>
            </div>
            <div class="box3-bottom-box5-right">
              <div class="box3-bottom-box5-right1">
                <p class="box3-bottom-box5-right-p1">您的称呼：</p>
                <el-input v-model="call"></el-input>
              </div>
              <div class="box3-bottom-box5-right2">
                <p class="box3-bottom-box5-right-p1">您的电话：</p>
                <el-input v-model="telephone"></el-input>
              </div>
              <div class="btn">
                <el-button type="danger" @click="inquiry">立即询价</el-button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  // 印花机/印花
  name: 'PrintingMachine',
  data () {
    return {
      // 走马灯
      urls: {
        // urls1: [
        //   { id: 1, url: '' },
        //   { id: 2, url: '' },
        //   { id: 3, url: '' }
        // ],
        urls2: [
          { id: 4, url: require('../../assets/imgs/产品中心/5/图层 525.png') },
          { id: 5, url: require('../../assets/imgs/产品中心/5/图层 526.png') },
          { id: 6, url: require('../../assets/imgs/产品中心/5/图层 540.png') }
        ]
        // urls3: [
        //   { id: 7, url: '' },
        //   { id: 8, url: '' },
        //   { id: 9, url: '' }
        // ],
        // urls4: [
        //   { id: 10, url: '' },
        //   { id: 11, url: '' },
        //   { id: 12, url: '' }
        // ],
        // urls5: [
        //   { id: 13, url: '' },
        //   { id: 14, url: '' },
        //   { id: 15, url: '' }
        // ]
      },
      // 称呼
      call: '',
      // 电话
      telephone: ''
    }
  },
  methods: {
    inquiry () {
      console.log(this.call)
      console.log(this.telephone)
    }
  }
}
</script>

<style scoped>
.box1{
  position: relative;
  margin-top: 12.5rem;
  width: 100%;
  height: 61rem;
}

.box1-bottom{
  position: absolute;
  left: 28.4375rem;
  top: 7.6875rem;
  width: 60.8125rem;
  height: 23.6875rem;
}

.box1-title{
  position: absolute;
  top: .625rem;
  left: 26.75rem;
  width: 25rem;
  height: 5rem;
  font-size: 1rem;
  letter-spacing: .0625rem;
  color: #dadada;
}

.box1-title p{
  padding-left: 1.9375rem;
  padding-top: .375rem;
  font-size: 2.5rem;
  color: #333;
}

.box1-title-box{
  position: absolute;
  top: 9.3125rem;
  left: 18.25rem;
  width: 34.5rem;
  height: 23.625rem;
  background-color: #f0f0f0;
}

.box1-title-box1{
  position: absolute;
  width: 3.4375rem;
  height: 3.4375rem;
  border: .125rem dashed #ff3747;
  border-radius: 50%;
}

.box1-title-text1,.box1-title-text2,.box1-title-text3{
  position: absolute;
  top: 3.125rem;
  left: 14.1875rem;
  font-size: 1.625rem;
  color: #555555;
}

.box1-title-img1,.box1-title-img2,.box1-title-img3{
  position: absolute;
  top: 2.4375rem;
  left: 8.125rem;
  width: 3.4375rem;
  height: 3.4375rem;
}

.box1-title-img2{
  position: absolute;
  top: 7.125rem;
}

.box1-title-img3{
  position: absolute;
  top: 11.5rem;
}

.box1-title-img1-text1,.box1-title-img1-text2,.box1-title-img1-text3{
  z-index: 2;
  position: absolute;
  top: 3rem;
  left: 8.875rem;
  font-size: 1.625rem;
  color: #fff;
}

.box1-title-img1-text2{
  position: absolute;
  top: 7.6875rem;
}

.box1-title-img1-text3{
  position: absolute;
  top: 12.0625rem;
}

.box1-title-text2{
  position: absolute;
  top: 7.6875rem;
  left: 14.1875rem;
}

.box1-title-text3{
  position: absolute;
  top: 12.125rem;
  left: 14.1875rem;
}

.box1-title-text4{
  z-index: 3;
  position: absolute;
  top: 18.125rem;
  left: 10.0625rem;
  font-size: 1.375rem;
  color: #fff;
}

.box1-title-text5{
  position: absolute;
  top: 17.0625rem;
  left: 7rem;
  width: 22.3125rem;
  height: 3.75rem;
}

.box1-right-img{
  position: absolute;
  top:0;
  left: 838px;
  width: 47.1875rem;
  height: 27.75rem;
}

.box-bottom-text{
  position: absolute;
  top: 36.25rem;
  left: 18.25rem;
  width: 82.8125rem;
  font-size: 1.625rem;
  color: #6b6b6b;
}

.box2{
  width: 100%;
  height: 40.9375rem;
  background-color: #f3f1ff;
}

.box2-top{
  position: relative;
  width: 100%;
  height: 4.375rem;
}

.box2-top-p1{
  position: absolute;
  top: 2.3125rem;
  left: 56.3125rem;
  font-size: 2rem;
  color: #333;
}

.box2-top-p2{
  position: absolute;
  top: 5.625rem;
  left: 55.9375rem;
  font-size: 1.25rem;
  color: #333;
}

.box2-top-left{
  position: absolute;
  top: 6.4375rem;
  left: 52.3125rem;
  width: 2.8125rem;
  height: .0625rem;
  background-color: #333;
}

.box2-top-right{
  position: absolute;
  top: 6.4375rem;
  right: 47.875rem;
  width: 2.8125rem;
  height: .0625rem;
  background-color: #333;
}

/deep/ .box2 .el-carousel__container{
  margin-top: 5.375rem;
  margin-left: 19.125rem;
  width: 81.125rem;
}

.ridinglantern-box{
  display: inline-block;
  width: 26.125rem;
  height: 23.875rem;
  margin-left: 1.375rem;
}

.ridinglantern-box:first-child{
  margin-left: 0;
}

/deep/ .box2 .el-carousel__indicators--outside button{
  width: .9375rem;
  height: .9375rem;
  border-radius: 50%;
  background-color: #fff;
}

/deep/ .box2 .is-active .el-carousel__button{
  background-color: #ffbb13 !important;
}

.box3{
  position: relative;
  width: 100%;
  height: 125.625rem;
}

.box3-top-p1{
  position: absolute;
  top: 2.9375rem;
  left: 56.5625rem;
  font-size: 2rem;
  color: #333;
}

.box3-top-p2{
  position: absolute;
  top: 5.9375rem;
  left: 57.3125rem;
  width: 6.5rem;
  height: .125rem;
  background-color: #004ea1;

}

.box3-top-p3{
  position: absolute;
  top: 6.1875rem;
  left: 53.875rem;
  font-size: 1.25rem;
  color: #333;
}

.box3-01{
  position: relative;
  top: 11.25rem;
  width: 100%;
  height: 19.6875rem;
}

.box3-left-img1{
  position: absolute;
  top: 2.9375rem;
  left: 18.5625rem;
  width: 25.5625rem;
  height: 15.625rem;
}

.box3-01-title{
  position: absolute;
  top: 0;
  left: 56.375rem;
  font-size: 7.1875rem;
  color: #c81e1e;
}

.box3-01-title p{
  position: absolute;
  top: 4.375rem;
  left: 8.25rem;
  width: 20.625rem;
  font-size: 1.875rem;
  color: #c81e1e;
}

.box3-01-text1 img{
  position: absolute;
  top: 8.125rem;
  left: 56.3125rem;
  width: 5.5rem;
  height: 5.9375rem;
}

.box3-01-text1 p{
  position: absolute;
  top: 8.5rem;
  left: 61.5625rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-01-text1 span{
  font-size: 1.25rem;
  color: #787878;
}

.box3-01-text2 img{
  position: absolute;
  top: 14.8125rem;
  left: 56.3125rem;
  width: 5.5rem;
  height: 5.9375rem;
}

.box3-01-text2 p{
  position: absolute;
  top: 15.25rem;
  left: 61.5625rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-01-text2 span{
  font-size: 1.25rem;
  color: #787878;
}

.box3-01-text3 img{
  position: absolute;
  top: 8.125rem;
  left: 79.375rem;
  width: 5.5rem;
  height: 5.9375rem;
}

.box3-01-text3 p{
  position: absolute;
  top: 8.5rem;
  left: 84.6875rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-01-text3 span{
  font-size: 1.25rem;
  color: #787878;
}

.box3-01-text4 img{
  position: absolute;
  top: 14.8125rem;
  left: 79.375rem;
  width: 5.5rem;
  height: 5.9375rem;
}

.box3-01-text4 p{
  position: absolute;
  top: 15.25rem;
  left: 84.6875rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-01-text4 span{
  font-size: 1.25rem;
  color: #787878;
}

.box3-02{
  position: relative;
  top: 16.875rem;
  width: 100%;
  height: 15.625rem;
}

.box3-02-img1{
  position: absolute;
  top: 1.5625rem;
  right: 46.5625rem;
  width: 16.0625rem;
  height: 12.3125rem;
}

.box3-02-img2{
  position: absolute;
  top: 1.5625rem;
  right: 12.4375rem;
  width: 32.9375rem;
  height: 12.1875rem;
}

.box3-02-title{
  position: absolute;
  top: 0;
  left: 15.25rem;
  font-size: 7.1875rem;
  color: #c81e1e;
}

.box3-02-title p{
  position: absolute;
  top: 4.375rem;
  left: 8.25rem;
  width: 20.625rem;
  font-size: 1.875rem;
  color: #c81e1e;
}

.box3-02-text1 p{
  position: absolute;
  top: 8.75rem;
  left: 23.4375rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-02-text1 img{
  position: absolute;
  top: 9.375rem;
  left: 21.6875rem;
  width: 1rem;
  height: 1.1875rem;
}

.box3-02-text2 p{
  position: absolute;
  top: 11.125rem;
  left: 23.4375rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-02-text2 img{
  position: absolute;
  top: 11.75rem;
  left: 21.6875rem;
  width: 1rem;
  height: 1.1875rem;
}

.box3-02-text3 p{
  position: absolute;
  top: 13.5rem;
  left: 23.4375rem;
  font-size: 1.625rem;
  color: #000;
}

.box3-02-text3 img{
  position: absolute;
  top: 14.1875rem;
  left: 21.6875rem;
  width: 1rem;
  height: 1.1875rem;
}

.box3-03{
  position: relative;
  top: 22.1875rem;
  width: 100%;
  height: 19.6875rem;
  background-color: #fff;
}

.box3-03 img{
  position: absolute;
  top: 2.1875rem;
  left: 15.9375rem;
  width: 32.3125rem;
  height: 16.75rem;
}

.box3-03-title{
  position: absolute;
  top: 0;
  left: 904px;
  font-size: 7.1875rem;
  color: #c81e1e;
}

.box3-03-title p{
  position: absolute;
  top: 4.375rem;
  left: 8.25rem;
  width: 20.625rem;
  font-size: 1.875rem;
  color: #c81e1e;
}

.box3-03-text1{
  position: absolute;
  top: 8.125rem;
  right: 25rem;
  line-height: 2.375rem;
  font-size: 1.5rem;
  color: #000;
}

.box3-bottom{
  position: relative;
  top: 30.1875rem;
  width: 100%;
  height: 33.75rem;
}

.box3-bottom img{
  position: absolute;
  top: .75rem;
  left: 15rem;
  width: 47.1875rem;
  height: 31.0625rem;
}

.box3-bottom-box1{
  position: absolute;
  right: 19.3125rem;
  width: 34.6875rem;
  height: 7.5rem;
  font-size: 2.125rem;
  color: #707070;
  border-bottom: .125rem solid #000;
}

.box3-bottom-box1 p{
  font-size: 1.75rem;
  color: #333;
}

.box3-bottom-box2{
  position: absolute;
  top: 9.375rem;
  right: 38rem;
  font-size: .875rem;
  color: #666;
}

.box3-bottom-box2-p1{
  padding-bottom: 1.375rem;
  font-size: 1.25rem;
  color: #333;
}

.box3-bottom-box2-p2{
  padding-top: 1.375rem;
  font-size: 1.25rem;
  color: #333;
}

.box3-bottom-box3{
  position: absolute;
  top: 9.875rem;
  right: 36.625rem;
  width: .125rem;
  height: 8.625rem;
  border-right: .0625rem dashed #000;
}

.box3-bottom-box4{
  position: absolute;
  top: 9.375rem;
  right: 19.6875rem;
  font-size: .875rem;
  color: #666;
}

.box3-bottom-box4-p1{
  padding-bottom: 1.1875rem;
  font-size: 1.25rem;
  color: #333;
}

.box3-bottom-box4-p2{
  padding-top: .3125rem;
  font-size: 1.25rem;
  color: #333;
}

.box3-bottom-box5{
  display: inline-block;
  position: relative;
  top: 23.625rem;
  left: 962px;
  width: 47.125rem;
  height: 10rem;
}

.box3-bottom-box5-left img{
  position: absolute;
  top: 0;
  left: 0;
  width: 15.4375rem;
  height: 10rem;
}

.box3-bottom-box5-left p {
  position: absolute;
  top: .2813rem;
  left: .2813rem;
  width: 14.8125rem;
  height: 9.4375rem;
  background-color: #d80c18;
}

.span1{
  position: absolute;
  top: 2.375rem;
  left: 6.0625rem;
  font-size: 1.25rem;
  letter-spacing: .1875rem;
  color: #fff;
}

.span2{
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 9.3125rem;
  height: .0625rem;
  background-color: #fff;
}

.span3{
  position: absolute;
  top: 6.4375rem;
  left: 3rem;
  font-size: .875rem;
  letter-spacing: .125rem;
  color: #fff;
}

.span4{
  position: absolute;
  top: 2.375rem !important;
  left: 4rem !important;
  width: 1.5625rem !important;
  height: 1.5rem !important;
}

.box3-bottom-box5-right{
  position: absolute;
  left: 15.4688rem;
  width: 31.6875rem;
  height: 10rem;
  background-color: #000;
}

.box3-bottom-box5-right1{
  position: relative;
  margin-top: 2.3125rem;
  margin-left: 1.8125rem;
  width: 16.625rem;
  height: 2.25rem;
}

/deep/.box3-bottom-box5-right .el-input__inner{
  margin-left: 7.125rem;
  width: 11.25rem;
  height: 2.25rem;
}

.box3-bottom-box5-right-p1{
  position: absolute;
  top: 7px;
  font-size: 1rem;
  color: #fff;
}

.box3-bottom-box5-right2{
  position: relative;
  margin-top: 1.125rem;
  margin-left: 1.8125rem;
  width: 16.625rem;
  height: 2.25rem;
}

/deep/.box3-bottom-box5-right .el-input__inner{
  border-radius: .1875rem;
}

.btn{
  position: absolute;
  top: 3.9375rem;
  left: 21.625rem;
}

/deep/ .btn .el-button--danger{
  font-size: 1rem;
  background-color: #d80c18;
  border: none;
}

</style>
