<template>
  <div>
    <div class="top-box">
    <!-- MSO海宏智能科技智能制造 -->
      <div class="top-box1">
        <!-- 左侧图片 -->
          <img src="../../assets/imgs/产品中心/3/图层 584.png" alt="">
      </div>
        <!-- 右侧文字 -->
      <div class="top-box2">
        <p class="top-box2-text1">MSO海宏智能科技智能制造</p>
        <!-- 小横线 -->
        <p class="top-box2-line"></p>
        <p class="top-box2-text2">
          搭载国际知名品牌机器人本体，如日本FANUC、Kawasaki、瑞士ABB、德国KUKA等，系统运行平稳，噪音小故 障  率  低、零部件更换便捷，可根据客户具体需求和实际情况定制。
        </p>
      <!-- 右侧下方图片 -->
        <img class="box3-img1" src="../../assets/imgs/产品中心/3/日本.png" alt="">
        <span class="box3-text1">日本FANUC</span>
        <img class="box3-img2" src="../../assets/imgs/产品中心/3/ABB.png" alt="">
        <span class="box3-text2">瑞士ABB</span>
        <img class="box3-img3" src="../../assets/imgs/产品中心/3/KUKA.png" alt="">
        <span class="box3-text3">德国KUKA</span>
      </div>
    </div>
    <!-- 机器人外围配套系统 -->
    <div class="peripheral">
      <!-- 标题 -->
      <div class="peripheral-title">
        <p class="peripheral-title-text1">机器人外围配套系统</p>
        <p class="peripheral-title-line"></p>
        <p class="peripheral-title-text2">Peripheral supporting system of robot</p>
      </div>
      <!-- 下方图片 -->
      <div class="peripheral-img-box">
        <!-- 第一个图片盒子 -->
        <div class="peripheral-img-box1">
          <img class="peripheral-box1-img1" src="../../assets/imgs/产品中心/3/配套系统/图层 504.png" alt="">
          <img class="peripheral-box1-img2" src="../../assets/imgs/产品中心/3/配套系统/图层 508.png" alt="">
          <img class="peripheral-box1-img3" src="../../assets/imgs/产品中心/3/配套系统/图层 509.png" alt="">
        </div>
        <div class="peripheral-img-text1">机器人末端执行机构</div>
        <!-- 第二个图片盒子 -->
        <div class="peripheral-img-box2">
          <img class="peripheral-box2-img1" src="../../assets/imgs/产品中心/3/配套系统/图层 512.png" alt="">
          <img class="peripheral-box2-img2" src="../../assets/imgs/产品中心/3/配套系统/图层 511.png" alt="">
          <img class="peripheral-box2-img3" src="../../assets/imgs/产品中心/3/配套系统/图层 513.png" alt="">
        </div>
        <div class="peripheral-img-text2">机器人视觉、力觉等传感器</div>
        <!-- 第三个 -->
        <div class="peripheral-img-box3">
          <img class="peripheral-box3-img1" src="../../assets/imgs/产品中心/3/配套系统/图层 514.png" alt="">
          <img class="peripheral-box3-img2" src="../../assets/imgs/产品中心/3/配套系统/图层 515.png" alt="">
        </div>
        <div class="peripheral-img-text3">机器人末端执行机构</div>
      </div>
    </div>
    <!-- 解决方案标题 -->
      <div class="solution-top">
          <p class="solution-p1">解决方案</p>
          <div class="solution-box"></div>
          <p class="solution-p2">Total Solution</p>
      </div>
      <!-- 解决方案下方图片 -->
      <div class="solution-img">
        <div class="solution-img-left">
          <img src="../../assets/imgs/产品中心/3/智能制造图片/锻造自动化生产线.png" alt="">
          <p>锻造自动化生产线</p>
          <img src="../../assets/imgs/产品中心/3/智能制造图片/压铸自动化产线.png" alt="">
          <p>压铸自动化产线</p>
          <img src="../../assets/imgs/产品中心/3/智能制造图片/上下料自动化产线.png" alt="">
          <p>上下料自动化产线</p>
          <img src="../../assets/imgs/产品中心/3/智能制造图片/非标自动化组装线.png" alt="">
          <p>非标自动化组装线</p>
        </div>
        <div class="solution-img-right">
          <img src="../../assets/imgs/产品中心/3/智能制造图片/冲压自动化生产线.png" alt="">
          <p>冲压自动化生产线</p>
          <img src="../../assets/imgs/产品中心/3/智能制造图片/码垛自动化产线.png" alt="">
          <p>码垛自动化产线</p>
          <img src="../../assets/imgs/产品中心/3/智能制造图片/焊接机器人自动化线.png" alt="">
          <p>上下料自动化产线</p>
          <img src="../../assets/imgs/产品中心/3/智能制造图片/立体仓库.png" alt="">
          <p>立体仓库</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  // 智能制造
  name: 'IntelligentManufacturing',
  data () {
    return {}
  }
}

</script>

<style scoped>
.top-box{
  display: flex;
  margin-top: 4.6875rem;
  width: 100%;
  height: 100%;
}

.top-box1{
  display: flex;
  margin-left: 16.3125rem;
  width: 31.5625rem;
  height: 36.25rem;
}

.top-box2{
  position: relative;
  margin-left: 9.9375rem;
}

.top-box2-text1{
  font-weight: 700;
  font-size: 1.875rem;
  color: #333;
}

.top-box2-line{
  position: absolute;
  top: 4.0625rem;
  width: 3.875rem;
  height: .125rem;
  background-color: #000;
}

.top-box2-text2{
  padding-top: 4.125rem;
  width: 32.4375rem;
  font-size: 1.125rem;
  color: #434343;
}

.box3-img1{
  position: absolute;
  top: 15.5625rem;
  left: -3.125rem;
  width: 10.75rem;
  height: 18.625rem;
}

.box3-img2{
  position: absolute;
  top: 15.6875rem;
  left: 155px;
  width: 11.625rem;
  height: 18.625rem;
}

.box3-img3{
  position: absolute;
  top: 17.125rem;
  left: 22.625rem;
  width: 14.5625rem;
  height: 17.125rem;
}

.box3-text1{
  position: absolute;
  top: 35.75rem;
  left: 0;
  font-size: 1.125rem;
  color: #434343;
}

.box3-text2{
  position: absolute;
  top: 35.75rem;
  left: 10.9375rem;
  font-size: 1.125rem;
  color: #434343;
}

.box3-text3{
  position: absolute;
  top: 35.75rem;
  left: 23.4375rem;
  font-size: 1.125rem;
  color: #434343;
}

.peripheral{
  position: relative;
  margin-top: 6.8125rem;
}

.peripheral-title-text1{
  padding-left: 52.75rem;
  font-size: 1.625rem;
  color: #585858;
}

.peripheral-title-line{
  position: absolute;
  top: 3.125rem;
  left: 57.5rem;
  width: 4.5rem;
  height: .125rem;
  background-color: #004ea1;
}

.peripheral-title-text2{
  padding-top: 1.6875rem;
  padding-left: 50.75rem;
  font-size: 1rem;
  color: #585858;
}

.peripheral-img-box{
  position: relative;
  width: 78.3125rem;
  height: 36.9375rem;
  margin-top: 4.5rem;
  margin-left: 20.5rem;
  background-color: #000;
}

.peripheral-img-box1,.peripheral-img-box2,.peripheral-img-box3{
  position: absolute;
  width: 25rem;
  height: 32.6875rem;
  border: .0625rem solid #000;
  border-bottom: 0;
  background-color: #fff;
}

.peripheral-img-box2{
  position: absolute;
  top: 0;
  left: 26.75rem;
}

.peripheral-img-box3{
  position: absolute;
  top: 0;
  left: 53.5rem;
}

.peripheral-img-text1,.peripheral-img-text2,.peripheral-img-text3{
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25.125rem;
  height: 4.25rem;
  font-size: 1.625rem;
  font-weight: 700;
  color: #fff;
  background-color: #004ea1;
}

.peripheral-img-text2{
  position: absolute;
  bottom: 0;
  left: 26.8125rem;
}

.peripheral-img-text3{
  position: absolute;
  bottom: 0;
  left: 53.5625rem;
}

.peripheral-box1-img1{
  top: 5.125rem;
  left: 1.8125rem;
  position: absolute;
  width: 11.3125rem;
  height: 9.6875rem;
}

.peripheral-box1-img2{
  position: absolute;
  top: 1.75rem;
  right: 2rem;
  width: 8.875rem;
  height: 16.125rem;
}

.peripheral-box1-img3{
  position: absolute;
  bottom: 2.625rem;
  left: 2.25rem;
  width: 20.625rem;
  height: 10.4375rem;
}

.peripheral-box2-img1{
  position: absolute;
  top: 3.8125rem;
  left: 2.5rem;
  width: 21.375rem;
  height: 11.9375rem;
}

.peripheral-box2-img2{
  position: absolute;
  bottom: 3.75rem;
  left: 2rem;
  width: 10.6875rem;
  height: 11.5rem;
}

.peripheral-box2-img3{
  position: absolute;
  bottom: 5.125rem;
  right: 2rem;
  width: 10.5rem;
  height: 9.4375rem;
}

.peripheral-box3-img1{
  position: absolute;
  top: 1.5rem;
  left: 2.1875rem;
  width: 20.25rem;
  height: 14.6875rem;
}

.peripheral-box3-img2{
  position: absolute;
  bottom: 1.5625rem;
  left: 2.1875rem;
  width: 20.3125rem;
  height: 14.125rem;
}

.solution-top{
  position: relative;
  margin-top: 5.5625rem;
  margin-left: 56.75rem;
  width: 7.1875rem;
  height: 4.125rem;
}

.solution-p1{
  position: absolute;
  top: 0;
  font-size: 1.625rem;
  color: #585858;
}

.solution-box{
  position: absolute;
  top: 2.5rem;
  left: 1.0625rem;
  width: 4.5rem;
  height: .125rem;
  background-color: #004ea1;
}

.solution-p2{
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: #585858;
}

.solution-img{
  display: flex;
  width: 83.25rem;
  height: 113.75rem;
  margin-left: 18.125rem;
  padding-bottom: 18.125rem;
}

.solution-img-left{
  margin-right: 5.625rem;
  width: 38.8125rem;
  height: 24.5rem;
}

.solution-img-left img{
  margin-top: 4.3125rem;
  width: 100%;
  height: 20rem;
}

.solution-img-left img:first-child{
    margin-top: 0px;
}

.solution-img-left p{
  margin-top: .625rem;
  margin-left: 9.375rem;
  font-size: 2.25rem;
  color: #004ea1;
}

.solution-img-right{
  width: 38.8125rem;
  height: 24.5rem;
}

.solution-img-right img{
  margin-top: 4.375rem;
  width: 100%;
  height: 20rem;
}

.solution-img-right img:first-child{
  margin-top: .5rem;
}

.solution-img-right p{
  margin-top: .625rem;
  margin-left: 9.375rem;
  font-size: 2.25rem;
  color: #004ea1;
}

.solution-img-right p:last-child{
  margin-left: 15.3125rem;
}
</style>
