<template>
  <div class="box">
    <!-- 招标标题 -->
    <div class="title">
      海宏集团石梁河水库视频监控系统建设项目的公告
    </div>
    <div class="title_box">
      <div class="condition_title">
        招标条件
        <p class="horizontal" />
        <p class="title_text">
          本招标项目石梁河水库视频监控系统建设项目已由 江苏海宏智能科技有限公司 批准建设，建设资金已落实（企业自筹）。招标人为江苏海宏智能科技有限公司。项目已具备招标条件，现对石梁河水库视频监控系统建设项目进行公开招标。
        </p>
      </div>
      <div class="condition_title1">
        项目概况与招标范围
        <p class="horizontal" />
      </div>
      <div class="title_box1">
          <p class="title_text1">
            项目建设地点：连云港市境内石梁河镇、班庄镇及沙河镇。
          </p>
          <p class="title_text1">
            项目总体建设内容：本项目拟建设一套适用于石梁河水库的视频监控系统，满足"无人值守、少人值守"的要求，达到远程监控、画面实时远传浏览的水平，并与已建设完成的“石梁河智慧监管平台"连接。通过设置视频监视设施，使工作人员能够对现场关键部位进行直接的观察了解，极少数的人工巡检作为视频监控的补充，帮助运行人员进行综合判断。在工程前端各建筑物、重要水体范围内配置活动摄像机和固定摄像机，对各点及周围的现场情况进行全方位的监视和管理，使水库过度采沙、偷捕、污水任意排放、重点水工建筑物被破坏等情况能够得到有效控制。
          </p>
          <div class="title_text">
            在石梁河水库环湖55公里范围建设一套视频监控系统，包括前端摄像机建设、网络传输系统建设、监管中心建设、广播系统建设、供电系统建设及防雷接地系统建设，具体工作内容如下：
          </div>
          <p class="table_box">
            <table>
              <tr>
                <th>项目名称</th>
                <th>建设内容</th>
              </tr>
            <tr>
              <td>标准卡口建设</td>
              <td>在沿河主干道路部署标准卡口设备，抓拍过往车辆和车牌信息</td>
            </tr>
            <tr>
              <td>简易卡口建设</td>
              <td>在非主干道路部署简易卡口系统，对途径车辆及车牌抓拍记录</td>
            </tr>
            <tr>
              <td>高空球机建设</td>
              <td>在开阔场景区域部署高空球机监控，通过轮巡和预置位监控方式循环覆盖360°场景</td>
            </tr>
            <tr>
              <td>鹰眼建设</td>
              <td>在有高塔覆盖的区域，选用高空鹰眼全景监控设备，通过全景和球机相结合的方式覆盖大场景</td>
            </tr>
            <tr>
              <td>广播系统建设</td>
              <td>在重点水体附近布置广播系统</td>
            </tr>
            <tr>
              <td>监管中心建设</td>
              <td>在管理处范围内建设一座监管中心、一座监控中心</td>
            </tr>
            <tr>
              <td>网络环境建设</td>
              <td>自建光纤链路、租用运营商光纤链路</td>
            </tr>
            <tr>
              <td>网络环境建设</td>
              <td>自建光纤链路、租用运营商光纤链路</td>
            </tr>
            <tr>
              <td>太阳能供电</td>
              <td>对于市电供电困难或投资过大的监控点采用太阳能供电</td>
            </tr>
            <tr>
              <td>防雷接地系统</td>
              <td>对于高空球机配套防雷接地系统</td>
            </tr>
          </table>
          </p>
          <div class="title_text">
            <p>
              （2）本次招标共一个标段，主要工作内容包括：上述石梁河水库视频监控系统建设项目的建设及相关临时工程以及为完成上述工程所必须的其它工程的施工与保修。具体以技术要求及工程量清单为准。
            </p>
            <p>
              （3）建设周期:自中标通知书发之日后到项目试运行为60日历天。缺陷责任期：24个月。
            </p>
          </div>
      </div>
      <div class="condition_title">
        投标人资格要求
        <p class="horizontal" />
      </div>
      <div class="condition_title_left">
        <div class="condition_title1_left">
          资质要求：
          <div class="title_text">
            <p> &emsp;&emsp;（1）投标人应为中国大陆境内合法注册的独立企业法人，持有有效的营业执照。</p>
            <p> &emsp;&emsp; （2）投标人应具有有效的基本账户开户许可证或基本存款账户信息。</p>
            <p> &emsp;&emsp; （3）投标人应具有电子与智能化专业承包二级及以上资质。</p>
            <p> &emsp;&emsp; （4）投标人应具有省级及以上建设行政主管部门核发的有效的《安全生产许可证》。</p>
          </div>
        </div>
        <div class="condition_title1_left">
          业绩要求：
          <div class="title_text">
            &emsp;&emsp; 2020年1月1日以来（以合同签订日期为准），投标人独立完成过视频监控工程施工项目（单项合同不低于300万元人民币，工程项目工作内容包含视频监控的也予以认可）。（需同时提供：1、中标通知书，2、合同协议书，3、合同完工验收或竣工验收证明资料扫描件（上述三者材料缺一不可））
          </div>
        </div>
        <div class="condition_title1_left">
          财务要求：  /  。
        </div>
        <div class="condition_title1_left">
          项目经理要求：
          <div class="title_text">
            &emsp;&emsp; 具备二级及以上注册建造师资格证书（机电工程专业）。
          </div>
        </div>
        <div class="condition_title1_left">
          技术负责人要求：
          <div class="title_text">
            &emsp;&emsp; 具备中级及以上技术职称（机电类专业或电子信息类专业）。
          </div>
        </div>
        <div class="condition_title1_left">
          三类人员安全生产考核合格证书要求：
          <div class="title_text">
            &emsp;&emsp; 法人代表人(A类)、项目经理（B类）、专职安全员（C类）具备行政主管部门颁发的安全生产考核合格证书；
          </div>
        </div>
        <div class="condition_title1_left">
          施工设备要求：
          <div class="title_text">
            &emsp;&emsp; 满足本项目工程需要。
          </div>
        </div>
        <div class="condition_title1_left">
          其他要求：
          <div class="title_text">
            <p> &emsp;&emsp; ①投标人拟投入本项目的项目经理、技术负责人、专职安全管理人员均必须提供由社保机关出具的2022年10月份以来连续6个月本企业为其缴纳的养老保险证明(由社保部门出具并加盖社保部门印章，后同）</p>
            <p> &emsp;&emsp; ②不拖欠农民工工资书面承诺（格式见招标文件）。</p>
          </div>
        </div>
        <div class="title_text">
          本次招标不接受联合体投标。
        </div>
        <div class="title_text">
          本次招标实行资格后审，资格审查的要求见招标文件。资格后审不合格的投标人投标文件将按否决投标处理。
        </div>
      </div>
      <div class="condition_title">
        招标文件的获取
        <p class="horizontal" />
      </div>
      <div class="condition_title_left">
        <div class="condition_title1_left">
          获取招标文件准备:
          <div class="title_text">
            2023年 8月1日至2023年 8月7日（北京时间，下同）为招标文件发售期，凡有意参加投标者，请在招标文件发售期满之前完成如下工作：
            <div class="title_text">
              <p>1.购买招标文件 <br/></p>
              <p>2.招标文件售价 ：0元 </p>
              <p class="red">注：投标人请在报名期间内下载招标文件，如未在规定时间内下载招标文件由此产生的后果由投标人自行承担。</p>
          </div>
          </div>
        </div>
      </div>
      <div class="condition_title">
          投标文件的递交:
          <p class="horizontal" />
      </div>
      <div class="condition_title_left">
        <div class="condition_title1_left">
          <div class="title_text">
            <p class="p_text">投标文件（电子投标文件）递交的截止时间（投标截止时间，下同）为2023年 8月11日 9 时 00分。</p>
            <p class="p_text">递交地点：海宏集团3楼会议室</p>
            <div class="title_text">
              <p>1、开标当日，投标人抵达开标现场</p>
              <p>2、投标文件递交截止时间后，招标人公布投标人名单并核验投标保证金递交情况</p>
              <p>3、投标人必须在当日开标时间前进行签到，否则无法参与后续开标过程，投标人在签到时，需填写联系人姓名、手机号码（手机号码务必能打通，否则答疑联系不上，产生的所有后果由投标人自行承担）。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="condition_title">
        踏勘现场和投标预备会
        <p class="horizontal" />
      </div>
      <div class="condition_title_left">
        <div class="condition_title1_left">
          招标人不组织踏勘现场和投标预备会，投标人可以自行踏勘现场。
          <div class="title_text">
           <p>
              <a href="javascript:;">评标标准和方法</a>
            </p>
            <p>
              <a href="javascript:;">评标办法前附表</a>
            </p>
          </div>
        </div>
      </div>
      <div class="condition_title">
        其他内容: /
        <p class="horizontal" />
      </div>
      <div class="condition_title">
        发布公告媒介
        <p class="horizontal" />
      </div>
      <div class="title_text1">
        <a href="javascript:;">本公告在连云港市公共资源交易中心网(发布公告的媒介名称)发布</a>
      </div>
      <div class="condition_title">
        联系方式
        <p class="horizontal" />
      </div>
      <div class="title_box1">
          <p class="title_text1">
            招标人：江苏海宏智能科技有限公司
          </p>
          <p class="title_text1">
            地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号
          </p>
          <p class="title_text1">
            联系人：陈工
          </p>
          <p class="title_text1">
            电话：0518-85111399
          </p>
      </div>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
      <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
          <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
            <p class="bottom-box-right-text">
              关于我们 | 网站地图 | 网站声明 <br />
              联系电话：19901541926 (曹经理 微信同号) <br />
              电子邮箱：jingzhaoyin@mail.chinamso.com <br />
              联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号  <br />
            </p>
            <!-- 底部二维码 -->
            <div class="bottom-box-right-text1">
              <img src="../assets/imgs/公众号 拷贝.png" alt="">
              <p>微信公众号</p>
            </div>
            <!-- 底部版权 -->
          <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
        <el-button>
          <img src="../assets/imgs/侧边栏/微信.png" alt="">
          <p>微信</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/形状.png" alt="">
          <p>在线沟通</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/电话.png" alt="">
          <p>联系电话</p>
        </el-button>
        <el-button @click="toTop">
          <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
          <p>返回顶部</p>
        </el-button>
    </div>
  </div>
</template>

<script>
export default {
  // 招标公告
  name: 'invitetenders',
  data() {
    return {
      search: '',
      // tabs标签页默认值
      activeName: '招标公告'
    }
  },
  methods: {
    searchbtn() {
      console.log(this.search)
    },
    toTop () {
      document.documentElement.scrollTop = 0
    },
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    }
  }
}
</script>

<style scoped>
.title{
  display: flex;
  justify-content: center;
  margin-top: 100px;
  font-size: 35px;
  font-weight: 700;
}

.title_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.condition_title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
}

.title_text{
  line-height: 50px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 25px;
  text-indent:2em
}

.title_box1{
  width: 80%;
}

.condition_title1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
}

.title_text1{
  line-height: 50px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 25px;
}

.table_box{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table_box table{
  width: 90%;
  border: 1px solid #333;
  border-collapse: collapse;
}

.table_box table th, .table_box table td {
  padding: 10px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #333;
}

.condition_title_left{
  width: 80%;
}

.condition_title1_left{
  margin-top: 20px;
  font-size: 25px;
  font-weight: 700;
}

.red{
  color: red;
}

.p_text{
  font-weight: 700;
}

.location {
  width: 100%;
  height: 100%;
  margin-top: 3.9375rem;
}

.location-top {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 8.125rem;
  padding-bottom: 3.75rem;
  line-height: 1.5625rem;
  font-size: 1.625rem;
}

.location-top .el-breadcrumb,
/deep/ .location .el-breadcrumb__inner,
/deep/ .el-breadcrumb__separator {
  font-size: 1.625rem;
  color: #000;
}

/deep/ .location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #000 !important;
}

.location-top p {
  width: 7rem;
  margin-right: .625rem;
}

.location-bottom {
  width: 120rem;
  height: 100%;
}

/deep/.location-top .el-breadcrumb__inner.is-link {
  font-weight: 400 !important;
}

/deep/.location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #000;
}

.horizontal{
  margin-top: 5px;
  width: 100px;
  height: 3px;
  background-color: #004ea1;
}
</style>
