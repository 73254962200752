<template>
  <div>
    <img src="@/assets/imgs/publicity.png" title="点击图片下载2023年度江苏省行业领域十大科技进展提名.pdf" @click="downloadPdf" style="cursor: pointer;" alt="">
  </div>
</template>

<script>
export default {
  methods: {
    downloadPdf() {
      const pdfBase64 = '将PDF文件转换后的base64编码内容'
      const link = document.createElement('a')
      link.href = `data:application/pdf;base64,${pdfBase64}`
      link.download = 'publicity.pdf'
      link.click()
    }
  }
}
</script>

<style>
</style>
