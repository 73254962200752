<template>
  <div>
    <!-- AMR智能移动机器人概述 -->
        <div class="robot-left">
          <img src="../../assets/imgs/产品中心/2/图层 569 拷贝.png" alt="">
        </div>
        <div class="robot-right">
          <p class="robot-right-text">AMR智能移动机器人<span>概述</span></p>
          <!-- 小横线 -->
          <p class="robot-right-box"></p>
          <!-- 概述文字 -->
          <p class="robot-right-text1">
          以双差速轮作为移动行走的关键执行机构的一款高精度全向转运平台，适合室外场地的运行。四组差速轮通过运动控制可实现前进、后退、横移、自旋、绕任点    自旋等运动功能。两车具备联动功能，实现叶根和叶尖的同时运载，均为潜入背负式结构，托架上可放置工装，采用插销的方式更换不同的工装，适用于种规格    的叶片转运。<br />
          整车采用模块化设计，传动系统、电机控制系统、电控系统均封闭于独立箱体，便于维护及更换零部件。车身安装声光报警器、探测避障系统及控制面板显屏，    运行状态、电池状态、故障信息等实时显示，为设备的安全运行提供有效保障。
          </p>
          <!-- 文字下方图片 -->
          <p class="robot-right-img1">
            <img src="../../assets/imgs/产品中心/2/图层 570.png" alt="">
          </p>
          <p class="robot-right-img2">
            <img src="../../assets/imgs/产品中心/2/图层 566 拷贝.png" alt="">
          </p>
        </div>
      <!-- 海宏常见小车种类标题 -->
      <div class="robot-center">
        <span class="robot-center-text1">海宏常见小车种类</span>
        <p class="robot-center-box"></p>
        <span class="robot-center-text2">全向轮&负重驱动装置&多车联动</span>
      </div>
      <!-- 标题下方背景图大盒子 -->
      <div class="robot-center-img">
        <!-- 智能叉车标题 -->
        <div class="forklift-title">智能叉车</div>
        <div class="forklift-img1">
          <img src="../../assets/imgs/产品中心/2/图层 566 拷贝.png" alt="">
        </div>
        <div class="forklift-img2">
          <img src="../../assets/imgs/产品中心/2/图层 569 拷贝.png" alt="">
        </div>
        <div class="forklift-text1">非接触式激光避障传感器及车身有接触式防撞机构</div>
        <!-- 下箭头 -->
        <div class="downa-rrow"></div>
        <div class="downa-rrow1"></div>
        <!-- 下箭头下方文字 -->
        <div class="downa-text2">
          • 麦克纳姆轮全方位任意全向移动 <br />
          • 除需日常充电外，无其他维护成本<br />
          &ensp;并设有系统故障诊断及电量显示功能<br />
          • 控制面板显示屏实时状态显示，并显示故障信息
        </div>
        <!-- 双车联动标题 -->
        <div class="linkage-title">双车联动</div>
        <div class="linkage-img">
            <img src="../../assets/imgs/产品中心/2/图层 568.png" alt="">
        </div>
        <!-- 三箭头 -->
        <div class="triple-arrow1"></div>
        <div class="triple-arrow2"></div>
        <div class="triple-arrow3"></div>
        <!-- 箭头上方文字 -->
        <div class="linkage-text">
            • 可增加输送机构实现不同高度物料对接，实现输送柔性化<br />
            • 可安装带升降功能的剪叉升降机，实现<br />升降机构的升降<br />
            • 根据工况需求搭载不同的导航方式<br />
        </div>
        <!-- 背负式双车联动-->
        <div class="backpack-title">背负式双车联动</div>
        <div class="backpack-img1">
            <img src="../../assets/imgs/产品中心/2/图层 564.png" alt="">
        </div>
        <div class="backpack-img2">
            <img src="../../assets/imgs/产品中心/2/图层 567.png" alt="">
        </div>
        <!-- 下箭头 -->
        <div class="backpack-rrow"></div>
        <div class="backpack-rrow1"></div>
        <!-- 箭头下方文字 -->
        <div class="backpack-text">
            背负式双车联动叶片搬运设备是以双差速轮作为移动行走的关键执行机构的一款高精度全向转运平台。单车额定载荷可达30吨，两车具备联动功能，两车联动可实现同步前进、同步后退、同步转向、左右平移、90°直角转弯及360°原地旋转功能。
        </div>
      </div>
      <!-- 解决方案标题 -->
      <div class="solution-top">
          <p class="solution-p1">解决方案</p>
          <div class="solution-box"></div>
          <p class="solution-p2">Total Solution</p>
      </div>
      <!-- 解决方案下方盒子 -->
      <div class="solution-bottom">
        <!-- 物流运输 -->
        <div class="solution-bottom-img1">
          <p class="solution-bottom-text1">物流运输</p>
          <p class="solution-bottom-text2">Logistics transportation</p>
          <img src="../../assets/imgs/产品中心/2/图片/物流运输.jpeg" alt="">
        </div>
        <!-- 智慧仓储 -->
        <div class="solution-bottom-img2">
          <p class="solution-bottom-text1">智慧仓储</p>
          <p class="solution-bottom-text2">Smart storage</p>
          <img src="../../assets/imgs/产品中心/2/图片/智慧仓储.jpeg" alt="">
        </div>
        <!-- 电力电网 -->
        <div class="solution-bottom-img1">
          <p class="solution-bottom-text1">电力电网</p>
          <p class="solution-bottom-text2">Electric power grid</p>
          <img src="../../assets/imgs/产品中心/2/图片/电力电网.jpeg" alt="">
        </div>
        <!-- 精密制造 -->
        <div class="solution-bottom-img2">
          <p class="solution-bottom-text1">精密制造</p>
          <p class="solution-bottom-text2">Precision manufacturing</p>
          <img src="../../assets/imgs/产品中心/2/图片/精密制造.png" alt="">
        </div>
      </div>
  </div>
</template>

<script>
export default {
  // 智能机器人
  name: 'IntelligentRobot'
}
</script>

<style scoped>
.robot-left{
  position: absolute;
  top: 10.5rem;
  left: 18.5rem;
  display: inline-block;
  width: 40.3125rem;
  height: 22.8125rem;
}

.robot-right{
  position: relative;
  left: 63.5rem;
  top: 4.6875rem;
  display: inline-block;
  width: 33.125rem;
  height: 38.125rem;
}

.robot-right-text{
  position: absolute;
  font-size: 1.875rem;
  color: #585858;
}

.robot-right-text span{
  color: #333;
}

.robot-right-box{
  position: absolute;
  top: 3.3125rem;
  width: 3.875rem;
  height: .125rem;
  background-color: #333;
}

.robot-right-text1{
  position: absolute;
  top: 5.625rem;
  width: 32.625rem;
  height: 17.9375rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #646464;
}

.robot-right-img1{
  position: absolute;
  top: 26.5625rem;
  width: 20.3125rem;
  height: 8.125rem;
}

.robot-right-img2{
  position: absolute;
  top: 23.25rem;
  left: 23.4375rem;
  width: 8.5625rem;
  height: 14.625rem;
}

.robot-center{
  position: relative;
  margin-left: 51.875rem;
  margin-top: 15.3125rem;
  width: 16.875rem;
  height: 4.75rem;
}

.robot-center-text1{
  position: absolute;
  top: 0;
  left: 1.3125rem;
  font-size: 1.625rem;
  color: #000;
}

.robot-center-box{
  position: absolute;
  top: 2.5rem;
  left: 6.125rem;
  width: 4.1875rem;
  height: .1875rem;
  background-color: #004ea1;
}

.robot-center-text2{
  top: 3.6875rem;
  position: absolute;
  font-size: 1.125rem;
  color: #666;
}

.robot-center-img{
  position: relative;
  margin-top: 3.125rem;
  width: 100%;
  height: 50rem;
  background-image: url('../../assets/imgs/产品中心/2/组 4.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.forklift-title{
  position: absolute;
  top: 4.125rem;
  left: 19.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.25rem;
  height: 3.75rem;
  font-size: 1.625rem;
  color: #e60012;
  background-color: #fff;
}

.forklift-img1{
  position: absolute;
  top: 9.5625rem;
  left: 9.6875rem;
  width: 10.125rem;
  height: 17.4375rem;
}

.forklift-img2{
  position: absolute;
  top: 14.1875rem;
  left: 21.1875rem;
  width: 19.3125rem;
  height: 10.875rem;
}

.forklift-text1{
  position: absolute;
  top: 30rem;
  left: 11.3125rem;
  font-size: 1.1219rem;
  color: #fff;
}

.downa-rrow, .downa-rrow1{
  position: absolute;
  top: 33.75rem;
  left: 21.25rem;
  width: .75rem;
  height: .75rem;
  border: .0625rem solid #fff;
  border-width: 0 0 .0625rem .0625rem;
  transform: rotate(-45deg)
}

.downa-rrow1{
  position: absolute;
  top: 34.25rem;
  left: 21.25rem;
}

.downa-text2{
  position: absolute;
  top: 37.5rem;
  left: 10.6875rem;
  line-height: 2rem;
  font-size: 1.125rem;
  color: #fff;
}

.linkage-title{
  position: absolute;
  bottom: 5.5rem;
  left: 53.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.25rem;
  height: 3.75rem;
  font-size: 1.625rem;
  color: #e60012;
  background-color: #fff;
}

.linkage-img{
  position: absolute;
  top: 25rem;
  left: 48rem;
  width: 21.6875rem;
  height: 11.25rem;
}

.triple-arrow1{
  position: absolute;
  top: 20.75rem;
  left: 55rem;
  width: .75rem;
  height: .75rem;
  border: .0625rem solid #fff;
  border-width: .0625rem 0 0 .0625rem;
  transform: rotate(5deg)
}

.triple-arrow2{
  position: absolute;
  top: 20.1875rem;
  left: 57.3125rem;
  width: .75rem;
  height: .75rem;
  border: .0625rem solid #fff;
  border-width: .0625rem 0 0 .0625rem;
  transform: rotate(45deg)
}

.triple-arrow3{
  position: absolute;
  top: 20.75rem;
  left: 59.375rem;
  width: .75rem;
  height: .75rem;
  border: .0625rem solid #fff;
  border-width: .0625rem .0625rem 0 0;
  transform: rotate(-5deg)
}

.linkage-text{
  position: absolute;
  top: 5.625rem;
  left: 48.375rem;
  line-height: 2rem;
  font-size: 1.125rem;
  color: #fff;
}

.backpack-title{
  position: absolute;
  top: 4rem;
  right: 20.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 3.75rem;
  font-size: 1.625rem;
  color: #e60012;
  background-color: #fff;
}

.backpack-img1{
  position: absolute;
  top: 15rem;
  right: 12.3125rem;
  width: 31rem;
  height: 9.3125rem;
}

.backpack-img2{
  position: absolute;
  top: 21.875rem;
  right: 7.25rem;
  width: 11.5625rem;
  height: 12.125rem;
}

.backpack-rrow, .backpack-rrow1{
  position: absolute;
  top: 28.625rem;
  right: 27.0625rem;
  width: .75rem;
  height: .75rem;
  border: .0625rem solid #fff;
  border-width: 0 0 .0625rem .0625rem;
  transform: rotate(-45deg)
}

.backpack-rrow1{
  position: absolute;
  top: 29.1875rem;
}

.backpack-text{
  position: absolute;
  right: 14.375rem;
  bottom: 4.3125rem;
  width: 26.125rem;
  height: 9.6875rem;
  font-size: 1.125rem;
  color: #fff;
}

.solution-top{
  position: relative;
  margin-top: 4.4375rem;
  margin-left: 56.75rem;
  width: 7.1875rem;
  height: 4.125rem;
}

.solution-p1{
  position: absolute;
  top: 0;
  font-size: 1.625rem;
  color: #585858;
}

.solution-box{
  position: absolute;
  top: 2.5rem;
  left: 1.0625rem;
  width: 4.5rem;
  height: .125rem;
  background-color: #004ea1;
}

.solution-p2{
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: #585858;
}

.solution-bottom{
  margin-top: 3.9375rem;
  margin-left: 19.3125rem;
  padding-bottom: 5.5rem;
  width: 81.3125rem;
  height: 28.25rem;
}

.solution-bottom-text1{
  position: absolute;
  top: 2.25rem;
  left: 1.5rem;
  font-size: 1.875rem;
  color: #040404;
}

.solution-bottom-text2{
  position: absolute;
  top: 4.6875rem;
  left: 1.5rem;
  font-size: 1.125rem;
  color: #040404;
}

.solution-bottom-img1{
  display: inline-block;
  margin-left: .9375rem;
  position: relative;
  width: 19.6875rem;
  height: 100%;
  background-color: #ebf3f6;
}

.solution-bottom-img1:first-child{
  margin-left: 0 !important;
}

.solution-bottom-img1 img{
  padding-top: 8.1875rem;
  width: 100%;
  height: 20.0625rem;
}

.solution-bottom-img2{
  display: inline-block;
  margin-left: .8125rem;
  position: relative;
  width: 19.6875rem;
  height: 100%;
  background-color: #e5e5e5
}

.solution-bottom-img2 img{
  padding-top: 8.1875rem;
  width: 100%;
  height: 20.0625rem;
}

</style>
