<template>
  <div class="summary-box">
    <div class="summary-box-left">
      <img src="../../assets/imgs/产品中心/1/控制器/正面透明底倒影.png" alt="">
    </div>
    <div class="summary-box-right">
      <p class="box-right-text1">MSO-MC5运动控制器概述</p>
      <div class="box-right-text1-box"></div>
      <p class="box-right-text2">
        MSO-MC5是通过EtherCAT实时自动化总线运行远程伺服和步进驱动的高功能运动控制器。系统以最新ARM11为控制核心，支持最大32轴运动控制，64位超高精度位置分辨率，EtherCAT从站伺服和I/O可以相互连接并在位置、速度和力矩模式中循环同步运行。MSO-MC5的编程方式与传统的模拟量轴完全相同，只是添加了基于EtherCAT总线的驱动设置以及过程报警。
      </p>
      <div class="box-bottom-img">
        <img class="bottom-img1" src="../../assets/imgs/产品中心/1/控制器/DTU透明倒影.png" alt="">
        <img class="bottom-img2" src="../../assets/imgs/产品中心/1/控制器/透明底加亮.png" alt="">
        <img class="bottom-img3" src="../../assets/imgs/产品中心/1/控制器/图层 590 拷贝.png" alt="">
      </div>
    </div>
    <!-- 概述下方背景图盒子 -->
    <div class="background">
      <p>
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; MSO系列遥测终端采用32位高性能ARMCortex-M4处理器<br />
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; 支持UCSII、FreeRTOSRT-Thread 等多种嵌入式操作系统操作系统<br />
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;支持远程访问查看并设置参数<br />
        &ensp;&ensp;&ensp;&ensp;&ensp; 硬件方面终端机全部采用工业级部件，内嵌工业级4G模块(全网通)向下兼容GPRS<br />
        集数据采集、无线通讯、移动侦测、加密通讯、大容量存储和远程管理为一体的安全终端设备<br />
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; 广泛应用于水利、气象、环保、电力、钢铁冶金、石油化工、车载等行业
      </p>
    </div>
    <!-- MSO系列遥测终端 -->
    <div class="MSO">
      <p class="MSO-text1">MSO系列遥测终端</p>
      <p class="MSO-box"></p>
      <p class="MSO-text2">高性能处理器&工业级部件</p>
    </div>
    <div class="MSO-img-box">
      <div>
        <img src="../../assets/imgs/产品中心/1/控制器/DTU透明倒影灰底.png" alt="">
        <p>
          MSO智能遥测终端
          <span class="img-bottom-box" />
        </p>
      </div>
      <div>
        <img src="../../assets/imgs/产品中心/1/控制器/灰底.png" alt="">
        <p>
          MSO遥测监控终端
          <span class="img-bottom-box" />
        </p>
      </div>
      <div>
        <img src="../../assets/imgs/产品中心/1/控制器/DTU透明倒影灰底.png" alt="">
        <p>
          MSO-DTU遥测终端
          <span class="img-bottom-box" />
        </p>
      </div>
      <div>
        <img src="../../assets/imgs/产品中心/1/控制器/图层 590 灰底.png" alt="">
        <p>
          MSO-MC3控制器
          <span class="img-bottom-box" />
        </p>
      </div>
    </div>
    <!-- 硬件概要盒子 -->
    <div class="outline-box">
      <el-button class="outline-but1"  @click="btn1">
        <img src="../../assets/imgs/产品中心/1/硬件信息.png" alt="">
        <p>硬件概要</p>
      </el-button>
      <el-button class="outline-but2" @click="btn2">
        <img src="../../assets/imgs/产品中心/1/i_b申请软件ID.png" alt="">
        <p>软件功能</p>
      </el-button>
      <el-button class="outline-but3" @click="btn3">
        <img src="../../assets/imgs/产品中心/1/参数.png" alt="">
        <p>技术参数</p>
      </el-button>
      <el-button class="outline-but4" @click="btn4">
        <img src="../../assets/imgs/产品中心/1/33资源-线性.png" alt="">
        <p>资源及特点</p>
      </el-button>
      <el-button class="outline-but5" @click="btn5">
        <img src="../../assets/imgs/产品中心/1/通信.png" alt="">
        <p>通信协议</p>
      </el-button>
    </div>
    <!-- 硬件概要盒子下方背景图 -->
    <div class="outline-box-img">
      <div class="btn1-box" v-if="box1">
        <p class="p1">硬件概要</p>
        <p class="p2">MSO智能遥测终端采用RS485或模拟量接收传感器采集数据，算法分析处理后再以规定协议发送至数据层。实现信息管理平台总体架构搭建，便于各子系统对接。是实现水雨情实时监测、消防安全报警管理以及其他电力设备智能化管理的基础。与GIS系统库区电子地图结合，库区所有监测站点和信息展示一目了然。</p>
        <!-- 底部小横线 -->
        <p class="p3"></p>
        <div class="btn1-box-img"></div>
      </div>
      <div class="btn2-box" v-if="box2">
        <p class="p1">软件功能</p>
        <p class="p2">
          ◆支持TCPClient/TCP Server/UDP Cli-ent/,UDP Serveri透传方式；<br />
          ◆域名DNS解析；<br />
          ◆HTTP协议；<br />
          ◆MQTT协议；<br />
          ◆符合《水资源监测数据传输规szy206-2016》
        </p>
        <!-- 底部小横线 -->
        <p class="p3"></p>
        <div class="btn1-box-img"></div>
      </div>
      <div class="btn3-box" v-if="box3">
        <p class="p1">技术参数</p>
        <p class="p2"></p>
        <!-- 底部小横线 -->
        <p class="p3"></p>
        <div class="btn1-box-img"></div>
      </div>
      <div class="btn4-box" v-if="box4">
        <p class="p1">资源及特点</p>
        <p class="p2"></p>
        <!-- 底部小横线 -->
        <p class="p3"></p>
        <div class="btn1-box-img"></div>
      </div>
      <div class="btn5-box" v-if="box5">
        <p class="p1">通信协议</p>
        <p class="p2"></p>
        <!-- 底部小横线 -->
        <p class="p3"></p>
        <div class="btn1-box-img"></div>
      </div>
    </div>
    <!-- 广泛应用盒子 -->
    <div class="extensive">
      <!-- 标题 -->
      <div class="extensive-top">
        <p class="extensive-p1">广泛应用</p>
        <div class="extensive-box"></div>
        <p class="extensive-p2">Extensive use</p>
      </div>
    <!-- 广泛应用图片大盒子 -->
      <div class="extensive-img">
    <!-- 广泛应用图片盒子 -->
        <div class="extensive-img1">
          <p class="extensive-img1-text1">码垛搬运</p>
          <p class="extensive-img1-text2">Pallet handling</p>
        </div>
        <div class="extensive-img2">
          <p class="extensive-img1-text1">焊接锻造</p>
          <p class="extensive-img1-text2">Welded forging</p>
        </div>
        <div class="extensive-img3">
          <p class="extensive-img1-text1">移动叉车</p>
          <p class="extensive-img1-text2">Mobile forklift</p>
        </div>
        <div class="extensive-img4">
          <p class="extensive-img1-text1">智能加工</p>
          <p class="extensive-img1-text2">Intelligent processing</p>
        </div>
      </div>
      <!-- 底部文字 -->
      <div class="extensive-bottom">
        MSO运动控制器可广泛应用于管件加工、码垛搬运、粮食仓储设备、油库运输及储存行业、印花设备、全向移动智能小车、物联网等多种行业。
      </div>
    </div>
    <!-- 解决方案盒子 -->
    <div class="solution">
      <!-- 标题盒子 -->
      <div class="solution-top">
        <p class="solution-p1">解决方案</p>
        <div class="solution-box"></div>
        <p class="solution-p2">Total Solution</p>
      </div>
      <!-- 智能表计解决方案 -->
      <div class="intelligent-meter">
        <!-- 图片盒子在左侧 -->
        <div class="intelligent-meter-left">
          <div class="solution-text1">智能表计解决方案</div>
          <!-- 文字盒子在右侧 -->
          <div class="solution-text2">
            智能表计可用于水、电、气、热等计量表。NB-IoT远程抄表在继承了GPRS远程抄表功能的同时还拥有海量容量，相同基站通讯用户容量是GPRS远程抄表的10倍。在相同的使用环境条件下NB-IoT模块的待机时间可长达10年以上，新技术信号覆盖更强，可覆盖到室内与地下室。
          </div>
        </div>
        <div class="intelligent-meter-right">
          <img src="../../assets/imgs/产品中心/1/新建文件夹/图层 589.png" alt="">
        </div>
      </div>
      <!-- 共享支付解决方案 -->
      <div class="intelligent-meter1">
        <!-- 图片盒子在左侧 -->
        <div class="intelligent-meter-left1">
          <img src="../../assets/imgs/产品中心/1/新建文件夹/图层 587.png" alt="">
        </div>
        <!-- 文字盒子在右侧 -->
        <div class="intelligent-meter-right1">
          <div class="solution-text1">共享支付解决方案</div>
          <div class="solution-text2">
            MSO智能测控终端的广覆盖力可轻松查找停留在任何环境下的共享单车，包括地下室或地下车库中停放的单车。并且可实现每小区5万用户的容量。电池运行长达10年以上，且运营商的成本较低。
          </div>
        </div>
      </div>
      <!-- 烟感传输解决方案 -->
      <div class="intelligent-meter">
        <!-- 图片盒子在左侧 -->
        <div class="intelligent-meter-left">
          <div class="solution-text1">烟感传输解决方案</div>
          <!-- 文字盒子在右侧 -->
          <div class="solution-text2">
            MSO智能测控终端的低功耗超长待机工作，全天候提供数据传输，NB-IoT拥有海量的连接数，同时接入的烟感器可高达到5万个以上，可满足海量的烟感器同时接入。并且NB-IoT适合更多安装场景，可覆盖至室内和地下室。无须担心信号覆盖问题，也不需要专业人员维护安装。
          </div>
        </div>
        <div class="intelligent-meter-right">
          <img src="../../assets/imgs/产品中心/1/新建文件夹/图层 588.png" alt="">
        </div>
      </div>
      <!-- 智慧农业解决方案 -->
      <div class="intelligent-meter1">
        <!-- 图片盒子在左侧 -->
        <div class="intelligent-meter-left1">
          <img src="../../assets/imgs/产品中心/1/新建文件夹/图层 584.png" alt="">
        </div>
        <!-- 文字盒子在右侧 -->
        <div class="intelligent-meter-right1">
          <div class="solution-text1">智慧农业解决方案</div>
          <div class="solution-text2">
            MSO智能测控终端可实现农业灌溉、病虫、施肥等状态的数据传输，全面提升农场生产管理水平，减少传统的生产成本，及时有效的了解农作物生长状态，提高经济效益。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 多轴运动控制器
  name: 'motionController',
  data () {
    return {
      box1: true,
      box2: false,
      box3: false,
      box4: false,
      box5: false
    }
  },
  methods: {
    btn1 () {
      this.box1 = !this.box1
      this.box2 = false
      this.box3 = false
      this.box4 = false
      this.box5 = false
    },
    btn2 () {
      this.box2 = !this.box2
      this.box1 = false
      this.box3 = false
      this.box4 = false
      this.box5 = false
    },
    btn3 () {
      this.box3 = !this.box3
      this.box1 = false
      this.box2 = false
      this.box4 = false
      this.box5 = false
    },
    btn4 () {
      this.box4 = !this.box4
      this.box1 = false
      this.box2 = false
      this.box3 = false
      this.box5 = false
    },
    btn5 () {
      this.box5 = !this.box5
      this.box1 = false
      this.box2 = false
      this.box3 = false
      this.box4 = false
    }
  }
}
</script>

<style scoped>
.summary-box{
  margin-top: 5rem;
  width: 120rem;
  height: 100%;
}

.summary-box-left{
  display: inline-block;
  padding-left: 18.75rem;
  width: 41.875rem;
  height: 31.5625rem;
}

.summary-box-right{
  margin-left: 9.375rem;
  display: inline-block;
  width: 33.125rem;
  height: 16.875rem;
}

.box-right-text1{
  font-size: 1.875rem;
}

.box-right-text1-box{
  margin-top: 1.5625rem;
  width: 3.875rem;
  height: .125rem;
  background-color: #000;
}

.box-right-text2{
  margin-top: 1.875rem;
  width: 33.125rem;
  height: 11.0625rem;
  font-size: 1.125rem;
}

.box-bottom-img{
  display: flex;
  width: 37.5rem;
  height: 13.75rem;
}

.bottom-img1{
  padding-top: .625rem;
  width: 10.9375rem;
  height: 100%;
}

.bottom-img2{
  padding-top: .9375rem;
  width: 10rem;
  height: 10rem;
}

.bottom-img3{
  padding-top: 3.75rem;
  width: 6.25rem;
  height: 5.625rem;
}

.background{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  line-height: 1.875rem;
  color: #fff;
  width: 100%;
  height: 13.75rem;
  background-image: url('../../assets/imgs/产品中心/1/组 5.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.MSO{
  position: relative;
  left: 53.125rem;
  width: 13.75rem;
  height: 11.875rem;
}

.MSO-text1{
  position: absolute;
  top: 3.75rem;
  font-size: 1.625rem;
}

.MSO-text2{
  position: absolute;
  top: 7.5rem;
  left: .1875rem;
  font-size: 1.125rem;
}

.MSO-box{
  position: absolute;
  top: 6.5625rem;
  left: 5rem;
  width: 4.25rem;
  height: .1875rem;
  background-color: #004ea1;
}

.MSO-img-box{
  margin-left: 18.125rem;
  width: 83.75rem;
  height: 25rem;
}

.MSO-img-box div{
  display: inline-block;
  margin-left: 1.25rem;
  width: 20rem;
  height: 23.125rem;
}

.MSO-img-box div:first-child{
  margin-left: 0;
}

.MSO-img-box div p:hover{
  background-color: #ff8400;
  color: #fff;
}

.MSO-img-box div p:hover span{
  background-color: #fff;
}

.MSO-img-box p{
  position: relative;
  top: -0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.375rem;
  width: 20rem;
  height: 5.625rem;
  color: #000;
  box-shadow: .3125rem .3125rem .1875rem rgba(0, 0, 0, .1);
}

.MSO-img-box img{
  width: 20rem;
  height: 17.5rem;
}

.img-bottom-box{
  position: absolute;
  top: 4.375rem;
  width: 2.5rem;
  height: .0625rem;
  background-color: #ff8400;
}

.outline-box{
  margin-top: 2.5rem;
  margin-left: 22.8125rem;
  width: 74.375rem;
  height: 7.5rem;
}

.outline-box img{
  width: 3.125rem;
  height: 3.125rem;
}

.outline-box p{
  margin-top: .9375rem;
  font-size: 1rem;
  color: #fff;
}

.outline-box button{
  margin-left: 0;
  border: none;
}

.outline-but1{
  width: 14.875rem;
  height: 7.5rem;
  border-radius: 0px;
  background-color: #004471;
}

.outline-but1:hover, .outline-but1:focus{
  background-color: #004471;
}

.outline-but2{
  width: 14.875rem;
  height: 7.5rem;
  border-radius: 0px;
  background-color: #065889;
}

.outline-but2:hover, .outline-but2:focus{
  background-color: #065889;
}

.outline-but3{
  width: 14.875rem;
  height: 7.5rem;
  border-radius: 0px;
  background-color: #0d6ea4;
}

.outline-but3:hover, .outline-but3:focus{
  background-color: #0d6ea4;
}

.outline-but4{
  width: 14.875rem;
  height: 7.5rem;
  border-radius: 0px;
  background-color: #1381bb;
}

.outline-but4:hover, .outline-but4:focus{
  background-color: #1381bb;
}

.outline-but5{
  width: 14.875rem;
  height: 7.5rem;
  border-radius: 0px;
  background-color: #1996d5;
}

.outline-but5:hover, .outline-but5:focus{
  background-color: #1996d5;
}

.outline-box-img{
  position: relative;
  width: 100%;
  height: 30.625rem;
  background-image: url('../../assets/imgs/产品中心/1/组 6.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.btn1-box{
  position: absolute;
  left: 22.8125rem;
  width: 29.6875rem;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
}

.btn2-box{
  position: absolute;
  left: 37.6875rem;
  width: 29.6875rem;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
}

.btn3-box{
  position: absolute;
  left: 52.625rem;
  width: 29.6875rem;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
}

.btn4-box{
  position: absolute;
  left: 67.5rem;
  width: 29.6875rem;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
}

.btn5-box{
  position: absolute;
  left: 82.3125rem;
  width: 29.6875rem;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
}

.p1{
  position: absolute;
  top: 4.6875rem;
  left: 5.8125rem;
  font-weight: 700;
  font-size: 2.25rem;
  color: #004ea1;
}

.p2{
  z-index: 1;
  position: absolute;
  top: 8.625rem;
  left: 5.9375rem;
  width: 18rem;
  height: 14.875rem;
  line-height: 2rem;
  font-size: 1rem;
}

.p3{
  position: absolute;
  left: 5.9375rem;
  bottom: 3.4375rem;
  width: 6.25rem;
  height: .375rem;
  background-color: #0167ae;
}

.btn1-box-img{
  position: absolute;
  right: .125rem;
  bottom: .625rem;
  width: 12.1875rem;
  height: 11.5625rem;
  background-image: url('../../assets/imgs/产品中心/1/硬件信息 拷贝.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.extensive{
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 19.375rem;
  width: 81.3125rem;
  height: 100%;
}

.extensive-top{
  position: absolute;
  margin-top: 5rem;
  width: 7.1875rem;
  height: 4.125rem;
}

.extensive-p1{
  position: absolute;
  top: 0;
  font-size: 1.625rem;
  color: #585858;
}

.extensive-box{
  position: absolute;
  top: 2.375rem;
  left: 1.125rem;
  width: 4.5rem;
  height: .125rem;
  background-color: #004ea1;
}

.extensive-p2{
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: #585858;
}

.extensive-img{
  margin-top: 13.125rem;
  padding-bottom: 2.0625rem;
  width: 100%;
  height: 28.125rem;
}

.extensive-img1{
  display: inline-block;
  width: 19.6875rem;
  height: 100%;
  background: url('../../assets/imgs/产品中心/1/新建文件夹/图层 575.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.extensive-img2{
  margin-left: .8125rem;
  display: inline-block;
  width: 19.6875rem;
  height: 100%;
  background-image: url('../../assets/imgs/产品中心/1/新建文件夹/图层 577.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.extensive-img3{
  margin-left: .9375rem;
  display: inline-block;
  width: 19.6875rem;
  height: 100%;
  background-image: url('../../assets/imgs/产品中心/1/新建文件夹/微信图片_20220721165237.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.extensive-img4{
  margin-left: .8125rem;
  display: inline-block;
  width: 19.6875rem;
  height: 100%;
  background-image: url('../../assets/imgs/产品中心/1/新建文件夹/图层 576.png');
  background-size:cover;
  background-repeat: no-repeat;
}

.extensive-img1-text1{
  padding-top: 1.875rem;
  padding-left: 1.4375rem;
  font-size: 1.875rem;
}

.extensive-img1-text2{
  padding-left: 1.4375rem;
  font-size: 1.125rem;
}

.extensive-bottom{
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.25rem;
  font-size: 1.25rem;
  color: #e60012;
}

.solution{
  margin-top: 5.625rem;
  margin-left: 18.5625rem;
  padding-bottom: 10.9375rem;
  width: 83.125rem;
  height: 100%;
}

.solution-top{
  position: relative;
  margin-left: 38.125rem;
  width: 7.1875rem;
  height: 4.125rem;
}

.solution-p1{
  position: absolute;
  top: 0;
  font-size: 1.625rem;
}

.solution-box{
  position: absolute;
  top: 2.5rem;
  left: 1.0625rem;
  width: 4.5rem;
  height: .125rem;
  background-color: #004ea1;
}

.solution-p2{
  position: absolute;
  bottom: 0;
  font-size: 1rem;
}

.intelligent-meter{
  display: flex;
  margin-top: 6.875rem;
  width: 100%;
  height: 100%;
}

.intelligent-meter-left{
  display: inline-block;
  width: 39.6875rem;
  height: 10.625rem;
}

.solution-text1{
  font-size: 2.125rem;
  font-weight: 700;
}

.solution-text2{
  padding-top: .625rem;
  font-size: 1.125rem;
}

.intelligent-meter-right{
  display: inline-block;
  margin-left: 3.4375rem;
  width: 40rem;
  height: 14.375rem;
}

.intelligent-meter1{
  padding-top: 4.6875rem;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.intelligent-meter-left1{
  display: inline-block;
  width: 40rem;
  height: 14.375rem;
}

.intelligent-meter-right1{
  display: inline-block;
  margin-left: 3.4375rem;
  width: 39.6875rem;
  height: 10.625rem;
}

</style>
