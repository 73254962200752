<template>
  <div class="honor-box" v-if="certi">
    <!-- 标题文字盒子 -->
    <div class="honor-top1">
      <p class="honor-top-text1">获奖证书</p>
      <p class="honor-top-text2">Certificate of award</p>
    </div>
    <!-- 获奖证书图片盒子 -->
    <div class="certificate-img">
      <!-- 每张图片的盒子 -->
      <div class="certificate-img-box" v-for="item in urls" :key="item.id">
        <img :src="item.url" alt="">
        <div class="certificate-img-text">
          <p class="p1">{{item.text}}</p>
          <p class="p2">{{item.text1}}</p>
        </div>
      </div>
       <!-- 底部三张图片 -->
        <div class="certificate-img1">
          <div class="certificate-img-box2" v-for="item in urls1" :key="item.id">
            <img :src="item.url" alt="">
            <div>
              <p class="p3">{{item.text}}</p>
            </div>
          </div>
        </div>
    </div>
    <!-- 下一页按钮 -->
      <el-button class="btn" type="text" @click="jump">下一页</el-button>
  </div>
  <utilitymodelpatent v-else></utilitymodelpatent>
</template>

<script>
import utilitymodelpatent from '../Certificate Patent/UtilityModelPatent.vue'
export default {
  // 荣誉资质-荣誉-查看更多
  name: 'certificate',
  data () {
    return {
      urls: [
        { id: 1, text: '17年泰州蜂鸟 二等奖', url: require('../../../assets/imgs/关于我们/3/获奖证书/17年泰州蜂鸟 二等奖.jpg') },
        { id: 2, text: '第七届创新创业大赛', text1: '优秀企业', url: require('../../../assets/imgs/关于我们/3/获奖证书/2018.08第七届创新创业大赛 优秀企业.jpg') },
        { id: 3, text: '创青春三等奖', url: require('../../../assets/imgs/关于我们/3/获奖证书/创青春三等奖.jpg') },
        { id: 4, text: '宏翔东方高企证书', url: require('../../../assets/imgs/关于我们/3/获奖证书/宏翔东方高企证书.jpg') },
        { id: 5, text: '科协优秀奖1', url: require('../../../assets/imgs/关于我们/3/获奖证书/科协优秀奖.jpg') },
        { id: 6, text: '科协优秀奖2', url: require('../../../assets/imgs/关于我们/3/获奖证书/科协优秀奖78.jpg') },
        { id: 7, text: '人社局一类项目', url: require('../../../assets/imgs/关于我们/3/获奖证书/人社局一类项目.jpg') },
        { id: 8, text: '市科技创新一等奖', url: require('../../../assets/imgs/关于我们/3/获奖证书/市科技创新一等奖.jpg') },
        { id: 9, text: '先进集体', url: require('../../../assets/imgs/关于我们/3/获奖证书/先进集体.jpg') },
        { id: 10, text: '研究生工作站_1', url: require('../../../assets/imgs/关于我们/3/获奖证书/研究生工作站_1.jpg') },
        { id: 11, text: '中国创翼一等奖', url: require('../../../assets/imgs/关于我们/3/获奖证书/中国创翼一等奖.jpg') },
        { id: 12, text: '中小科技企业入库编号', url: require('../../../assets/imgs/关于我们/3/获奖证书/中小科技企业入库编号.png') },
        { id: 13, text: '朱总 333人才 证书', url: require('../../../assets/imgs/关于我们/3/获奖证书/朱总 333人才 证书.png') },
        { id: 14, text: '质量安全管理优秀企业证书', url: require('../../../assets/imgs/关于我们/3/获奖证书/质量安全管理优秀企业证书.jpg') }
      ],
      urls1: [
        { id: 1, text: 'i创杯 获奖证书_1', url: require('../../../assets/imgs/关于我们/3/获奖证书/i创杯 获奖证书_1.jpg') },
        { id: 2, text: '科协批复', url: require('../../../assets/imgs/关于我们/3/获奖证书/科协批复.jpg') },
        { id: 3, text: '验收证书', url: require('../../../assets/imgs/关于我们/3/获奖证书/验收证书.jpg') }
      ],
      // 默认显示获奖证书组件
      certi: true
    }
  },
  created () {
    // 打开组件回到顶部
    this.toTop()
  },
  methods: {
  // 隐藏获奖证书组件
    jump () {
      this.certi = false
    },
    // 接受子组件调用显示获奖证书，隐藏实用新型专利
    jump2 () {
      this.certi = true
    },
    toTop () {
      document.documentElement.scrollTop = 0
    }
  },
  components: { utilitymodelpatent }
}

</script>

<style scoped>
.honor-box{
  margin-left: 10.9375rem;
  margin-top: 5.3125rem;
  width: 85rem;
  height: 100%;
}

.honor-top{
  width: 100%;
  height: 100%;
}

.honor-top1{
  width: 100%;
  height: 7.5rem;
  margin-left: .8125rem;
}

.honor-top-text1{
  font-size: 2.25rem;
  color: #004ea1;
}

.honor-top-text2{
  font-size: 1.25rem;
  color: #6985a3;
}

.certificate-img{
  width: 100%;
  height: 100%;
}

.certificate-img-box{
  display: inline-block;
  margin-left: .8125rem;
  width: 19.8125rem;
  height: 20.625rem;
}

.certificate-img img{
  width: 19.8125rem;
  height: 14.375rem;
}

.certificate-img-text{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.4375rem;
  margin-top: .625rem;
  font-size: 1.375rem;
}

.p1{
  position: absolute;
  top: 0;
}

.p2{
  position: absolute;
  top: 2.5rem;
}

.certificate-img1{
  width: 100%;
  height: 100%;
}

.certificate-img-box2{
  display: inline-block;
  width: 13.9375rem;
  height: 100%;
  margin-top: 3.125rem;
  margin-left: 8.75rem;
}

.certificate-img-box2:first-child{
  margin-left: 4.0625rem;
}

.certificate-img-box2 img{
  width: 13.9375rem;
  height: 19.6875rem;
}

.p3{
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.375rem;
}

.btn{
  margin-top: 23.125rem;
  margin-left: 76.875rem;
  padding-bottom: 45.3125rem;
  font-size: 1.625rem;
  color: #000;
}
</style>
