<template>
  <div class="honor-box">
    <!-- 标题文字盒子 -->
    <div class="honor-top1">
      <p class="honor-top-text1">实用新型专利</p>
      <p class="honor-top-text2">Utility models</p>
    </div>
    <!-- 实用新型专利的图片盒子 -->
    <div class="patent-img">
      <div class="patent-img-box" v-for="item in urls" :key="item.id">
        <img :src="item.url" alt="">
        <div class="patent-img-text">
          <p class="p1">{{item.text}}</p>
          <p class="p2">{{item.text1}}</p>
        </div>
      </div>
    </div>
    <!-- 上一页按钮 -->
    <el-button class="btn" type="text" @click="jump">上一页</el-button>
  </div>
</template>

<script>
export default {
  // 荣誉资质-知识产权-查看更多
  name: 'UtilityModelPatent',
  data () {
    return {
      urls: [
        { id: 1, text: '压差调节型气动控制机构', url: require('../../../assets/imgs/关于我们/3/实用新型专利/1压差调节型气动控制机构.jpg') },
        { id: 2, text: '机械式拉伸旋转机构', url: require('../../../assets/imgs/关于我们/3/实用新型专利/2机械式拉伸旋转机构.jpg') },
        { id: 3, text: '等离子切割空间轨迹', text1: '规划机器人系统', url: require('../../../assets/imgs/关于我们/3/实用新型专利/3等离子切割空间轨迹规划机器人系统.jpg') },
        { id: 4, text: '机械式放气调节装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/4机械式放气调节装置.jpg') },
        { id: 5, text: '弹簧与气压同步作用型', text1: '管路缩口调节系统', url: require('../../../assets/imgs/关于我们/3/实用新型专利/5弹簧与气压同步作用型管路缩口调节系统.jpg') },
        { id: 6, text: '一种方便调整的弯管机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/6一种方便调整的弯管机.jpg') },
        { id: 7, text: '一种具有安全防护装置的切管机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/7一种具有安全防护装置的切管机.jpg') },
        { id: 8, text: '一种切管机的减震结构', text1: '管子的弯管机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/8一种切管机的减震结构.jpg') },
        { id: 9, text: '一种适用于不同管径', text1: '管子的弯管机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/9一种适用于不同管径管子的弯管机.jpg') },
        { id: 10, text: '一种用于弯管机的', text1: '送料传动机构', url: require('../../../assets/imgs/关于我们/3/实用新型专利/10一种用于弯管机的送料传动机构.jpg') },
        { id: 11, text: '一种便于调节的凹版印花机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/11一种便于调节的凹版印花机.jpg') },
        { id: 12, text: '一种便于移动具有烘干', text1: '功能的凹版印花机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/12一种便于移动具有烘干功能的凹版印花机.jpg') },
        { id: 13, text: '一种弯管机进给装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/13一种弯管机进给装置.jpg') },
        { id: 14, text: '一种弯管机水平调节装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/14一种弯管机水平调节装置.jpg') },
        { id: 15, text: '一种改进型印花机', url: require('../../../assets/imgs/关于我们/3/实用新型专利/15一种改进型印花机.jpg') },
        { id: 16, text: '一种印花机导带清洗装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/16一种印花机导带清洗装置.jpg') },
        { id: 17, text: '一种印花机的加浆装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/17一种印花机的加浆装置.jpg') },
        { id: 18, text: '一种印花机色浆过滤装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/18一种印花机色浆过滤装置.jpg') },
        { id: 19, text: '一种印花机用定位装置', url: require('../../../assets/imgs/关于我们/3/实用新型专利/19一种印花机用定位装置.jpg') }
      ]
    }
  },
  created () {
    // 打开组件回到顶部
    this.toTop()
  },
  methods: {
    // 调用父组件方法隐藏实用新型专利，显示获奖证书
    jump () {
      this.$parent.jump2()
    },
    // 回到顶部
    toTop () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.honor-top{
  width: 100%;
  height: 100%;
}

.honor-top1{
  width: 100%;
  height: 7.5rem;
  margin-left: .8125rem;
}

.honor-top-text1{
  font-size: 2.25rem;
  color: #004ea1;
}

.honor-top-text2{
  font-size: 1.25rem;
  color: #6985a3;
}

.patent-img{
  width: 100%;
  height: 100%;
}

.patent-img-box{
  display: inline-block;
  width: 19.6875rem;
  height: 31.25rem;
  margin-left: .9375rem;
}

.patent-img-box img{
  width: 100%;
  height: 25rem;
}

.patent-img-text{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
}

.patent-img-text p{
  font-size: 1.375rem;
}

.p1{
  position: absolute;
  top: .625rem;
}

.p2{
  position: absolute;
  top: 2.5rem;
}

.btn{
  margin-top: 23.125rem;
  margin-left: 76.875rem;
  padding-bottom: 45.3125rem;
  font-size: 1.625rem;
  color: #000;
}
</style>
