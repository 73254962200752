<template>
  <div class="box">
    <!-- 标题和搜索盒子 -->
    <div class="headlogo">
      <!-- 左侧盒子 -->
      <div class="head-left">
        <!-- logo -->
        <div class="msoimg">
          <img src="../assets/imgs/clurlogo.png" alt="">
        </div>
        <!-- logo标题 -->
        <div class="mso-title">
          <div class="text1">
            江苏海宏智能科技有限公司
          </div>
          <div class="text2">
            -OCEAN&MACRO INTELLGENT TECHNOLOGY-
          </div>
        </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="head-rgiht">
        <!-- 微信公众号 -->
        <div class="weChat-official-account">
          <img src="../assets/imgs/微信.png" alt="">
          <div class="a-left-box">
            <el-button type="text" class="but-left">微信公众号</el-button>
          </div>
          <el-button type="text" class="but-right">收藏本站</el-button>
          <!-- 二维码 -->
          <div class="QR-code">
            <img src="../assets/imgs/公众号 拷贝.png" alt="">
          </div>
        </div>
        <!-- 右侧搜索输入框和按钮 -->
        <div class="head-inp">
          <el-input type="text" placeholder="输入关键词搜索" v-model="search"></el-input>
          <el-button class="head-but" @click="searchbtn">搜索</el-button>
        </div>
      </div>
    </div>
    <!-- 导航栏盒子 -->
    <el-breadcrumb class="Navigation">
      <el-breadcrumb-item class="a-link" :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: './AboutUs' }"><a>关于我们</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/ProductCenter' }"><a>产品中心</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/CaseSharing' }"><a>案例分享</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/TalentRecruitment' }"><a>人才招聘</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/BusinessCooperation' }"><a>商务合作</a></el-breadcrumb-item>
      <el-breadcrumb-item class="a-link" :to="{ path: '/Notice' }"><a>公告</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item class="a-link" :to="{ path: '/News' }"><a>企业新闻</a></el-breadcrumb-item> -->
      <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 顶部图片盒子 -->
    <div class="top-box">
      <p class="top-p1">商务合作</p>
      <p class="top-p2">BUSINESS COOPERATION</p>
    </div>
    <!-- 您的位置 -->
    <div class="location-top">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商务合作</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 连云港总部 -->
    <div class="headquarters">
      <p class="headquarters-top">连云港总部</p>
      <p class="headquarters-bottom"></p>
    </div>
    <!-- 连云港总部下方联系方式 -->
    <div class="contact-information">
      <!-- 联系地址 -->
      <div class="contact-address">
        <!-- 圆圈盒子 -->
        <div class="contact-address-circle">
          <img src="../assets/imgs/商务合作/地址.png" alt="">
        </div>
        <!-- 下方文字标题 -->
        <div class="contact-address-title">联系地址</div>
        <!-- 下方文字 -->
        <div class="contact-address-text">
          中国江苏自由贸易试验区（<br />连云港片区）黄海大道58号
        </div>
      </div>
      <!-- 咨询热线 -->
      <div class="consulting-hotline">
        <div class="consulting-hotline-circle">
          <img src="../assets/imgs/商务合作/电话.png" alt="">
        </div>
        <!-- 下方文字标题 -->
        <div class="contact-address-title">咨询热线</div>
        <!-- 下方文字 -->
        <div class="contact-address-text">
          19901541926 (武经理 )
        </div>
      </div>
      <!-- 微信 -->
      <div class="wechat">
        <div class="wechat-circle">
          <img src="../assets/imgs/商务合作/微信.png" alt="">
        </div>
        <!-- 下方文字标题 -->
        <div class="contact-address-title">微信</div>
        <!-- 下方二维码 -->
        <div class="wechat-img">
          <img src="../assets/imgs/商务合作/图层 544.png" alt="">
        </div>
      </div>
      <!-- 抖音 -->
      <div class="tiktok">
        <div class="tiktok-circle">
          <img src="../assets/imgs/商务合作/图层 545.png" alt="">
        </div>
        <!-- 下方文字标题 -->
        <div class="contact-address-title">抖音</div>
        <!-- 下方二维码 -->
        <div class="tiktok-img">
          <img src="../assets/imgs/商务合作/抖音.png" alt="">
        </div>
      </div>
      <!-- 联系邮箱 -->
      <div class="contact-email">
        <div class="contact-email-circle">
          <img src="../assets/imgs/商务合作/邮箱.png" alt="">
        </div>
        <!-- 下方文字标题 -->
        <div class="contact-address-title">联系邮箱</div>
        <!-- 下方文字 -->
        <div class="contact-address-text">
          jingzhaoyin@mail.chinamso.com
        </div>
      </div>
    </div>
    <!-- 地图盒子 -->
    <div class="map">
      <img src="../assets/imgs/商务合作/地图.png" alt="">
    </div>
    <!-- 下方文字盒子 -->
    <div class="bottom-text">
      <div class="bottom-text1">
        <p class="bottom-span">企业产品定位明确，对企业产品应专注进行研发升级、精益求精、做到极致、同时注重建立品牌、实现快速的产品迭代升级。</p>
        <p class="bottom-span">
          企业理念是我们企业的最高行动纲领，是我们员工的最高行为准则，是衡量我们的决策、管理、制度、行为、形象是否准确的基准。每一位员工尤其是管理人员一定要正确理解并时刻牢记我们企业的企训、理念和精神。</p>
        <p class="bottom-span">如果您对我们的产品感兴趣，请联系我们，我们有优秀的团队，为您提供高品质的服务。</p>
      </div>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
      <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
        <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
        <p class="bottom-box-right-text">
          关于我们 | 网站地图 | 网站声明 <br />
          联系电话：19901541926 (武经理 微信同号) <br />
          电子邮箱：jingzhaoyin@mail.chinamso.com <br />
          联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号 <br />
        </p>
        <!-- 底部二维码 -->
        <div class="bottom-box-right-text1">
          <img src="../assets/imgs/公众号 拷贝.png" alt="">
          <p>微信公众号</p>
        </div>
        <!-- 底部版权 -->
        <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
      <el-button>
        <img src="../assets/imgs/侧边栏/微信.png" alt="">
        <p>微信</p>
      </el-button>
      <el-button>
        <img src="../assets/imgs/侧边栏/形状.png" alt="">
        <p>在线沟通</p>
      </el-button>
      <el-button>
        <img src="../assets/imgs/侧边栏/电话.png" alt="">
        <p>联系电话</p>
      </el-button>
      <el-button @click="toTop">
        <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
        <p>返回顶部</p>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  // 商务合作
  name: 'BusinessCooperation',
  data() {
    return {
      search: ''
    }
  },
  created() {
    // 打开组件回到顶部
    this.toTop()
  },
  methods: {
    searchbtn() {
      console.log(this.search)
    },
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    },
    toTop() {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.top-box {
  position: relative;
  width: 100%;
  height: 22.5rem;
  background-image: url('../assets/imgs/商务合作/图层 540.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.top-p1 {
  position: absolute;
  top: 6.9375rem;
  left: 52.8125rem;
  font-size: 3.75rem;
  font-weight: 700;
  color: #fffefe;
}

.top-p2 {
  position: absolute;
  top: 12.5625rem;
  left: 40.0625rem;
  font-size: 3.125rem;
  font-weight: 700;
  color: #fffefe;
}

.location-top {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3.625rem;
  padding-left: 8.125rem;
  line-height: 1.5625rem;
  font-size: 1.625rem;
  color: #3f3f3f;
}

/deep/ .location-top .el-breadcrumb__inner,
/deep/ .el-icon-arrow-right:before,
/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-size: 1.625rem;
  color: #3f3f3f;
}

/deep/.location-top .el-breadcrumb__inner.is-link {
  font-weight: 400 !important;
}

/deep/.location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #3f3f3f;
}

.headquarters {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 4.0625rem;
  width: 100%;
  height: 3.75rem;
}

.headquarters-top {
  display: flex;
  justify-content: center;
  width: 10.9375rem;
  font-size: 2.125rem;
  color: #3f3f3f;
}

.headquarters-bottom {
  position: absolute;
  left: 54.9375rem;
  bottom: 0;
  width: 10.25rem;
  height: .3125rem;
  background-color: #004ea1;
}

.contact-information {
  position: relative;
  margin-top: 4.625rem;
  width: 100%;
  height: 34.375rem;
}

.contact-address {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 17.8125rem;
  width: 13.75rem;
  height: 19.375rem;
}

.contact-address-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.5625rem;
  height: 9.5625rem;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.contact-address-circle img {
  width: 3.1875rem;
  height: 3.5625rem;
}

.contact-address-title {
  position: absolute;
  top: 11.75rem;
  font-size: 1.5rem;
  color: #3f3f3f;
}

.contact-address-text {
  position: absolute;
  top: 15.125rem;
  font-size: 1.125rem;
  color: #333;
}

.consulting-hotline {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 35.625rem;
  width: 13.6875rem;
  height: 19.375rem;
}

.consulting-hotline-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.5625rem;
  height: 9.5625rem;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.consulting-hotline-circle img {
  width: 3.375rem;
  height: 3.625rem;
}

.wechat {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 53.875rem;
  width: 13.6875rem;
  height: 19.375rem;
}

.wechat-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.5625rem;
  height: 9.5625rem;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.wechat-circle img {
  width: 4.25rem;
  height: 3.5rem;
}

.wechat-img {
  position: absolute;
  top: 15.1875rem;
  width: 7.25rem;
  height: 7.3125rem;
}

.tiktok {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 71.625rem;
  width: 13.6875rem;
  height: 19.375rem;
}

.tiktok-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.5625rem;
  height: 9.5625rem;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.tiktok-circle img {
  position: absolute;
  top: 2.875rem;
  left: 3.125rem;
  width: 4.75rem;
  height: 5.875rem;
}

.tiktok-img {
  position: absolute;
  top: 14.6875rem;
  width: 7.25rem;
  height: 7.3125rem;
}

.contact-email {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 91rem;
  width: 13.6875rem;
  height: 19.375rem;
}

.contact-email-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.5625rem;
  height: 9.5625rem;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.contact-email-circle img {
  width: 3.5rem;
  height: 2.875rem;
}

.map {
  width: 100%;
  height: 43.5625rem;
}

.bottom-text {
  display: flex;
  justify-content: center;
  margin-top: 5.625rem;
  width: 100%;
  height: 43.75rem;
}

.bottom-span {
  width: 75rem;
  line-height: 50px;
  font-size: 1.625rem;
  color: #3f3f3f;
  text-indent: 2em;
}</style>
