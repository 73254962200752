<template>
  <div>
    <!-- 标题盒子 -->
    <div class="top-title">
    <p class="top-title-text">基于物联网的柔性智能制造系统典型结构</p>
    <p class="top-title-box"></p>
    </div>
    <div class="top-box1">
      <img class="top-box1-img" src="../../assets/imgs/产品中心/4/图层 537.png" alt="">
      <!-- 图片上的文字 -->
      <p class="top-box1-img-text1">互联网</p>
      <p class="top-box1-img-text2">企业级</p>
      <p class="top-box1-img-text3">车间级</p>
      <p class="top-box1-img-text4">车间级</p>
      <!-- 实现柔性智能制造系统的关键 -->
      <div class="top-box1-text1">
        <p class="box1-text1">实现柔性智能制造系统的<span>关键：</span></p>
        <p class="box1-text2">
          <!-- 右箭头 -->
          <img class="right-arrow1" src="../../assets/imgs/产品中心/4/形状 36.png" alt="">
          畅通数据流
        </p>
        <p class="box1-text3">
          <!-- 右箭头 -->
          <img class="right-arrow2" src="../../assets/imgs/产品中心/4/形状 36.png" alt="">
          互联互通
        </p>
        <p class="box1-text4">
          <!-- 右箭头 -->
          <img class="right-arrow3" src="../../assets/imgs/产品中心/4/形状 36.png" alt="">
          云服务
        </p>
      </div>
    <!-- 图片右侧介绍1 -->
      <div class="top-box2-right1">
        <p class="top-box2-right1-p1">互联网+</p>
        <p class="top-box2-right1-p2">云应用服务、移动服务、数据服务</p>
      </div>
      <!-- 图片右侧介绍2 -->
      <div class="top-box2-right2">
        <p class="top-box2-right2-p1">企业信息层</p>
        <p class="top-box2-right2-p2">ERP（企业资源计划）&emsp;CRM（客户关系管理）</p>
        <p class="top-box2-right2-p3">SCM（供应链管理）&emsp;&emsp;PLM（产品全生命周期管理）</p>
          <img class="top-box2-right2-img" src="../../assets/imgs/产品中心/4/图层 538.png" alt="">
      </div>
      <!-- 图片右侧介绍3 -->
      <div class="top-box2-right3">
        <p class="top-box2-right3-p1">MES（制造执行系统）数据采集与监控层<br />生产线\装置级控制系统</p>
        <p class="top-box2-right3-p2">SCADA数据采集与监视控制系统PLC\DCS\辅助生产子系统</p>
          <img class="top-box2-right3-img" src="../../assets/imgs/产品中心/4/图层 539.png" alt="">
      </div>
      <!-- 图片右侧介绍4 -->
      <div class="top-box2-right4">
        <p class="top-box2-right4-p1">设备层</p>
        <p class="top-box2-right4-p2">RFID\传感器\加工中心\数控机床\机器人\3D打印机等各类智能执行设备</p>
          <img class="top-box2-right4-img" src="../../assets/imgs/产品中心/4/图层 540.png" alt="">
      </div>
    </div>
    <!-- 解决方案标题 -->
    <div class="solution-top">
      <p class="solution-p1">解决方案</p>
      <div class="solution-box"></div>
      <p class="solution-p2">Total Solution</p>
    </div>
    <!-- 解决方法下方盒子 -->
    <div class="solution-bottom-box">
      <!-- 左侧图片 -->
      <div class="solution-bottom-box1">
        <img src="../../assets/imgs/产品中心/4/4-物联网文案+图片/1-连e行/1.jpg" alt="">
        <p class="solution-bottom-box1-line"></p>
      </div>
      <!-- 右侧文字 -->
      <div class="solution-bottom-text1">
        连云港市交通控股集团连e行系统<br />
        <span>
          连云港市交通控股集团服务广大市民的公共出行管理平台，包括了网约车，汽车公交，汽车租赁，定制包车，停车场，驾培等子项目。
        </span>
      </div>
      <!-- 右侧图片 -->
      <div class="solution-bottom-box2">
        <img src="../../assets/imgs/产品中心/4/4-物联网文案+图片/1-连e行/微信图片_20220708180628.png" alt="">
      </div>
    </div>
    <!-- 连云港市智慧石梁河监管平台 -->
    <div class="supervise">
      连云港市智慧石梁河监管平台
      <p>
        该平台是对石梁河水库的砂石开采，水文水质，生态养殖等全面监督、综合管理，做到“信息透明、预报预警”，“成果上屏，一屏指挥”。
      </p>
    </div>
    <!-- 下方图片 -->
    <div class="supervise-img">
      <img src="../../assets/imgs/产品中心/4/4-物联网文案+图片/2-石梁河/微信图片_20220709152241(1).jpg" alt="">
      <img src="../../assets/imgs/产品中心/4/4-物联网文案+图片/2-石梁河/微信图片_20220709152317(1).jpg" alt="">
    </div>
    <!-- 日出东方集团平板集热器长MES管理系统 -->
    <div class="management-title">
      <!-- 标题 -->
      <p class="management-title1">日出东方集团平板集热器长MES管理系统</p>
      通过MES系统实现平板集热器厂各个信息化模块，以及部分自动化设备的模块集成，打通“最后一公里”信息化，提高生产效率，为领导全局决策提供全面的数据依据。<br />
      增加制定车间半成品BOM基础数据，重整工艺流程、工艺指导书和工艺图纸，每日生产计划分解下发时能够给班组长，以及工人切实地、明确地指导。能有效保障产品质量，减少成本损耗，且便于质量问题追湖。
      计件工资表（“3E卡”)上信息系统，减少人工输入误差，实现工人计件工资透明化，有效提高工人的积极性。
    </div>
    <!-- 下方图片 -->
    <div class="management-img">
      <img src="../../assets/imgs/产品中心/4/4-物联网文案+图片/3-日出东方MES/微信图片_20220709152326.png" alt="">
      <img src="../../assets/imgs/产品中心/4/4-物联网文案+图片/3-日出东方MES/微信图片_20220709152337.png" alt="">
    </div>
    <!-- 底部两图片盒子 -->
    <div class="management-img-bottom">
      <div class="management-img-text1">应用于互联网数据云端监控</div>
      <img class="management-img-bottom1" src="../../assets/imgs/产品中心/4/图层 528.png" alt="">
      <div class="management-img-text2">成果应用案例</div>
      <img class="management-img-bottom2" src="../../assets/imgs/产品中心/4/图层 529.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  // 物联网信息平台
  name: 'InformationPlatform'
}
</script>

<style scoped>
.top-title{
  margin-top: 4.375rem;
  width: 100%;
  height: 3.1875rem;
}

.top-title-text{
  margin-left: 32.5rem;
  font-size: 1.875rem;
  color: #292929;
}

.top-title-box{
  margin-left: 32.5rem;
  width: 34.1875rem;
  height: .0625rem;
  background-color: #5e5e5e;
}

.top-box1{
  position: relative;
  width: 100%;
  height: 33.4375rem;
}

.top-box1-img{
  position: absolute;
  left: 33.625rem;
  width: 31.75rem;
  height: 33.4375rem;
}

.top-box1-text1{
  position: relative;
  top: 3.5rem;
  left: 18rem;
  width: 24.0625rem;
  height: 9.6875rem;
}

.box1-text1{
  padding-left: 14px;
  font-size: 1.625rem;
  color: #666;
}

.box1-text1 span{
  color: #fe6161;
}

.box1-text2{
  padding-left: 2.625rem;
  padding-top: .5rem;
  font-size: 1.5rem;
  color: #0024ff;
}

.box1-text3{
  padding-left: 2.625rem;
  font-size: 1.5rem;
  color: #0024ff;
}

.box1-text4{
  padding-left: 2.625rem;
  font-size: 1.5rem;
  color: #0024ff;
}

.right-arrow1{
  position: absolute;
  top: 3.25rem;
  left: 0;
  width: 1.6875rem !important;
  height: .9375rem !important;
}

.right-arrow2{
  position: absolute;
  top: 5.1875rem;
  left: 0;
  width: 1.6875rem !important;
  height: .9375rem !important;
}

.right-arrow3{
  position: absolute;
  top: 115px;
  left: 0;
  width: 1.6875rem !important;
  height: .9375rem !important;
}

.top-box1-img-text1,.top-box1-img-text2,.top-box1-img-text3,.top-box1-img-text4{
  position: absolute;
  top: 3.75rem;
  left: 46.9375rem;
  font-size: 1.5rem;
  color: #292929;
}

.top-box1-img-text2{
  position: absolute;
  top: 12.125rem;
}

.top-box1-img-text3{
  position: absolute;
  top: 20.4375rem;
}

.top-box1-img-text4{
  position: absolute;
  top: 28.875rem;
}

.top-box2-right1{
  position: absolute;
  top: 2.0625rem;
  left: 52.8125rem;
  width: 39.0625rem;
  height: 5.4375rem;
  border-bottom: .0625rem dashed #bababa;
}

.top-box2-right1-p1{
  padding-left: 1.0625rem;
  font-size: 1.375rem;
  color: #4cc8f4;
}

.top-box2-right1-p2{
  padding-top: 1rem;
  padding-left: 1.0625rem;
  font-size: 1.125rem;
  color: #666;
}

.top-box2-right2{
  position: absolute;
  top: 9.625rem;
  left: 56.8125rem;
  width: 39.4375rem;
  height: 6.5625rem;
  border-bottom: .0625rem dashed #bababa;

}

.top-box2-right2-p1{
  padding-left: 1.0625rem;
  font-size: 1.375rem;
  color: #4cc8f4;
}

.top-box2-right2-p2{
  padding-top: .875rem;
  padding-left: 1.0625rem;
  font-size: 1.125rem;
  color: #666;
}

.top-box2-right2-p3{
  padding-top: 1rem;
  padding-left: 1.0625rem;
  font-size: 1.125rem;
  color: #666;
}

.top-box2-right2-img{
  position: absolute;
  bottom: .875rem;
  left: 31.375rem;
  width: 4.9375rem;
  height: 4.25rem;
}

.top-box2-right3{
  position: absolute;
  top: 17.3125rem;
  right: 19.375rem;
  width: 39.4375rem;
  height: 7.25rem;
  border-bottom: .0625rem dashed #bababa;
}

.top-box2-right3-p1{
  position: absolute;
  padding-left: 1.0625rem;
  width: 14.375rem;
  height: 6.25rem;
  line-height: 2.5rem;
  font-size: 1.375rem;
  color: #4cc8f4;
}

.top-box2-right3-p2{
  position: absolute;
  bottom: .8125rem;
  left: 17.25rem;
  width: 16.1875rem;
  height: 2.875rem;
  line-height: 1.875rem;
  font-size: 1.125rem;
  color: #666;
}

.top-box2-right3-img{
  position: absolute;
  right: 1.5625rem;
  bottom: 1.0625rem;
  width: 3.75rem;
  height: 5.3125rem;
}

.top-box2-right4{
  position: absolute;
  top: 26.8125rem;
  right: 15.625rem;
  width: 39.125rem;
  height: 6.5rem;
  border-bottom: .0625rem dashed #bababa;
}

.top-box2-right4-p1{
  padding-left: 1.0625rem;
  font-size: 1.375rem;
  color: #4cc8f4;
}

.top-box2-right4-p2{
  padding-top: 1rem;
  padding-left: 1.0625rem;
  width: 20.5625rem;
  font-size: 1.125rem;
  color: #666;
}

.top-box2-right4-img{
  position: absolute;
  bottom: 1.25rem;
  right: 5.6875rem;
  width: 3.4375rem;
  height: 4.5625rem;
}

.solution-top{
  position: relative;
  margin-top: 5.5625rem;
  margin-left: 56.75rem;
  width: 7.1875rem;
  height: 4.125rem;
}

.solution-p1{
  position: absolute;
  top: 0;
  font-size: 1.625rem;
  color: #585858;
}

.solution-box{
  position: absolute;
  top: 2.5rem;
  left: 1.0625rem;
  width: 4.5rem;
  height: .125rem;
  background-color: #004ea1;
}

.solution-p2{
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: #585858;
}

.solution-bottom-box{
  position: relative;
  margin-top: 5.3125rem;
  width: 100%;
  height: 47.5rem;
  background-color: #fff;
}

.solution-bottom-box1{
  position: absolute;
  width: 22.875rem;
  height: 47.6875rem;
  margin-left: 18.4375rem;
}

.solution-bottom-box1-line{
  position: absolute;
  left: 6.75rem;
  bottom: 0;
  width: 9.3125rem;
  height: .3125rem;
  background-color: #d3d3d3;
}

.solution-bottom-text1{
  position: absolute;
  top: .6875rem;
  left: 48.625rem;
  width: 42.625rem;
  height: 7.8125rem;
  font-size: 2.125rem;
  color: #000;
}

.solution-bottom-text1 span{
  font-size: 1.375rem;
  color: #1d1d1d;
}

.solution-bottom-box2{
  position: absolute;
  top: 13.875rem;
  right: 18.5rem;
  width: 54.1875rem;
  height: 29.8125rem;
}

.supervise{
  position: relative;
  margin: 6.125rem 0 2.375rem 38.3125rem;
  padding-left: 7.5rem;
  width: 41.6875rem;
  height: 7.6875rem;
  font-size: 2.125rem;
  color: #000;
}

.supervise p{
  position: absolute;
  top: 4.375rem;
  left: 0;
  font-size: 1.375rem;
  color: #1d1d1d;
}

.supervise-img{
  margin-left: 18.25rem;
  margin-top: 1rem;
  width: 83.1875rem;
  height: 100%;
}

.management-title{
  margin: 6.1875rem 0 3.125rem 26.5625rem;
  width: 66.5625rem;
  height: 100%;
  font-size: 1.375rem;
  color: #1d1d1d;
}

.management-title1{
  padding-bottom: 2.5rem;
  padding-left: 13.75rem;
  font-size: 2.125rem;
  color: #000;
}

.management-img{
  margin-left: 18.25rem;
  margin-top: 1rem;
  width: 83.1875rem;
  height: 100%;
}

.management-img-bottom{
  position: relative;
  width: 100%;
  height: 43.75rem;
}

.management-img-bottom1{
  padding-top: 4.0625rem;
  padding-left: 16.25rem;
  width: 39rem;
  height: 28.75rem;
}

.management-img-bottom2{
  position: absolute;
  margin-top: 6.875rem;
  right: 19.375rem;
  width: 44.75rem;
  height: 28.4375rem;
}

.management-img-text1{
  position: absolute;
  top: 36.75rem;
  left: 23.9375rem;
  font-weight: 700;
  font-size: 2rem;
  color: #000;
}

.management-img-text2{
  position: absolute;
  z-index: 2;
  top: 7.3125rem;
  right: 575px;
  font-size: 2rem;
  font-weight: 700;
  color: #ff4251;
  background-color: #ffc000;
}
</style>
