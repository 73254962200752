<template>
  <div class="box">
    <!-- 标题和搜索盒子 -->
    <div class="headlogo">
      <!-- 左侧盒子 -->
      <div class="head-left">
        <!-- logo -->
          <div class="msoimg">
            <img src="../../assets/imgs/clurlogo.png" alt="">
          </div>
          <!-- logo标题 -->
          <div class="mso-title">
            <div class="text1">
              江苏海宏智能科技有限公司
            </div>
            <div class="text2">
              -OCEAN&MACRO INTELLGENT TECHNOLOGY-
            </div>
          </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="head-rgiht">
        <!-- 微信公众号 -->
        <div class="weChat-official-account">
          <img src="../../assets/imgs/微信.png" alt="">
          <div class="a-left-box">
            <el-button type="text" class="but-left">微信公众号</el-button>
          </div>
          <el-button type="text" class="but-right">收藏本站</el-button>
          <!-- 二维码 -->
          <div class="QR-code">
            <img src="../../assets/imgs/公众号 拷贝.png" alt="">
          </div>
        </div>
        <!-- 右侧搜索输入框和按钮 -->
        <div class="head-inp">
          <el-input type="text" placeholder="输入关键词搜索" v-model="search"></el-input>
          <el-button class="head-but" @click="searchbtn">搜索</el-button>
        </div>
      </div>
    </div>
    <!-- 导航栏盒子 -->
    <el-breadcrumb class="Navigation">
        <el-breadcrumb-item class="a-link" :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: './AboutUs' }"><a>关于我们</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/ProductCenter' }"><a>产品中心</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/CaseSharing' }"><a>案例分享</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/TalentRecruitment' }"><a>人才招聘</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Businesscooperation' }"><a>商务合作</a></el-breadcrumb-item>
        <el-breadcrumb-item class="a-link" :to="{ path: '/Notice' }"><a>公告</a></el-breadcrumb-item>
        <!-- <el-breadcrumb-item class="a-link" :to="{ path: '/News' }"><a>企业新闻</a></el-breadcrumb-item> -->
        <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 顶部图片盒子 -->
    <div class="top-box">
      <p class="top-p1">案例分享</p>
      <p class="top-p2">SUCCESS CASE</p>
    </div>
    <!-- 您的位置 -->
    <div class="location-top">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CaseSharing' }">案例分享</el-breadcrumb-item>
        <el-breadcrumb-item>移动充电桩</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 移动充电桩 -->
    <div class="title">
      移动充电桩
      <p class="title-box"></p>
    </div>
    <!-- 下方文字介绍 -->
    <div class="text">
      海宏智能科技有限公司研发的停车场移动充电宝解决方案，利用手机充电宝的共享创新思路，并且结合AMR智能移动机器人灵活运输充电电池，能很好地缓解新能源汽车和传统汽车对车位的大量需求，并提高了停车场车位和场地的利用率。
    </div>
    <!-- 经典案例 -->
    <div class="title1">
      经典案例
      <p class="title-box1"></p>
    </div>
    <!-- 经典案例下方图片 -->
    <div class="title1-img">
      <img class="title1-img1" src="../../assets/imgs/案例分享/1-框架页/1-移动机器人配图/2-智能仓储/智能仓储2.jpg" alt="">
      <p class="title1-text1">智能仓储</p>
      <img class="title1-img2" src="../../assets/imgs/案例分享/1-框架页/1-移动机器人配图/2-智能仓储/微信图片_202207220841542.jpg" alt="">
      <p class="title1-text2">智能移动叉车</p>
      <img class="title1-img3" src="../../assets/imgs/案例分享/1-框架页/1-移动机器人配图/3-移动充电桩【配图/图片2.png" alt="">
      <p class="title1-text3">充电场景</p>
      <img class="title1-img4" src="../../assets/imgs/案例分享/1-框架页/1-移动机器人配图/3-移动充电桩【配图/图片3.png" alt="">
      <p class="title1-text4">充电场景</p>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
    <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
            <img src="../../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
        <p class="bottom-box-right-text">
          关于我们 | 网站地图 | 网站声明 <br />
          联系电话：19901541926 (武经理 微信同号) <br />
          电子邮箱：jingzhaoyin@mail.chinamso.com <br />
          联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号  <br />
        </p>
        <!-- 底部二维码 -->
        <div class="bottom-box-right-text1">
          <img src="../../assets/imgs/公众号 拷贝.png" alt="">
          <p>微信公众号</p>
        </div>
        <!-- 底部版权 -->
        <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
        <el-button>
          <img src="../../assets/imgs/侧边栏/微信.png" alt="">
          <p>微信</p>
        </el-button>
        <el-button>
          <img src="../../assets/imgs/侧边栏/形状.png" alt="">
          <p>在线沟通</p>
        </el-button>
        <el-button>
          <img src="../../assets/imgs/侧边栏/电话.png" alt="">
          <p>联系电话</p>
        </el-button>
        <el-button @click="toTop">
          <img src="../../assets/imgs/侧边栏/dingbu.png" alt="">
          <p>返回顶部</p>
        </el-button>
    </div>
  </div>
</template>

<script>
export default {
  // 移动充电桩
  name: 'MobileChargingPile',
  data () {
    return {
      search: ''
    }
  },
  created () {
    // 打开组件回到顶部
    this.toTop()
  },
  methods: {
    searchbtn () {
      console.log(this.search)
    },
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    },
    toTop () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.top-box{
  position: relative;
  width: 100%;
  height: 22.5rem;
  background-image: url('../../assets/imgs/案例分享/图层 15.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.top-p1{
  position: absolute;
  top: 7.25rem;
  left: 52.75rem;
  font-size: 3.75rem;
  font-weight: 700;
  color: #fffefe;
}

.top-p2{
  position: absolute;
  top: 12.8125rem;
  left: 48.625rem;
  font-size: 3.125rem;
  font-weight: 700;
  color: #fffefe;
}

.location-top{
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3.625rem;
  padding-left: 8.125rem;
  line-height: 1.5625rem;
  font-size: 1.625rem;
}

/deep/ .location-top .el-breadcrumb__inner, /deep/ .el-icon-arrow-right:before,/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner{
  font-size: 1.625rem;
  color: #000;
}

/deep/.location-top .el-breadcrumb__inner.is-link{
  font-weight: 400 !important;
}

/deep/.location-top .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
  color: #000;
}

.title{
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 3.625rem;
  width: 100%;
  height: 4rem;
  font-size: 2.25rem;
  color: #000;
}

.title-box{
  position: absolute;
  bottom: 0;
  width: 10.25rem;
  height: .3125rem;
  background-color: #004ea1;
}

.text{
  margin-left: 19.1875rem;
  margin-top: 4.375rem;
  width: 80.9375rem;
  height: 100%;
  line-height: 4.375rem;
  font-size: 1.75rem;
  color: #5e5e5e;
  background-color: pink;
}

.title1{
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10.25rem;
  width: 100%;
  height: 4rem;
  font-size: 2.25rem;
  color: #000;
}

.title-box1{
  position: absolute;
  bottom: 0;
  width: 3.0625rem;
  height: .3125rem;
  background-color: #004ea1;
}

.title1-img{
  position: relative;
  margin-bottom: 53.125rem;
  width: 100%;
  height: 72.1875rem;
}

.title1-img img{
  width: 40rem;
  height: 26.25rem;
}

.title1-img1{
  position: absolute;
  top: 5.5rem;
  left: 18.6875rem;
  width: 40rem;
  height: 30.9375rem;
}

.title1-text1{
  position: absolute;
  top: 34.8125rem;
  left: 35.5rem;
  font-size: 1.75rem;
  color: #000;
}

.title1-img2{
  position: absolute;
  top: 5.5rem;
  right: 18.875rem;
  width: 40rem;
  height: 30.9375rem;
}

.title1-text2{
  position: absolute;
  top: 34.8125rem;
  right: 32.125rem;
  font-size: 1.75rem;
  color: #000;
}

.title1-img3{
  position: absolute;
  top: 41.1875rem;
  left: 18.6875rem;
  width: 40rem;
  height: 30.9375rem;
}

.title1-text3{
  position: absolute;
  top: 70.375rem;
  left: 35.5rem;
  font-size: 1.75rem;
  color: #000;
}

.title1-img4{
  position: absolute;
  top: 41.1875rem;
  right: 18.875rem;
  width: 40rem;
  height: 30.9375rem;
}

.title1-text4{
  position: absolute;
  top: 70.375rem;
  right: 32.125rem;
  font-size: 1.75rem;
  color: #000;
}
</style>
