<template>
  <div class="box">
    <!-- 招标标题 -->
    <div class="title">
      海宏集团石梁河水库视频监控系统建设项目中标结果公示
    </div>
    <div class="title_box">
      <p class="title_text">根据工程招标投标的有关法律、法规、规章和该工程招标文件的规定，海宏集团石梁河水库视频监控系统建设项目中标人已经确定。现将中标结果公示如下：</p>
      <div class="title_text">
        <span>中标人：</span>
        中国电信股份有限公司连云港分公司
      </div>
      <div class="title_text">
        <span>中标金额：</span>
        4150000元
      </div>
      <div class="title_text">
        <span>质量标准：</span>
        合格
      </div>
      <div class="title_text">
        <span>工期：</span>
        自中标通知书发之日后到项目试运行为60日历天
      </div>
    </div>
    <!-- 底部盒子 -->
    <div class="bottom-box">
      <!-- 左侧大楼图片盒子 -->
      <div class="bottom-box-left">
          <img src="../assets/imgs/建筑.png" alt="">
      </div>
      <!-- 底部信息 -->
      <div class="bottom-box-right">
            <p class="bottom-box-right-text">
              关于我们 | 网站地图 | 网站声明 <br />
              联系电话：19901541926 (曹经理 微信同号) <br />
              电子邮箱：jingzhaoyin@mail.chinamso.com <br />
              联系地址：中国江苏自由贸易试验区（连云港片区）黄海大道58号  <br />
            </p>
            <!-- 底部二维码 -->
            <div class="bottom-box-right-text1">
              <img src="../assets/imgs/公众号 拷贝.png" alt="">
              <p>微信公众号</p>
            </div>
            <!-- 底部版权 -->
          <p class="bottom-box-right-text2">Copyright © 2022-2024 江苏海宏智能科技有限公司 版权所有 &nbsp;<span style="cursor: pointer;" @click="jump_gxb">备案号：苏ICP备2021012348号-2</span></p>
      </div>
    </div>
    <!-- 回到顶部盒子 -->
    <div class="back-top">
        <el-button>
          <img src="../assets/imgs/侧边栏/微信.png" alt="">
          <p>微信</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/形状.png" alt="">
          <p>在线沟通</p>
        </el-button>
        <el-button>
          <img src="../assets/imgs/侧边栏/电话.png" alt="">
          <p>联系电话</p>
        </el-button>
        <el-button @click="toTop">
          <img src="../assets/imgs/侧边栏/dingbu.png" alt="">
          <p>返回顶部</p>
        </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Selected',
  data: function() {
    return {

    }
  },
  methods: {
    jump_gxb() {
      location.href = 'https://beian.miit.gov.cn/'
    },
    toTop () {
      document.documentElement.scrollTop = 0
    }
  }
}

</script>

<style scoped>
.title{
  display: flex;
  justify-content: center;
  margin-top: 100px;
  font-size: 35px;
  font-weight: 700;
}

.title_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_text{
  width: 80%;
  line-height: 50px;
  margin-top: 50px;
  font-weight: 500;
  font-size: 25px;
  text-indent:2em
}

.title_text span{
  font-weight: 700;
}
</style>
