<template>
  <div class="development-box">
    <p class="top-text">
      企业发展史
      <span>
        DEVELOPMENT HISTORY
      </span>
    </p>
    <div class="history">
      <!-- 左侧大盒子 -->
      <div class="history-left">
        <p class="history-left1">
          <img src="../../assets/imgs/关于我们/2/2-发展历程/10 2021.12 签约江海大智能制造产业学院.jpg" alt="">
          <span class="left-text1">2021年12月</span>
          <span class="left-text2">签约江苏海洋大学智能制造学院</span>
        </p>
        <p class="history-left2">
          <img src="../../assets/imgs/关于我们/2/2-发展历程/9 2021.10 国家创新创业优秀.jpg" alt="">
          <span class="left-text1">2021年10月</span>
          <span class="left-text2">获得国家级创新创业大赛优秀奖</span>
        </p>
        <p class="history-left3">
          <img src="../../assets/imgs/关于我们/2/2-发展历程/6 2018.08省级创新创业大赛优秀企业.jpg" alt="">
          <span class="left-text1">2018年08月</span>
          <span class="left-text2">获得省级创新创业大赛优秀奖</span>
        </p>
        <p class="history-left4">
          <img src="../../assets/imgs/关于我们/2/2-发展历程/3 2016.8 多轴运动控制系统研发中心.png" alt="">
          <span class="left-text1">2016年08月</span>
          <span class="left-text3">成立MSO多轴运动控制系统研究中心</span>
        </p>
      </div>
      <!-- 第一个盒子 -->
      <!-- 中间盒子 -->
      <div class="history-middle">
        <div class="middle-box1">2021年-</div>
        <p class="min-left-box1"></p>
        <p class="min-middle-box1"></p>
      <!-- 第二个盒子 -->
        <div class="middle-box2">2020年</div>
        <p class="min-right-box1"></p>
        <p class="min-middle-box2"></p>
      <!-- 第三个盒子 -->
        <div class="middle-box3">2018年</div>
        <p class="min-left-box2"></p>
        <p class="min-middle-box3"></p>
      <!-- 第四个盒子 -->
        <div class="middle-box4">2017年</div>
        <p class="min-right-box2"></p>
        <p class="min-middle-box4"></p>
        <!-- 第五个盒子 -->
        <div class="middle-box5">2016年</div>
        <p class="min-left-box3"></p>
        <p class="min-middle-box5"></p>
        <!-- 第六个盒子 -->
        <div class="middle-box6">2014年</div>
        <p class="min-right-box3"></p>
      </div>
      <!-- 右侧盒子 -->
      <div class="history-reight">
        <p class="history-reight1">
          <span class="right-text1">2020年12月</span>
          <span class="right-text2">获批省级研究生工作站</span>
          <img src="../../assets/imgs/关于我们/2/2-发展历程/8 2020.12 研究生工作站.jpg" alt="">
        </p>
        <p class="history-reight2">
          <span class="right-text1">2020年12月</span>
          <span class="right-text2">获批省级研究生工作站</span>
          <img src="../../assets/imgs/关于我们/2/2-发展历程/7 公司名称变更函2020.jpg" alt="">
        </p>
        <p class="history-reight3">
          <span class="right-text1">2017年10月</span>
          <span class="right-text2">获得连云港市创新创业大赛一等奖</span>
          <img src="../../assets/imgs/关于我们/2/2-发展历程/4 2017.10 创新创业大赛.png" alt="">
        </p>
        <p class="history-reight4">
          <span class="right-text1">2017年10月</span>
          <span class="right-text2">获批高新技术企业</span>
          <img src="../../assets/imgs/关于我们/2/2-发展历程/5 2017年10月.jpg" alt="">
        </p>
        <p class="history-reight5">
          <span class="right-text1">2014年12月</span>
          <span class="right-text2">承担连云港市中小企业技术创新项目</span>
          <img src="../../assets/imgs/关于我们/2/2-发展历程/2 2014中小企业创新项目.jpg" alt="">
        </p>
        <p class="history-reight6">
          <span class="right-text1">2014年04月</span>
          <span class="right-text2">公司成立</span>
          <img src="../../assets/imgs/关于我们/2/2-发展历程/1 2014公司成立.jpg" alt="">
        </p>
      </div>
    </div>
    <!-- 底部图片 -->
      <div class="bottom-img">
        <img src="../../assets/imgs/关于我们/2/banner.jpg" alt="">
      </div>
  </div>
</template>

<script>
export default {
  // 发展历程
  name: 'DevelopmentHistory'
}
</script>

<style scoped>
.development-box{
  width: 87.75rem;
  height: 100%;
  margin-left: 10.9375rem;
  margin-top: 5.3125rem;
}

.top-text{
  padding-left: 410px;
  font-size: 2.125rem;
  letter-spacing: 18px;
  font-weight: 700;
  color: #004ea1;
}

.top-text span{
  font-size: 1.25rem;
  letter-spacing: .125rem;
}

.history{
  display: flex;
  width: 87.8125rem;
  height: 124.375rem;
  margin-top: 5rem;
}

/* 左侧大盒子 */
.history-left{
  flex: 1;
  width: 34.25rem;
  height: 100%;
  /* background-color: #000; */
}

/* 左侧上方字体样式 */
.left-text1{
  position: absolute;
  top: 1.8125rem;
  right: 1.6875rem;
  font-size: 1.625rem;
  color: #333;
}

/* 左侧下方字体样式 */
.left-text2{
  position: absolute;
  top: 4.6875rem;
  right: 1.6875rem;
  color: #8a8a8a;
}

.history-left1{
  position: relative;
  width: 34.375rem;
  height: 10rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-left1 img{
  width: 13rem;
  height: 7.875rem;
  margin: .9375rem  2.8125rem .9375rem 1.1875rem;
}

.history-left2{
  position: relative;
  margin-top: .6875rem;
  width: 34.375rem;
  height: 15.4375rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-left2 img{
  margin: 1.125rem 4.5625rem 2rem 2.625rem ;
  width: 10rem;
  height: 13rem;
}

.history-left3{
  position: relative;
  margin-top: 11.5625rem;
  width: 34.375rem;
  height: 13.3125rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-left3 img{
  margin: 1rem 1.5625rem 0 .875rem;
  width: 15.6875rem;
  height: 9.875rem;
}

.history-left4{
  position: relative;
  margin-top: 21.25rem;
  width: 34.375rem;
  height: 20.9375rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-left4 img{
  margin-top: 7.3125rem;
  margin-left: 14.25rem;
  width: 16.5625rem;
  height: 11.5625rem;
}

/* 左侧最下面文字样式 */
.left-text3{
  position: absolute;
  top: 4.6875rem;
  right: 1.6875rem;
  color: #8a8a8a;
  font-size: 1.1875rem;
}

/* 中间大盒子 */
.history-middle{
  position: relative;
  flex: 1;
  width: 16.5rem;
  height: 100%;
}

/* 第一个盒子 */
.middle-box1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 2.5rem;
  margin-top: .9375rem;
  margin-left: 4.125rem;
  font-size: 1.375rem;
  color: #f7f8fb;
  border-radius: 30px;
  background-color: #004ea1;
}

/* 第一个盒子左侧短横线1 */
.min-left-box1{
  position: absolute;
  top: 2.125rem;
  left: 1.1875rem;
  width: 1.8125rem;
  height: .125rem;
  background-color: #6597fd;
}

/* 第一个盒子中间竖线1 */
.min-middle-box1{
  margin-top: .9375rem;
  margin-left: 9.5625rem;
  width: .0625rem;
  height: 9.1875rem;
  background-color: #6597fd;
}
/* 第一个盒子 */

/* 第二个盒子 */
.middle-box2{
  margin-top: .9375rem;
  margin-left: 4.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 2.5rem;
  font-size: 1.375rem;
  color: #f7f8fb;
  border-radius: 30px;
  background-color: #004ea1;
}

/* 第二个盒子右侧短横线1 */
.min-right-box1{
  position: absolute;
  top: 15.5rem;
  right: 1.1875rem;
  width: 1.8125rem;
  height: .125rem;
  background-color: #6597fd;
}

/* 第二个盒子中间横线2 */
.min-middle-box2{
  margin-top: .9375rem;
  margin-left: 9.5625rem;
  width: .0625rem;
  height: 20.625rem;
  background-color: #6597fd;
}
/* 第二个盒子 */

/* 第三个盒子 */
.middle-box3  {
  margin-top: .9375rem;
  margin-left: 4.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 2.5rem;
  font-size: 1.375rem;
  color: #f7f8fb;
  border-radius: 30px;
  background-color: #004ea1;
}

/* 第三个盒子左侧短横线2 */
.min-left-box2{
  position: absolute;
  top: 40.625rem;
  left: 1.1875rem;
  width: 1.8125rem;
  height: .125rem;
  background-color: #6597fd;
}

/* 第三个盒子中间横线3 */
.min-middle-box3{
  margin-top: .9375rem;
  margin-left: 9.5625rem;
  width: .0625rem;
  height: 8.625rem;
  background-color: #6597fd;
}

/* 第四个盒子 */
.middle-box4{
  margin-top: .9375rem;
  margin-left: 4.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 2.5rem;
  font-size: 1.375rem;
  color: #f7f8fb;
  border-radius: 30px;
  background-color: #004ea1;
}

/* 第四个盒子右侧短横线2 */
.min-right-box2{
  position: absolute;
  top: 53.75rem;
  right: 1.1875rem;
  width: 1.8125rem;
  height: .125rem;
  background-color: #6597fd;
}

 /* 第四个盒子中间横线4 */
.min-middle-box4{
  margin-top: .9375rem;
  margin-left: 9.5625rem;
  width: .0625rem;
  height: 17.5rem;
  background-color: #6597fd;
}
/* 第四个盒子 */

/* 第五个盒子 */
.middle-box5{
  margin-top: .9375rem;
  margin-left: 4.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 2.5rem;
  font-size: 1.375rem;
  color: #f7f8fb;
  border-radius: 30px;
  background-color: #004ea1;
}

/* 第五个盒子左侧短横线3 */
.min-left-box3{
  position: absolute;
  top: 75.625rem;
  left: 1.1875rem;
  width: 1.8125rem;
  height: .125rem;
  background-color: #6597fd;
}

/* 第五个盒子中间横线5 */
.min-middle-box5{
  margin-top: .9375rem;
  margin-left: 9.5625rem;
  width: .0625rem;
  height: 15rem;
  background-color: #6597fd;
}
/* 第五个盒子 */

/* 第六个盒子 */
.middle-box6{
  margin-top: .9375rem;
  margin-left: 4.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 2.5rem;
  font-size: 1.375rem;
  color: #f7f8fb;
  border-radius: 30px;
  background-color: #004ea1;
}
/* 第六个个盒子右侧短横线4 */
.min-right-box3{
  position: absolute;
  top: 95rem;
  right: 1.1875rem;
  width: 1.8125rem;
  height: .125rem;
  background-color: #6597fd;
}

/* 第六个盒子中间横线6 */
.min-middle-box6{
  /* position: absolute; */
  top: 79.375rem;
  left: 8.125rem;
  width: .0625rem;
  height: 15rem;
  background-color: #6597fd;
}
/* 第六个盒子 */

/* 右侧大盒子 */
.history-reight{
  flex: 1;
  display: inline-block;
  top: 0;
  right: 0;
  width: 34.625rem;
  height: 100%;
}

/* 右侧上方字体样式 */
.right-text1{
  position: absolute;
  top: 2rem;
  left: 1.625rem;
  font-size: 1.625rem;
  color: #333;
}
/* 右侧上方字体样式 */

/* 右侧下方字体样式 */
.right-text2{
  position: absolute;
  top: 4.8125rem;
  left: 1.625rem;
  font-size: 1.125rem;
  color: #8a8a8a;
}
/* 右侧下方字体样式 */

.history-reight1{
  position: relative;
  margin-top: 13.3125rem;
  width: 34.5625rem;
  height: 9.6875rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-reight1 img{
  width: 10.5625rem;
  height: 8.5625rem;
  margin-top: .4375rem;
  margin-left: 22.8125rem;
}

.history-reight2{
  position: relative;
  top: .625rem;
  width: 34.5625rem;
  height: 16.5625rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-reight2 img{
  margin: 1.125rem 0 0 22.625rem;
  width: 10.8125rem;
  height: 14.8125rem;
}

.history-reight3{
  position: relative;
  margin-top: 11.25rem;
  width: 34.5625rem;
  height: 10rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-reight3 img{
  margin: 1.0625rem 0 0 22.3125rem;
  width: 11.125rem;
  height: 7.875rem;
}

.history-reight4{
  position: relative;
  margin-top: .5rem;
  width: 34.5625rem;
  height: 9.9375rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-reight4 img{
  width: 11.5rem;
  height: 8.0625rem;
  margin: .9375rem 0 0 22rem;
}

.history-reight5{
  position: relative;
  margin-top: 20.625rem;
  width: 34.5625rem;
  height: 17.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-reight5 img{
  width: 10.75rem;
  height: 15.75rem;
  margin: .75rem 0 0 22.5625rem;
}

.history-reight6{
  position: relative;
  top: 111.125rem;
  width: 34.5625rem;
  height: 13.25rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.history-reight6 img{
  width: 16.5625rem;
  height: 10.75rem;
  margin: 1.0625rem 0 0 16.6875rem;
}

.bottom-img{
  margin-top: 9.6875rem;
  margin-bottom: 1.875rem;
  width: 100%;
  height: 35.125rem;
}

</style>
