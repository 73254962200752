import Vue from 'vue'
import {
  Button,
  Input,
  Menu,
  MenuItem,
  Carousel,
  CarouselItem,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Backtop,
  Tabs,
  TabPane,
  Link
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Backtop)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Backtop)
Vue.use(Link)
