<template>
  <div class="honor-box" v-if="honors">
    <!-- 荣誉大盒子 -->
    <div class="honor-top">
    <!-- 标题文字盒子 -->
      <div class="honor-top1">
        <p class="honor-top-text1">荣誉</p>
        <p class="honor-top-text2">Honorary qualifications</p>
        <!-- 隐藏荣誉资质显示获奖证书 -->
        <el-button round @click="jump">查看更多</el-button>
      </div>
    <!-- 走马灯盒子 -->
      <div class="riding-lantern">
      <template>
        <el-carousel :interval="5000" arrow="always" height="20.9375rem" :autoplay='false'>
          <el-carousel-item v-for="item in urls" :key="item.id">
            <span class="riding-lantern-box" v-for="item in item" :key="item.id">
              <img :src="item.url" alt="">
              <div class="riding-lantern-text">
                <span class="riding-lantern-text1">{{item.text1}}</span>
                <span class="riding-lantern-text2">{{item.text2}}</span>
                <span class="riding-lantern-text3">{{item.text3}}</span>
              </div>
            </span>
          </el-carousel-item>
        </el-carousel>
      </template>
      </div>
    <!-- 标记重点 -->
      <div class="mark-emphasis">
        <ul v-for="item in urls.mark" :key="item.id">
          <li>
            <span class="mark-img"><img :src="item.url" alt=""></span>
            <span class="mark-span">{{item.text}}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 知识产权大盒子 -->
    <div class="honor-bottom">
      <div class="honor-bottom1">
        <p class="honor-top-text1">知识产权</p>
        <p class="honor-top-text2">Intellectual property</p>
        <el-button round @click="jump1">查看更多</el-button>
      </div>
      <!-- 授权受理盒子 -->
      <div class="acceptance">
        <p class="acceptance1">已授权30项</p>
        <p class="acceptance2">已受理 <span>06</span> 项</p>
      </div>
      <div class="acceptance-img1">
        <img src="../../assets/imgs/关于我们/3/图层 527.png" alt="">
      </div>
      <div class="acceptance-img2">
        <p class="img2-top1">集成电路 <span>1</span> 项</p>
        <p class="img2-top2">实用新型专利 <span>21</span> 项</p>
        <p class="img2-bottom3">发明专利 <span>2</span> 项</p>
        <p class="img2-bottom4">软件著作权 <span>6</span> 项</p>
        <div class="img2-box1">软件著作权</div>
        <div class="img2-box2">发明专利</div>
        <div class="img2-box3">集成电路</div>
        <div class="img2-box4">高新产品认定</div>
        <img src="../../assets/imgs/关于我们/3/流程.png" alt="">
      </div>
    </div>
  </div>
  <!-- 获奖证书组件 -->
  <certificate @jump="jump" v-else-if="certi"></certificate>
  <utilitymodelpatent v-else></utilitymodelpatent>
</template>

<script>
// 导入获奖证书组件
import certificate from './Certificate Patent/certificate.vue'
import utilitymodelpatent from './Certificate Patent/UtilityModelPatent.vue'
export default {
  // 荣誉资质
  name: 'HonorsQualifications',
  data () {
    return {
      // 走马灯
      urls: {
        urls1: [
          { id: 1, text1: '第二届江苏省科协青年会员', text2: '创新创业大赛优秀奖', url: require('../../assets/imgs/关于我们/3/获奖证书/科协优秀奖.jpg') },
          { id: 2, text1: '', text3: '江苏省研究生工作站', url: require('../../assets/imgs/关于我们/3/获奖证书/研究生工作站_1.jpg') },
          { id: 3, text1: '2017年度连云港市市级', text2: '优秀创业项目一类项目', url: require('../../assets/imgs/关于我们/3/获奖证书/人社局一类项目.jpg') },
          { id: 4, text3: '企业证书', url: require('../../assets/imgs/关于我们/3/获奖证书/企业证书.png') }],
        urls2: [
          { id: 5, text1: '第二届江苏省科协青年会员', text2: '创新创业大赛优秀奖', url: require('../../assets/imgs/关于我们/3/获奖证书/科协优秀奖.jpg') },
          { id: 6, text1: '', text3: '江苏省研究生工作站', url: require('../../assets/imgs/关于我们/3/获奖证书/研究生工作站_1.jpg') },
          { id: 7, text1: '2017年度连云港市市级', text2: '优秀创业项目一类项目', url: require('../../assets/imgs/关于我们/3/获奖证书/人社局一类项目.jpg') },
          { id: 8, text3: '中小科技企业入库编号', url: require('../../assets/imgs/关于我们/3/获奖证书/中小科技企业入库编号.png') }
        ],
        mark: [
          { id: 1, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '获2017年省团委“创青春”创业大赛三等奖' },
          { id: 2, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '荣荣获2017年省科协创业大赛优秀奖' },
          { id: 3, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '荣获2017年连云港市创新创业大赛一等奖' },
          { id: 4, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '荣获2017年连云港市优秀创业一类项目' },
          { id: 5, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '荣获2018年中国创冀连云港赛区一等奖，省赛优秀奖' },
          { id: 6, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '荣获2018年互联网+连云港赛区二等奖，省赛优秀奖' },
          { id: 7, url: require('../../assets/imgs/关于我们/3/标记重点.png'), text: '荣获2021年度全国高端装备制造行业赛，优秀企业奖' }
        ]
      },
      // 默认显示荣誉资质
      honors: true,
      // 默认隐藏获奖证书
      certi: false,
      // 默认隐藏实用新型专利
      utility: false
    }
  },
  methods: {
    // 隐藏荣誉资质荣誉资质显示获奖证书
    jump () {
      this.honors = false
      this.certi = true
    },
    // 隐藏实用新型专利
    jump1 () {
      this.honors = false
      this.certi = false
      this.utility = true
    },
    jump2 () {
      this.honors = false
      this.certi = true
      this.utility = false
    }
  },
  components: {
    // 注册获奖证书组件
    certificate,
    // 注册实用新型专利组件
    utilitymodelpatent
  }
}

</script>

<style scoped>
.honor-box{
  margin-left: 10.9375rem;
  margin-top: 5.3125rem;
  width: 83.4375rem;
  height: 100%;
}

.honor-top{
  width: 100%;
  height: 100%;
}

.honor-top1{
  position: relative;
  width: 100%;
  height: 6.875rem;
  border-bottom: .0625rem solid #dcdcdc;
}

.honor-top-text1{
  font-size: 2.25rem;
  color: #004ea1;
}

.honor-top-text2{
  font-size: 1.25rem;
  color: #6985a3;
}

.honor-top .el-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: .875rem;
  right: 0;
  width: 8.125rem;
  height: 2.1875rem;
  color: #ccc;
  border: .0625rem solid #ccc;
}

.riding-lantern{
  padding-top: 3.125rem;
  width: 100%;
  height: 18.75rem;
}

.riding-lantern-box{
  position: relative;
  margin-top: .3125rem;
  margin-right: .375rem;
  margin-left: .3125rem;
  width: 20rem;
  height: 18.75rem;
  display: inline-block;
  background-color: #fff;
}

.riding-lantern-box img{
  width: 100%;
  height: 13.75rem;
}

.riding-lantern-box:hover{
  width: 20rem;
  height: 20.3125rem;
  box-shadow: .1875rem .1875rem .1875rem rgba(0, 0, 0, .1),.3125rem -5px .3125rem rgba(0, 0, 0, .1),--0.3125rem .3125rem .3125rem rgba(0, 0, 0, .1),-0.3125rem -5px .3125rem rgba(0, 0, 0, .1);
}

.riding-lantern-box:first-child{
  margin-left: .625rem;
  margin-right: .3125rem;
}

.riding-lantern-box:last-child{
  margin-left: .625rem;
}

.riding-lantern-text{
  margin-top: .625rem;
  width: 20rem;
  height: 5rem;
}

.riding-lantern-text1{
  display: block;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1rem;
}

.riding-lantern-text2{
  display: block;
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 25px;
  font-size: 1rem;
}

.riding-lantern-text3{
  line-height: 25px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

/deep/ .el-carousel__indicators{
  display: none;
}

/deep/ .el-carousel__arrow--left{
  left: .625rem !important;
}

/deep/.el-carousel__arrow--right{
  right: .5rem !important;
}

/deep/ .el-carousel__arrow{
  width: 2.6875rem;
  height: 5rem;
  border-radius: 0;
  font-size: 3.125rem;
  background:rgba(0, 0, 0, .2);
}

/deep/ .el-carousel__arrow:hover{
  background:rgba(0, 0, 0, .6);
}

.mark-emphasis{
  margin-top: 8.125rem;
  margin-left: 5.625rem;
  width: 47.8125rem;
  height: 32.5rem;
}

.mark-emphasis ul{
  display: flex;
  list-style: none;
}

.mark-emphasis ul li{
  display: flex;
  align-content: center;
}

.mark-img{
  display: inline-block;
  margin-top: .5rem;
  width: 2.0625rem;
  height: 2.5625rem;
}

.mark-span{
  display: inline-block;
  padding-left: 1.875rem;
  font-size: 1.875rem;
  margin-bottom: 2.5rem;
}

/* 知识产权大盒子样式 */
.honor-bottom{
  width: 100%;
  height: 100%;
}

.honor-bottom1{
  position: relative;
  margin-top: 10rem;
  width: 100%;
  height: 6.875rem;
  border-bottom: .0625rem solid #dcdcdc;
}

.honor-bottom1 .el-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: .875rem;
  right: 0;
  width: 8.125rem;
  height: 2.1875rem;
  color: #ccc;
  border: .0625rem solid #ccc;
}

.acceptance{
  margin-left: 29.375rem;
  margin-top: 2.5rem;
  width: 16.125rem;
  height: 6.25rem;
  border-radius: 28px;
  border: .1875rem solid #ff7a00;
}

.acceptance1{
  font-size: 1.625rem;
  padding-top: .8125rem;
  padding-left: 3.625rem;
}

.acceptance2{
  font-size: 1.625rem;
  padding-top: .9375rem;
  padding-left: 3.1875rem;
}

.acceptance2 span{
  color: #ff0000;
}

.acceptance-img1{
  margin-left: 3.4375rem;
  width: 100%;
  height: 26.875rem;
  margin-top: .625rem;
}

.acceptance-img2{
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 3.125rem;
  margin-top: 6.25rem;
  padding-bottom: 33.4375rem;
}

.acceptance-img2 p{
  font-size: 1.875rem;
}

.acceptance-img2 p span{
  font-size: 2.875rem;
  color: #ff0000;
}

.acceptance-img2 img{
  padding-top: 4.375rem;
  width: 100%;
  height: 22.1875rem;
}

.img2-top1{
  position: absolute;
  top: 0;
  left: 25.625rem;
    font-weight: 700;
}

.img2-top2{
  position: absolute;
  top: 0;
  left: 62.8125rem;
    font-weight: 700;
}

.img2-bottom3{
  position: absolute;
  top: 26.875rem;
  left: 6.5625rem;
  font-weight: 700;
}

.img2-bottom4{
  position: absolute;
  top: 26.875rem;
  left: 45rem;
  font-weight: 700;
}

.img2-box1{
  position: absolute;
  top: 6.875rem;
  left: 3.75rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  width: 17.5rem;
  height: 4.375rem;
  border: .25rem solid #ff7a00;
  border-radius: 25px
}

.img2-box2{
  position: absolute;
  top: 6.875rem;
  left: 43.4375rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  width: 17.5rem;
  height: 4.375rem;
  border: .25rem solid #ff7a00;
  border-radius: 25px
}

.img2-box3{
  position: absolute;
  top: 20rem;
  left: 23.4375rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  width: 17.5rem;
  height: 4.375rem;
  border: .25rem solid #ff7a00;
  border-radius: 25px
}

.img2-box4{
  position: absolute;
  top: 20rem;
  left: 63.125rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  width: 17.5rem;
  height: 4.375rem;
  border: .25rem solid #ff7a00;
  border-radius: 25px
}

</style>
