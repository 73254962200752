<template>
  <div class="objective-box">
    <div class="objective-top">
      <div class="top-img">
        <img src="../../assets/imgs/关于我们/4/图层 537.png" alt="">
      </div>
      <!-- 总体目标 -->
      <div class="text-box">
        <div class="text-box-top">总体目标</div>
        <p>致力于高新技术事业发展，通过不断的技术创新，形成自主技术，保持行业技术领先，获取市场竞争优势，同时不断进行管理改进，造就一支工作高效，身心健 康的员工队伍，作为企业持续发展的原动力。互利合作，与客户、与利益相关者合作发展，提高 企业运作效率，成为国家重点支持企业。我们企业的具体目标为“打 造国内物联网软硬件服务知名品牌”+“打造凹版数控印花设备国内第一”。</p>
      </div>
      <!-- 产品战略发展 -->
      <div class="text-box">
        <div class="text-box-top">产品发展战略</div>
        <p>立足于国内高新技术行业，实现产品技术多元化发展，以工业物联网软硬件服务、MSO高速凹版印花成套设备、基于MSO多轴运动控制系统的非标自动化成套设备 以及智能移动机器人等技术为核心，形成多元化发展，提高公司综合能力，在技术和管理上达到国际先进水平。</p>
      </div>
      <!-- 人才战略 -->
      <div class="text-box">
        <div class="text-box-top">人才战略</div>
        <p>每项技术要不断引进工程技术人员，加快营销、管理、技术复合型人才培养，储备一批管理人才，公司将继续加强员工培训，绩效考核，和企业文化建设。逐步 提高员工的薪酬，福利水平，创造有利人才成长的良好环境。</p>
      </div>
      <!-- 资本经营战略 -->
      <div class="text-box">
        <div class="text-box-top">资本经营战略</div>
        <p>在抓好工业项目经营基础上，通过合作，合资，收购，兼并等方式整合有利于扩大经营规模，拓展经营领域的资源，促进公司发展，形成规模效益。</p>
      </div>
    </div>
    <!-- 底部盒子 -->
    <div class="objective-img-bottom">
      <p class="img-bottom-text">愿景未来 <span>VISION FOR THE FUTRE</span></p>
      <p class="img-bottom-text1">智能改变世界&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创新引领未来</p>
      <div class="bottom-img">
        <img src="../../assets/imgs/关于我们/4/图层 538.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 目标愿景
  name: 'ObjectiveVision'
}
</script>

<style scoped>
  .objective-box{
    margin-top: 3.125rem;
    width: 83.75rem;
    height: 100%;
  }

  .objective-top{
    margin-left: 10.9375rem;
    width: 100%;
    height: 100%;
  }

  .top-img{
    width: 100%;
    height: 30.4375rem;
  }

  .text-box{
    margin-top: 5.3125rem;
    width: 1305px;
    height: 400px;
  }

  .text-box-top{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14.6875rem;
    height: 4.6875rem;
    font-size: 2.125rem;
    border-radius: .375rem;
    color: #fff;
    background-color: #004ea1;
  }

  .text-box p{
    margin-top: 3.4375rem;
    line-height: 3.4375rem;
    font-size: 2rem;
  }

  .objective-img-bottom{
    padding-bottom: 20.625rem;
    width: 103.125rem;
    height: 57.5rem;
  }

  .img-bottom-text{
    padding-left: 35.625rem;
    font-size: 2.5rem;
    letter-spacing: .625rem;
    font-weight: 700;
    color: #014a96;
  }

  .img-bottom-text span{
    letter-spacing: 0;
    font-size: 1.875rem;
    color: #014a96;
  }

  .img-bottom-text1{
    margin-top: 4.875rem;
    margin-left: 28.4375rem;
    font-size: 3.75rem;
  }

  .bottom-img{
    margin-top: 4.6875rem;
    width: 103.125rem;
    height: 41.25rem;
  }
</style>
