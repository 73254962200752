import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import HomePage from '../components/HomePage.vue'
// 关于我们
import AboutUs from '../components/AboutUs.vue'
// 产品中心
import ProductCenter from '../components/ProductCenter.vue'
// 案例分享
import CaseSharing from '../components/CaseSharing.vue'
// 案例分享-智能移动机器人小车系列
import Trolley from '../components/CaseSharing/Trolley.vue'
// 案例分享-智能仓储
import OnlyWarehousing from '../components/CaseSharing/OnlyWarehousing.vue'
// 案例分享-移动充电桩
import MobileChargingPile from '../components/CaseSharing/MobileChargingPile.vue'
// 案例分享-连云港市交通控股集团连e行系统
import TransportationHoldingGroup from '../components/CaseSharing/TransportationHoldingGroup.vue'
// 案例分享-连云港智慧石梁河监管平台
import SupervisionPlatform from '../components/CaseSharing/SupervisionPlatform.vue'
// 案例分享-日出东方集团平板集热器长MES管理系统
import MESManagementSystem from '../components/CaseSharing/MESManagementSystem.vue'
// 案例分享-MSO高速凹版印花机
import PrintingMachine from '../components/CaseSharing/PrintingMachine.vue'
// 案例分享-机器人锻造自动化生产线
import RobotForging from '../components/CaseSharing/RobotForging.vue'
// 案例分享-冲压自动化生产线
import StampingAutomation from '../components/CaseSharing/StampingAutomation.vue'
// 案例分享-压铸自动化产线
import DieCastingAutomation from '../components/CaseSharing/DieCastingAutomation.vue'
// 案例分享-码垛自动化产线
import StackingAutomation from '../components/CaseSharing/StackingAutomation.vue'
// 案例分享-机加工上下料自动化产线
import Machining from '../components/CaseSharing/Machining.vue'
// 案例分享-焊接机器人自动化线
import WeldingRobot from '../components/CaseSharing/WeldingRobot.vue'
// 案例分享-非标自动化组装线
import NonstandardAutomation from '../components/CaseSharing/NonstandardAutomation.vue'
// 案例分享-立体仓库
import StereoscopicWarehouse from '../components/CaseSharing/StereoscopicWarehouse.vue'
// 人才招聘
import TalentRecruitment from '../components/TalentRecruitment.vue'
// 商务合作
import BusinessCooperation from '../components/BusinessCooperation.vue'
// 公告
import Notice from '../components/Notice'
// 招标公告
import InviteTenders from '../Notice/InviteTenders'
// 中标公告
import Selected from '../Notice/Selected'
// 企业新闻
import News from '../components/News'
Vue.use(VueRouter)

const routes = [
  // 跳转根路径首页
  { path: '/', redirect: '/homepage' },
  // 首页
  { path: '/homepage', component: HomePage },
  // 关于我们
  { path: '/aboutus', component: AboutUs },
  // 产品中心
  { path: '/productcenter', component: ProductCenter },
  // 案例分享
  { path: '/casesharing', component: CaseSharing },
  // 案例分享-智能移动机器人小车系列
  { path: '/trolley', component: Trolley },
  // 案例分享-智能仓储
  { path: '/onlywarehousing', component: OnlyWarehousing },
  // 案例分享-移动充电桩
  { path: '/mobilechargingpile', component: MobileChargingPile },
  // 案例分享-连云港市交通控股集团连e行系统
  { path: '/transportationholdinggroup', component: TransportationHoldingGroup },
  // 案例分享-连云港智慧石梁河监管平台
  { path: '/SupervisionPlatform', component: SupervisionPlatform },
  // 案例分享-日出东方集团平板集热器长MES管理系统
  { path: '/MESmanagementsystem', component: MESManagementSystem },
  // 案例分享-MSO高速凹版印花机
  { path: '/printingmachine', component: PrintingMachine },
  // 案例分享-机器人锻造自动化生产线
  { path: '/robotforging', component: RobotForging },
  // 案例分享-冲压自动化生产线
  { path: '/stampingautomation', component: StampingAutomation },
  // 案例分享-压铸自动化产线
  { path: '/diecastingautomation', component: DieCastingAutomation },
  // 案例分享-码垛自动化产线
  { path: '/stackingautomation', component: StackingAutomation },
  // 案例分享-机加工上下料自动化产线
  { path: '/machining', component: Machining },
  // 案例分享-焊接机器人自动化线
  { path: '/weldingrobot', component: WeldingRobot },
  // 案例分享-非标自动化组装线
  { path: '/nonstandardautomation', component: NonstandardAutomation },
  // 案例分享-立体仓库
  { path: '/stereoscopicwarehouse', component: StereoscopicWarehouse },
  // 人才招聘
  { path: '/talentrecruitment', component: TalentRecruitment },
  // 商务合作
  { path: '/businesscooperation', component: BusinessCooperation },
  // 招标公告
  { path: '/invitetenders', component: InviteTenders },
  // 中标公告
  { path: '/selected', component: Selected },
  // 公告
  { path: '/notice', component: Notice },
  // 企业新闻
  { path: '/news', component: News }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
